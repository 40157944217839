import { useContext, useEffect } from 'react'
import HistoryContext from '../../components/context/history_context'
import { isClient } from '@utils'

const SESSION_KEY = 'goods-data'

const isBackFromGoodPage = history => history.prev?.includes('good') || false

const checkIfCanUseSession = (history, rootGoodsPageName, transitionPageUrl) => {
   if (history.empty) return false

   /* current page (but current page is don't 'good' page) is not equal transition page */
   if (
      transitionPageUrl
        && (!history.cur?.includes('good'))
        && (transitionPageUrl !== history.cur)
   ) return false

   /* transition to good page  */
   if (
      !transitionPageUrl
        && (history.prev?.includes('good') || history.cur?.includes('good'))
   ) return true

   return (
      history.cur.includes('good') && history.prev?.includes(rootGoodsPageName) ||
        history.cur.includes(rootGoodsPageName) && history.prev?.includes('good') ||
        history.prev?.includes('good') && history.list[history.list.length - 3]?.includes(rootGoodsPageName)
   )
}

const getSessionPageData = () => isClient() && sessionStorage.getItem(SESSION_KEY)
   ? JSON.parse(sessionStorage.getItem(SESSION_KEY))
   : null

const removeSessionPageData = () => (
   isClient()
    && sessionStorage.getItem(SESSION_KEY)
    && sessionStorage.removeItem(SESSION_KEY)
)

const useInitGoodsSession = (rootGoodsPageName, pageData, onRemoveSessionCallback = () => { }) => {
   const { onNextPage } = useContext(HistoryContext)

   useEffect(() => {
      onNextPage((currentHistory, nextUrl) => {
         if (nextUrl.includes('/products/')) {
            sessionStorage.setItem(SESSION_KEY, JSON.stringify({
               scrolledPoint: window.pageYOffset,
               transitionPageUrl: currentHistory.prev || null,
               ...pageData,
            }))
         } else if (!nextUrl.includes(rootGoodsPageName) && !nextUrl.includes('good')) {
            onRemoveSessionCallback()
            removeSessionPageData()
         }
      })
   }, [pageData])

   return getSessionPageData()
}

/**
 * @param { 'popular' | 'specials' | 'category' | 'search' } rootGoodsPageName
 * */
export default function useGoodsSession(rootGoodsPageName) {
   const useGetSessionValueIfPossible = (sessionValueKey, yourValue = null) => {
      const { history } = useContext(HistoryContext)
      const sessionData = getSessionPageData()

      if (!checkIfCanUseSession(history, rootGoodsPageName, sessionData?.transitionPageUrl)) {
         removeSessionPageData()
         return yourValue
      }

      return sessionData?.[sessionValueKey] || yourValue
   }

   return [useGetSessionValueIfPossible, useInitGoodsSession.bind(null, rootGoodsPageName)]
}

export {
   removeSessionPageData,
   isBackFromGoodPage,
}

// function useGoodsSessionStorage(pageData, onRemoveCallback = () => {}) {
//     const { history } = useContext(HistoryContext)
//
//     useEffect(() => {
//         if (!history[history.length - 1] || !history[history.length - 2]) return
//
//         if (getCanUseSession(history)) {
//             sessionStorage.setItem('goods-data', JSON.stringify({
//                 scrolledPoint: window.pageYOffset,
//                 ...pageData
//             }))
//         } else {
//             console.log('removed session in condition')
//             sessionStorage.removeItem('goods-data')
//             onRemoveCallback()
//         }
//     }, [history])
//
//     return getSessionPageData()
// }

// useEffect(() => {
//     if (!scrollMode) return
//
//     const changePageHandler = nextUrl => {
//         if (nextUrl.includes('good')) {
//             sessionStorage.setItem('goods-data', JSON.stringify({
//                 scrolledPoint: window.pageYOffset,
//                 ...pageData
//             }))
//         } else {
//             sessionStorage.removeItem('goods-data')
//         }
//     }
//
//     Router.events.on('routeChangeStart', changePageHandler)
//
//     return () => {
//         Router.events.off('routeChangeStart', changePageHandler)
//     }
// }, [pageData])