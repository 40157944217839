import Image from 'next/image'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Link from 'next/link'

import Layout from '@components/layouts'
import s from './not_found.module.scss'

export default function NotFoundPage() {
   const router = useRouter()
   const [redirectTime, setRedirectTime] = useState(5)

   useEffect(() => {
      const redirectInterval = setInterval(() => {
         setRedirectTime(time => time > 0 ? --time : 0)
      }, 1000)

      return () => clearTimeout(redirectInterval)
   }, [])

   useEffect(() => {
      const redirectTimeout = setTimeout(() => {
         router.push('/')
      }, 5000)

      return () => clearTimeout(redirectTimeout)
   }, [])

   return (
      <Layout>
         <div className={ s.not_found }>
            <div className="container-xl">
               <h1 className={ s.logo_error }>
                  <Image
                     width={ 300 }
                     height={ 100 }
                     src="/images/common/logo_404.svg"
                     alt=""
                  />
               </h1>
               <div className={ s.inner }>
                  <div className={ s.error }>
                     <img src="/images/common/error_image.svg" alt=""/>
                     <h2>Упсс, щось пішло не так!</h2>
                     <p>Редірект на головну сторінку через: { redirectTime }</p>
                  </div>
                  <div className={ s.specials }>
                     <Link href="/">
                        <a className={ s.specials_btn }>На головну сторінку</a>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   )
}
