import { useEffect, useRef, useState } from 'react'
import { getBase64FromFile } from '@utils'

const MAX_FILE_SIZE = 1048576 * 2 // 1048576 - 1Mb

export default function useFile({
   acceptFormats = '',
   onChange,
   forceClear = false,
   maxSize = MAX_FILE_SIZE
}) {
   const [isInvalidSize, setIsInvalidSize] = useState(false)
   const [fileName, setFileName] = useState('')
   const fileInputRef = useRef()

   useEffect(() => {
      fileInputRef.current.onchange = event => {
         const file = fileInputRef.current.files[0]

         if (!file) return

         setIsInvalidSize(file.size > maxSize)

         if (file.size > maxSize) {
            setFileName('')
            setTimeout(() => {
               setIsInvalidSize(false)
            }, 500)

            return file.value = ''
         }

         setFileName(file.name)
         getBase64FromFile(file, onChange)
      }

      fileInputRef.current.setAttribute('accept', acceptFormats)
      // ReactDOM.findDOMNode(fileInputRef.current).setAttribute('accept', acceptFormats)
   }, [])

   useEffect(() => {
      forceClear && clearFile()
   }, [forceClear])

   const clearFile = () => {
      setFileName('')
      fileInputRef.current.value = ''
      onChange('')
   }

   const handleUploadFileTrigger = event => {
      event.preventDefault()
      fileInputRef.current.click()
   }

   const handleClearFile = event => {
      // check is needed if manually triggered this event in function
      if (event) {
         event.preventDefault()
      }
      fileName && clearFile()
   }

   return {
      errors: { size: isInvalidSize },
      fileRef: fileInputRef,
      fileName,
      clearFile: handleClearFile,

      /* optionally */
      triggerUpload: handleUploadFileTrigger
   }
}
