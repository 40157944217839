import ua from './ua.json'
import ru from './ru.json'

const convertLang = () => {
   const reverse = (chr, lang) => lang[chr] ?? chr

   return {
      fromEng: string => string.replace(/./g, chr => reverse(chr, ua)),
      fromRus: string => string.replace(/./g, chr => reverse(chr, ru))
   }
}

export default convertLang()

