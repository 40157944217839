import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import s from './logo.module.scss'

const Logo = ({ width = 150, height = 40, mobile = false }) => {
   const { asPath } = useRouter()

   const logoItem = mobile ? (
      <Image
         src="https://api.ta-da.net.ua/uploads/app/logo/mobile/m_logo.svg"
         width={ width }
         height={ height }
         alt=""
      />
   ) : (
      <Image
         src="https://api.ta-da.net.ua/uploads/app/logo/mobile/m_logo.svg"
         width={ width }
         height={ height }
         alt=""
      />
   )

   if (asPath === '/') {
      return (
         <div className={ s.logo }>
            { logoItem }
         </div>
      )
   }

   return (
      <div className={ s.logo }>
         <Link href="/">
            <a>{ logoItem }</a>
         </Link>
      </div>
   )
}

export default Logo
