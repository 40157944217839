import React from 'react'
import Link from 'next/link'
import Price from '@components/common/price'
import { useImageFormatErrorHandler } from '@functions/hooks'
import s from './search_hints.module.scss'

const SearchHints = ({ searchValue, goods, categories, hideHints }) => {

   return (
      <div className={ s.search_hints }>
         <div className={ `${s.hint_content} ${s.hint_goods}` }>
            { goods.map(item => <HintGood key={ item.id } { ...item } hideHints={ hideHints } />) }
            <div className={ s.all_results }>
               <Link href={ `/search?search=${searchValue}` }>
                  <a onClick={ hideHints }>Всі результати пошуку</a>
               </Link>
            </div>
         </div>
         {
            categories?.length ? (
               <div className={ s.hint_content }>
                  { categories.map(({ id, name, category }) => <HintText key={ id } path={ `/products/category/${category}` } query={ searchValue } inner={ `в категорії ${name}` } hideHints={ hideHints } />) }
               </div>
            ) : null
         }
         {/* <div className={s.hint_content}>
                <HintText query={searchValue} inner={'в назвах Виробника'} />
            </div>
            <div className={s.hint_content}>
                <HintText query={'Можливо ви мали на увазі'} inner={'cevrf ?'} />
            </div> */}
      </div>
   )
}

const HintGood = ({ title, goodCode, src, price, discountPrice, hideHints }) => {
   const [imgSrc, onImageError] = useImageFormatErrorHandler(src)
   return(<Link href={ `/products/${goodCode}` }>
      <a className={ s.hint_good } onClick={ hideHints }>
         <div className={ s.hint_good_image }>
            <img 
               src={ imgSrc || src } 
               alt="" 
               onError={ onImageError }
            />
         </div>
         <div className={ s.hint_good_inner }>
            <h3 title={ title }>{title}</h3>
            <Price
               price={ price }
               discountPrice={ discountPrice }
               oldStyle={ {
                  fontSize: 12,
                  lineHeight: '14px'
               } }
               currentStyle={ {
                  fontSize: 18,
                  lineHeight: '21px'
               } }
            />
         </div>
      </a>
   </Link>
   )}

const HintText = ({ path = '/', query, inner, hideHints }) => (
   <Link href={ path }>
      <a className={ s.hint_text } onClick={ hideHints }>
         <div className={ s.hint_text_image }>
            <img src="/images/common/search.svg" />
         </div>
         <div className={ s.hint_text_inner }>
            <p><span>{query}</span> {inner}</p>
         </div>
      </a>
   </Link>
)

export default SearchHints
