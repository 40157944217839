const changeCategories = payload => ({ type: 'CHANGE_CATEGORIES', payload })
const changeFilter = payload => ({ type: 'CHANGE_FILTER', payload })
const changeTabs = payload => ({ type: 'CHANGE_TABS', payload })
const setAuthKey = payload => ({ type: 'SET_AUTH_KEY', payload })
const setCardNumber = payload => ({ type: 'SET_CARD_NUMBER', payload })
const setUserToken = payload => ({ type: 'SET_USER_TOKEN', payload })
const setTabs = payload => ({ type: 'SET_TABS', payload })
const changeModal = payload => ({ type: 'CHANGE_MODAL', payload })
const setProfileInfo = payload => ({ type: 'SET_PROFILE_INFO', payload })
const logout = payload => ({ type: 'LOGOUT', payload })
const setCardInfo = payload => ({ type: 'SET_CARD_INFO', payload })
const setModalMessage = payload => ({ type: 'SET_MODAL_MESSAGE', payload })
const setMarketsList = payload => ({ type: 'SET_MARKETS_LIST', payload })
const setCitiesNPList = payload => ({ type: 'SET_CITIES_NP_LIST', payload })
const setAddressesList = payload => ({ type: 'SET_ADDRESSES_LIST', payload })
const setChoosenCity = payload => ({ type: 'SET_CHOOSEN_CITY', payload })
const setChoosenAddress = payload => ({ type: 'SET_CHOOSEN_ADDRESS', payload })
const setChoosenCityNP = payload => ({ type: 'SET_CHOOSEN_CITY_NP', payload })
const setChoosenAddressNP = payload => ({ type: 'SET_CHOOSEN_ADDRESS_NP', payload })
const setNPShownStatus = payload => ({ type: 'SET_NP_SHOWN_STATUS', payload })
const generalSetter = payload => ({ type: 'GENERAL_SETTER', payload })
const setSpecialsList = payload => ({ type: 'SET_SPECIALS_LIST', payload })
const setPopularList = payload => ({ type: 'SET_POPULAR_LIST', payload })
const setNewGoodsList = payload => ({ type: 'SET_NEW_GOODS_LIST', payload })
const setCategories = payload => ({ type: 'SET_CATEGORIES', payload })
const setShopId = payload => ({ type: 'SET_SHOP_ID', payload })
const setShowSearchHints = payload => ({ type: 'SET_SHOW_SEARCH_HINTS', payload })
const setProductData = payload => ({ type: 'SET_PRODUCT_DATA', payload })
const setMainSlides = payload => ({ type: 'SET_MAIN_SLIDES', payload })
const setClosestMarketData = payload => ({ type: 'SET_CLOSEST_MARKET_DATA', payload })
const setShowClosestMarket = payload => ({ type: 'SET_SHOW_CLOSEST_MARKET', payload })
const setShoppingList = payload => ({ type: 'SET_SHOPPING_LIST', payload })
const setCartGoods = payload => ({ type: 'SET_CART_GOODS', payload })
const setPackages = payload => ({ type: 'SET_PACKAGES', payload })
const setMinTotalOrder = payload => ({ type: 'SET_MIN_TOTAL_ORDER', payload })
const setGoodToAdd = payload => ({ type: 'SET_GOOD_TO_ADD', payload })
const setAddIndicatorsList = payload => ({ type: 'SET_ADD_INDICATORS_LIST', payload })
const setChoosenPackage = payload => ({ type: 'SET_CHOOSEN_PACKAGE', payload })
const setUserCoordinates = payload => ({ type: 'SET_USER_COORDINATES', payload })
const setLocalShipping = payload => ({ type: 'SET_LOCAL_SHIPPING_METHOD', payload })
const setShippingMethod = payload => ({ type: 'SET_SHIPPING_METHOD', payload })
const setShippingAddress = payload => ({ type: 'SET_SHIPPING_ADDRESS', payload })
const setInformationItems = payload => ({ type: 'SET_INFORMATION_ITEMS', payload })
const setPartnersItems = payload => ({ type: 'SET_PARTNERS_ITEMS', payload })
const setShowMobileSidebar = payload => ({ type: 'SET_SHOW_MOBILE_SIDEBAR', payload })
const setPhoneResetUuid = payload => ({ type: 'PHONE_RESET_UUID', payload })
const setWasChangedMarket = payload => ({ type: 'SET_WAS_CHANGED_MARKET', payload })
const setInStock = payload => ({ type: 'SET_IN_STOCK', payload })
const setSettings = payload => ({ type: 'SET_SETTINGS', payload })
const setViewedProducts = payload => ({ type: 'SET_VIEWED_PRODUCTS', payload })
const setWishlistGoods = payload => ({ type: 'SET_WISHLIST_GOODS', payload })
const removeGoodFromWishlist = payload => ({ type: 'REMOVE_GOOD_FROM_WISHLIST', payload })
const removeGoodsFromWishlist = payload => ({ type: 'REMOVE_GOODS_FROM_WISHLIST', payload })
const addGoodsToWishlist = payload => ({ type: 'ADD_GOODS_TO_WISHLIST', payload })
const setPartnershipFileName = payload => ({ type: 'SET_PARTNERSHIP_FILE_NAME', payload })

export {
   setViewedProducts,
   addGoodsToWishlist,
   removeGoodsFromWishlist,
   removeGoodFromWishlist,
   setWishlistGoods,
   logout,
   setInStock,
   setWasChangedMarket,
   changeCategories,
   changeFilter,
   changeTabs,
   setAuthKey,
   setCardNumber,
   setUserToken,
   setTabs,
   changeModal,
   setProfileInfo,
   setCardInfo,
   setModalMessage,
   setMarketsList,
   setCitiesNPList,
   setAddressesList,
   setChoosenCity,
   setChoosenAddress,
   setChoosenCityNP,
   setChoosenAddressNP,
   setNPShownStatus,
   generalSetter,
   setSpecialsList,
   setPopularList,
   setCategories,
   setShopId,
   setShowSearchHints,
   setProductData,
   setMainSlides,
   setClosestMarketData,
   setShowClosestMarket,
   setShoppingList,
   setCartGoods,
   setPackages,
   setMinTotalOrder,
   setGoodToAdd,
   setAddIndicatorsList,
   setChoosenPackage,
   setUserCoordinates,
   setLocalShipping,
   setShippingMethod,
   setShippingAddress,
   setInformationItems,
   setPartnersItems,
   setShowMobileSidebar,
   setPhoneResetUuid,
   setPartnershipFileName,
   setSettings,
   setNewGoodsList
}
