import { memo } from 'react'
import Image from 'next/image'
import Menu from '@components/common/menu'
import MenuItem from '@components/common/menu/menu_item'

function GoodMenu({ onChangeOnAnalog, onMoveToWishlist, onRemove, isFavorite, isSold }) {
   const favoriteText = isFavorite ? 'Видалити з обраного' : 'Перемістити в обране'

   return (
      <Menu
         menuButton={
            <img
               src="/images/common/settings-dots.svg"
               style={ { width: 20, height: 20 } }
               alt=""
            />
         }
      >
         {/* { isSold && (
            <MenuItem onClick={ onChangeOnAnalog }>
               <Image src="/images/common/change.svg" width={ 24 } height={ 24 } alt=""/>
                Замінити на аналог
            </MenuItem>
         ) } */}
         <MenuItem onClick={ onMoveToWishlist }>
            <Image src="/images/common/heart-filled-red.svg" width={ 24 } height={ 24 } alt={ favoriteText }/>
            { favoriteText }
         </MenuItem>
         <MenuItem onClick={ onRemove }>
            <Image src="/images/common/trash.svg" width={ 24 } height={ 24 } alt=""/>
            Видалити товар
         </MenuItem>
      </Menu>
   )
}

export default memo(GoodMenu)
