import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'

import TadaServiceContext from '@components/context/tada_service_context'
import { changeModal, setModalMessage } from '@redux/actions'
import { useAccount } from '@functions/hooks'

import Loading from '../../loading'
import NewPhoneForm from './new_phone_form'
import s from '../form.module.scss'

const CheckVerificationEmailCodeForm = ({ uuid, protectedEmail, phone }) => {
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const [loading, setLoading] = useState(false)
   const [code, setCode] = useState('')

   const handleCheckVerification = async e => {
      e.preventDefault()

      setLoading(true)

      try {
         const { token } = await tadaService.codeVerification('email', uuid, code)

         dispatch(setModalMessage(''))
         dispatch(changeModal({
            title: 'Відновлення аккаунту',
            inner: <NewPhoneForm
               showSuccessText
               uuid={ uuid }
               token={ token }
               oldPhone={ phone }
            />
         }))
      } catch (e) {
         console.log(e)
         switch (e?.error?.code) {
         case 13:
            dispatch(changeModal({
               title: 'Відновлення акаунту',
               inner: <CanNotBeResetAccount />
            }))
            break
         case 39:
            dispatch(setModalMessage('Неправильний код'))
            break
         default:
            dispatch(setModalMessage('Сталася помилка'))
         }
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div style={ { marginTop: '10px', maxWidth: '490px' } }>На ваш email {protectedEmail} був відправлений код підтвердження</div>

         <div className={ s.form_column }>
            <label htmlFor="code">Код підтвердження</label>
            <input type="text" id="code" value={ code } onChange={ e => setCode(e.target.value) }/>
         </div>

         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ handleCheckVerification } disabled={ !code }>
                    Далі
            </button>
         </div>
      </form>
   )
}

const CanNotBeResetAccount = () => {

   const { toAuthorization } = useAccount()

   const onClick = () => {
      toAuthorization({ showSuccessText: true })
   }

   return (
      <div className={ s.form }>
         <div style={ { marginTop: '10px', maxWidth: '490px' } }>Для даного акаунта неможливе відновлення. <br /> Після реєстрації не був вказаний e-mail.</div>
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ onClick }>
                    Зареєструвати новий акаунт
            </button>
         </div>
      </div>
   )
}


export default CheckVerificationEmailCodeForm
