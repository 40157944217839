import { useEffect, useState } from 'react'

export default function useMounted() {
   const [isMounted, setIsMounted] = useState(false)
   const [isUnmounted, setIsUnmounted] = useState(false)

   useEffect(() => {
      setIsMounted(true)

      return () => setIsUnmounted(true)
   }, [])

   return [isMounted, isUnmounted]
}