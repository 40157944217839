export const DEPRECATED_ROUTES = {
   MARKETS: '/location',
   PARTNERSHIP: '/cooperation',
   PRODUCTS: {
      POPULAR: '/popular',
      SPECIALS: '/specials',
      SIMILAR: '/similar_goods',
      PRODUCT: '/good',
      CATEGORY: '/category',
      SEARCH: '/search',
      WITHOUT_DISCOUNT: '/goods_without_discount',
   },
}

export const ROUTES = {
   FAQ: '/faq',
   DELIVERY: '/delivery',
   PARTNERSHIP: '/partnership',
   FRANCHISE: 'https://ta-da.biz/',
   MARKETS: '/markets',
   CONTACTS_US: '/contact_us',
   NEWS: '/news',
   VACANCIES: '/vacancies',
   RULES: '/rules',
   BONUS_PROGRAM: '/bonus',
   SIZES_TABLE: '/sizes_table',
   ABOUT_US: '/about_us',
   PRODUCTS: {
      POPULAR: '/products/popular',
      NEW: '/products/new',
      SPECIALS: '/products/specials',
      SIMILAR: '/products/similar',
      PRODUCT: '/products',
      CATEGORY: '/products/category',
      SEARCH: '/products/search',
      WITHOUT_DISCOUNT: '/products/without_discount',
      PROMO: '/products/promo',
   },
}
