import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Logo from '@components/common/logo'
import PagesList from '@components/common/pages_list'
import Contacts from '@components/common/contacts'
import Socials from '@components/common/socials'
import BottomButtons from '../../mobile_sidebar/components/bottom_buttons'
import { clx } from '@utils'
import s from './footer.module.scss'
import Image from 'next/image'


const Footer = () => {
   const { informationItems, partnersItems } = useSelector(state => ({
      informationItems: state.informationItems,
      partnersItems: state.partnersItems
   }))

   const router = useRouter()

   const filteredInformationItems = useMemo(
      () => router.asPath !== '/'
         ? informationItems.filter(item => !item.href.includes(decodeURIComponent(router.asPath)))
         : informationItems,
      [informationItems, router.asPath]
   )

   const filteredPartnersItems = useMemo(
      () => router.asPath !== '/'
         ? partnersItems.filter(item => !item.href.includes(decodeURIComponent(router.asPath)))
         : partnersItems,
      [partnersItems, router.asPath]
   )

   return (
      <footer className={ s.footer }>
         <div className="container-xl">
            <div className={ s.footer__inner }>
               <div className={ s.logo }>
                  <Image
                     src="https://api.ta-da.net.ua/uploads/app/logo/pc/logo.svg"
                     width={ 301 } height={ 95 }
                     alt=""
                  />
               </div>
               <div className={ s.socials }>
                  <Socials />
               </div>
               <div className={ clx(s.information, 'hide_on_mobile') }>
                  <PagesList items={ filteredInformationItems } />
               </div>
               <div className={ clx(s.partners, 'hide_on_mobile') }>
                  <PagesList items={ filteredPartnersItems } />
               </div>
               <div className={ clx(s.contacts, 'hide_on_mobile') }>
                  <Contacts />
               </div>
            </div>
         </div>
         <div className={ clx(s.bottom_buttons, 'show_on_mobile') }>
            <BottomButtons informationItems={ filteredInformationItems } partnersItems={ filteredPartnersItems } />
         </div>
      </footer>
   )
}

export default Footer
