import Image from 'next/image'
import s from './socials.module.scss'
import { classList } from '@functions/functions'

const socialsItems = [
   {
      href: 'http://www.instagram.com/tadaua/',
      src: '/images/footer/instagram.svg',
      alt: 'instagram',
   },
   {
      href: 'https://www.facebook.com/people/TA-DA/61560845502325/',
      src: '/images/footer/facebook.svg',
      alt: 'facebook',
   },
   {
      href: 'https://www.tiktok.com/@tadaua?lang=uk...ype=webapp',
      src: '/images/footer/tik-tok.svg',
      alt: 'tik-tok',
   },
   {
      href: 'https://www.youtube.com/c/tadaua?sub_confirmation=1',
      src: '/images/footer/youtube.svg',
      alt: 'youtube',
   },
   {
      href: 'http://invite.viber.com/?g2=AQAPTM%2FYRDaso0kLa4KIRiQ7H%2FjnhBAmoTV%2Fuzbf2GhhbgxbAJ0AVzEF41RUYEwu',
      src: '/images/footer/viber.svg',
      alt: 'viber',
   },
   {
      href: 'https://t.me/tada_ua',
      src: '/images/footer/telegram.svg',
      alt: 'telegram',
   },
]

const Socials = ({ title = 'Слідкуй за нами в соціальних мережах:', className }) => (
   <div className={ classList(s.socials, className) }>
      <span>{title}</span>
      <div className={ s.social_links }>
         {socialsItems.map(({ alt, src, href }) => (
            <a href={ href } target="_blank" key={ alt } rel="noreferrer">
               <Image
                  quality={ 100 }
                  src={ src }
                  width={ 40 }
                  height={ 40 }
                  alt=""
               />
            </a>
         ))}
      </div>
   </div>
)

export default Socials
