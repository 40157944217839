import Authorization from '@components/common/authorization'
import Image from 'next/image'
import s from './account.module.scss'


const Account = () => (
   <div className={ s.account + ' hide_on_mobile' }>
      <a className={ s.account_image } href="account">
         <Image
            src="/images/common/user.svg"
            width={ 20 }
            height={ 20 }
            quality={ 100 }
            alt=""
         />
      </a>
      <Authorization/>
   </div>
)

export default Account
