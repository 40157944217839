import { generateRandomId } from '@utils'
import * as Env from './env'

const partnersPagesRoutes = []
const informationsPagesRoutes = []

if (Env.ABOUT_US_PAGE_IS_ENABLED) {
   informationsPagesRoutes.push({
      id: generateRandomId(),
      href: '/about_us',
      title: 'Про нас'
   })
}
informationsPagesRoutes.push({ id: generateRandomId(), href: '/location', title: 'Супермаркети' })
informationsPagesRoutes.push({ id: generateRandomId(), href: '/contact_us', title: 'Контакти' })
informationsPagesRoutes.push({ id: generateRandomId(), href: '/news', title: 'Новини' })
informationsPagesRoutes.push({ id: generateRandomId(), href: '/vacancies', title: 'Кар\'єра' })

if (Env.SIZES_TABLE_PAGE_IS_ENABLED) {
   informationsPagesRoutes.push({
      id: generateRandomId(),
      href: '/size_table',
      title: 'Розмірна таблиця'
   })
}

informationsPagesRoutes.push({ id: generateRandomId(), href: '/rules', title: 'Умови і положення' })


informationsPagesRoutes.push({ id: generateRandomId(), href: '/rules/6', title: 'Умови замовлення' })
partnersPagesRoutes.push({ id: generateRandomId(), href: '/cooperation', title: 'Співпраця' })
partnersPagesRoutes.push({ id: generateRandomId(), href: 'https://ta-da.biz/', title: 'Франшиза' })

if (Env.JOURNAL_PAGE_IS_ENABLED) {
   partnersPagesRoutes.push({
      id: generateRandomId(),
      href: '/journal',
      title: 'Акційна газета',
   })
}

if (Env.BONUS_PROGRAM_IS_ENABLED) {
   partnersPagesRoutes.push({
      id: generateRandomId(),
      href: '/bonus',
      title: 'Бонусна програма ТА-DА!'
   })
}

partnersPagesRoutes.push({ id: generateRandomId(), href: 'https://gift.ta-da.ua/', title: 'Подарунковий сертифікат' })
partnersPagesRoutes.push({ id: generateRandomId(), href: '/rules/16', title: 'Умови використання сертифікатів' })
partnersPagesRoutes.push({ id: generateRandomId(), href: '/faq', title: 'Питання та відповіді' })

// { id: generateRandomId(), href: '/products/promo', title: ' Акції' },

export {
   informationsPagesRoutes,
   partnersPagesRoutes,
}
