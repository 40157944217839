import NotFoundPage from './components/not_found'
import InternalErrorPage from './components/internal_error'
import DescribedErrorPage from './components/described_error'

export default function ErrorPage({ statusCode, className, title, message }) {
   switch(statusCode) {
   case 404:
      return <NotFoundPage/>
   case 500:
      return <InternalErrorPage />
   default:
      return (
         <DescribedErrorPage
            className={ className }
            title={ title }
            message={ message }
         />
      )
   }
}