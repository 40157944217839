import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { parseCookies } from 'nookies'

import s from './form.module.scss'
import BaseSelect from '../base_select'
import { parseJSON } from '@utils'

const SelectWarehouseForm = ({ city, cities, warehouse, warehouseList, setCity, setWarehouse, changeCity, changeWarehouse, isSearchable = true,
   isCheckout=false }) => {
   const customFilterOption = (option, inputValue) => {
      return option.value.toLowerCase().includes(inputValue.toLowerCase())
   }
   const sortedCities = cities.sort((a, b) => a.value.localeCompare(b.value))

   return (
      <div className={ s.form }>
         {!isCheckout && (
            <div className={ s.form_column } style={ { zIndex: 1002 } }>
               <BaseSelect
                  options={ sortedCities }
                  placeholder="Населений пункт..."
                  value={ city }
                  onChange={ changeCity }
                  isSearchable={ isSearchable }
                  filterOption={ customFilterOption }
               />
            </div>
         )}
         <div className={ s.form_column } style={ { zIndex: 1001 } }>
            <BaseSelect
               options={ warehouseList }
               placeholder="Відділення..."
               value={ warehouse }
               onChange={ changeWarehouse }
               isDisabled={ !city }
               isSearchable={ isSearchable }
            />
         </div>
      </div>
   )
}

export default SelectWarehouseForm
