import { useEffect, useState } from 'react'
import Image from 'next/image'

import { clx } from '@utils'
import s from './up_button.module.scss'


const UpButton = () => {
   const [show, setShow] = useState(false)

   useEffect(() => {
      const onScroll = () => {
         const scrolled = window.pageYOffset
         const coords = document.documentElement.clientHeight

         if (scrolled > coords) {
            setShow(true)
         }
         if (scrolled < coords) {
            setShow(false)
         }
      }

      window.addEventListener('scroll', onScroll)

      return () => window.removeEventListener('scroll', onScroll)
   }, [])

   const handleClick = () => {
      if (window.pageYOffset > 0) {

         const interval = setInterval(() => {
            window.scrollBy(0, -80)
         }, 0)

         const timeout = setTimeout(() => {
            clearInterval(interval)
            clearTimeout(timeout)

            window.scrollTo(0, 0)
         }, 250)
      }
   }

   return (
      <button className={ clx(s.up_button, show && s.up_button_show) } onClick={ handleClick }>
         <Image
            className={ s.arrow }
            src="/images/common/arrow_top.svg"
            width={ 12 }
            height={ 20 }
            alt=""
         />
      </button>
   )
}

export default UpButton
