import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { prettyPhone, getLoginTypeAndValue } from '@utils'
import { changeModal, setModalMessage } from '@redux/actions'

import TadaServiceContext from '@components/context/tada_service_context'
import NumberFormat from '../../number_format'
import Loading from '../../loading'
import CheckVerificationNewPhoneCodeForm from './check_verification_new_phone_code_form'
import s from '../form.module.scss'

const ConfirmNewPhoneForm = ({ oldPhone, newPhone, uuid, token }) => {

   const [confirmPhone, changeConfirmPhone] = useState(newPhone)
   const [loading, setLoading] = useState(false)
   const [isValid, setIsValid] = useState(true)

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const saveUserInfo = async (token, uuid, userName, userSurname, email, birthday, oldPhone, newPhone) => {
      try {
         await tadaService.infoSave(token, uuid, userName, userSurname, email, birthday, oldPhone, newPhone)
      } catch (e) {
         tadaService.logError(e).catch(e => console.log(e))
      }
   }

   const sendVerificationCodeToNewPhone = async phone => {
      try {
         await tadaService.codeGenerate('phone_new', prettyPhone(phone))
      } catch (e) {
         console.log(e)
         switch(e?.error?.code) {
         case 38:
            dispatch(setModalMessage('Вичерпано ліміт смс. Спробуйте пізніше'))
            break
         default:
            dispatch(setModalMessage('Сталася помилка'))
         }
      }
   }

   const confirmClick = async e => {
      e.preventDefault()

      const { type, value: formattedConfirmPhone } = getLoginTypeAndValue(confirmPhone)

      if(type === 'phone') {
         setLoading(true)
         dispatch(setModalMessage(''))

         try {
            const { userName, userSurname, email, birthday } = await tadaService.getPersonalInfo(token)

            await saveUserInfo(token, uuid, userName, userSurname, email, birthday, oldPhone, formattedConfirmPhone)
            await sendVerificationCodeToNewPhone(formattedConfirmPhone)

            dispatch(changeModal({
               title: 'Відновлення акаунту',
               inner: <CheckVerificationNewPhoneCodeForm
                  newPhone={ formattedConfirmPhone }
                  userData={ {
                     userName,
                     userSurname,
                     token,
                     uuid,
                     email,
                     birthday,
                     phone: formattedConfirmPhone
                  } }
               />
            }))
         } catch (e) {
            console.log(e)
            tadaService.logError().catch(e => console.log(e))
            dispatch(setModalMessage('Сталася помилка'))
         } finally { setLoading(false) }
      } else {
         dispatch(setModalMessage('Введіть номер телефону!'))
      }
   }

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div style={ { marginTop: '10px' } }>Підтвердіть новий номер телефону</div>
         <div className={ s.form_column }>
            <label htmlFor="confirm">Телефон</label>
            <NumberFormat
               format="+38 (###) ###-##-##"
               allowEmptyFormatting
               mask="_"
               type="tel"
               id="confirm"
               value={ confirmPhone }
               onValueChange={ ({ formattedValue, value }) => {
                  changeConfirmPhone(formattedValue)
                  setIsValid(value.length === 10)
               } }
            />
         </div>
         <div className={ s.form_center }>
            <button
               className={ s.form_big_btn }
               onClick={ confirmClick }
               disabled={ !isValid }
            >
                Підтверджую
            </button>
         </div>
      </form>
   )
}

export default ConfirmNewPhoneForm