import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Cart from '../cart'
import ChoseMarketForm from '../common/forms/chose_market_form'
import { changeModal, setAddIndicatorsList } from '@redux/actions'
import { clx } from '@utils'
import s from './goods_add_indicator.module.scss'

const GoodsAddIndicator = () => {
   const dispatch = useDispatch()

   const addIndicatorsList = useSelector(state => state.addIndicatorsList)

   useEffect(() => {
      const resetTimeout = setTimeout(() => {
         dispatch(setAddIndicatorsList([]))
      }, 5000)

      return () => clearTimeout(resetTimeout)
   }, [addIndicatorsList.length])

   return (
      <div className={ s.goods_add_indicator }>
         { addIndicatorsList.map(({ title, quantity }, idx) => (
            <GoodsAddItem
               key={ idx }
               title={ title }
               quantity={ quantity }
            />
         )) }
      </div>
   )
}

const GoodsAddItem = ({ title }) => {
   const dispatch = useDispatch()
   const choosenShopId = useSelector(state => state.choosenShopId)
   const [hide, setHide] = useState(false)

   useEffect(() => {
      const hideTimeout = setTimeout(() => {
         setHide(true)
      }, 4000)

      return () => clearTimeout(hideTimeout)
   }, [])

   const handleClick = () => {
      if (choosenShopId ) {//&& choosenShopId !== 8
         dispatch(changeModal({
            title: 'Ваш кошик!',
            inner: <Cart/>
         }))
      } else {
         dispatch(changeModal({ title: 'Оберіть місце доставки', inner: <ChoseMarketForm/> }))
      }
   }

   return (
      <div className={ clx(s.goods_add_item, hide && s.hide) } onClick={ handleClick }>
         <div className={ s.goods_add_content }>
            <h3>Товар додано до кошика!</h3>
            { title && <div className={ s.goods_add_good_title }>{ title }</div> }
         </div>
         <div className={ s.goods_add_icon }>
            <button>
               <img src="/images/common/shopping-basket.svg" />
            </button>
         </div>
      </div>
   )
}

export default GoodsAddIndicator
