import s from './loading.module.scss'
import { clx } from '@utils'

/**
 * @param {string=} className
 * @param {'default'|'small'} [size='default'] size
 * @param {number=} width
 * @param {number=} height
 * @param {'black'} color
 * */
const Loading = ({ className = '', size = 'default', width, height, color }) => (
   <div className={ clx(s.loading, className) }>
      <div className={ clx(s.rolling, s[size]) } style={ { width, height } }>
         <div className={ clx(s.spinner, s[color]) }>
            <div />
         </div>
      </div>
   </div>
)

export default Loading

/*
* TODO: после авторизации подтягивать корзину из кук
* */