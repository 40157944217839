import * as Sentry from '@sentry/nextjs'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

function initSentry() {
   if (process.env.NODE_ENV === 'development') {
      return
   }

   try {
      Sentry.init({
         dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
         integrations: [new TracingIntegrations.BrowserTracing()],
         tracesSampleRate: 1.0,
         maxBreadcrumbs: 50,
         release: '%REACT_APP_RELEASE_VERSION%',
         // beforeSend(event) {
         //     if (event.exception) {
         //         // Sentry.showReportDialog({
         //         //     eventId: event.event_id,
         //         //     title: 'Здається, у вас виникли проблеми',
         //         //     subtitle: 'Напишіть нижче, що трапилося',
         //         //     subtitle2: '',
         //         //     name: 'Топчик',
         //         //     email: 'myemail@gamil.com',
         //         //     comments: 'Я натиснув(ла) "Х", потім "Підтвердити"',
         //         //     user: null,
         //         //     labelName: 'Ім\'я',
         //         //     labelEmail: 'Адреса електронної пошти',
         //         //     labelComments: 'Що сталося?',
         //         //     labelSubmit: 'Відправити звіт про збій',
         //         //     labelClose: 'Закрити',
         //         //     successMessage: 'Ваш звіт відправлено. Дякуємо вам!',
         //         //     lang: 'ua'
         //         // })
         //     }
         //
         //     console.log('sentry', event)
         //
         //     return event
         // }
      })
   } catch (err) {
      console.error(err)
   }
}

const sentryException = err => Sentry.captureException(err)

export {
   initSentry,
   sentryException
}
