import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'next/image'

import { getLoginTypeAndValue } from '@utils'
import { useAccount } from '@functions/hooks'
import { changeModal, setModalMessage } from '@redux/actions'

import TadaServiceContext from '@components/context/tada_service_context'
import Loading from '../loading'
import CodeForm from './code_form'
import s from './form.module.scss'
import NumberFormat from '../number_format'

const PhoneCheckForm = ({ showSuccessText = false, updateUserShop = false, phone }) => {

   const [login, changeLogin] = useState(phone)
   const [loading, setLoading] = useState(false)

   const [formMessage, setFormMessage] = useState('')
   const [isValidPhone, setIsValidPhone] = useState(false)
   const [showRobotCall, setShowRobotCall] = useState(false)

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)
   const { setCard } = useAccount()

   const loginClick = e => {
      e.preventDefault()

      if (showRobotCall) return

      let cancelled = false
      const { type, value } = getLoginTypeAndValue(login)

      if (type === 'phone') {
         setLoading(true)

         dispatch(setModalMessage(''))

         tadaService
            .userAuth(value)
            .then(({ success, user }) => {
               if (!cancelled && success) {
                  setCard(user.cardNumber)
                  setLoading(false)
                  dispatch(changeModal({
                     title: 'Код підтвердження',
                     inner: <CodeForm
                        recallData={ { type, value } }
                        showSuccessText={ showSuccessText }
                        updateUserShop={ updateUserShop }
                     />,
                     extra: (
                        <div className={ s.confirm_code_dino }>
                           <Image src="/images/common/dino/confirm_code.svg" width={ 233 } height={ 266 } alt="" />
                        </div>
                     )
                  }))
               }
            })
            .catch(e => {
               if (!cancelled) {
                  console.log(e)
                  setLoading(false)
                  switch (e.error?.code) {
                  case 52:
                     setFormMessage('Користувач не зареєстрований')
                     break
                  case 59:
                     setFormMessage('Вичерпано ліміт смс! Спробуйте пізніше.')
                     setShowRobotCall(true)
                     break
                  default:
                     setFormMessage('Сталася помилка')
                     tadaService
                        .logError(e)
                        .catch(e => console.log(e))
                     break
                  }
               }
            }).finally(() => setLoading(false))
      } else {
         dispatch(setModalMessage('Введіть номер телефону!'))
      }

      return () => cancelled = true
   }

   const robotClick = e => {
      e.preventDefault()

      if (!showRobotCall) return

      const { type, value } = getLoginTypeAndValue(login)
      let cancelled = false

      if (type === 'phone') {

         setLoading(true)

         dispatch(setModalMessage(''))

         tadaService
            .userCheck(type, value)
            .then(() => tadaService.callAuthorization(type, value))
            .then(({ statusCode, cardNumber }) => {
               if (!cancelled && statusCode === 200) {
                  setCard(cardNumber)
                  setLoading(false)

                  dispatch(changeModal({
                     title: 'Код підтвердження',
                     inner: <CodeForm
                        recallData={ { type, value, call: true } }
                        showSuccessText={ showSuccessText }
                        updateUserShop={ updateUserShop }
                     />,
                     extra: (
                        <div className={ s.confirm_code_dino }>
                           <Image src="/images/common/dino/confirm_code.svg" width={ 233 } height={ 266 } alt="" />
                        </div>
                     )
                  }))
               }
            })
            .catch(e => {
               if (!cancelled) {
                  console.log(e)
                  setLoading(false)

                  switch (e.error?.code) {
                  case 50:
                     dispatch(changeModal({
                        title: 'Підтвердіть правильність номеру',
                        inner: <PhoneCheckForm showSuccessText={ showSuccessText } phone={ login } />
                     }))
                     break
                  case 52:
                     dispatch(setModalMessage('Користувач не зареєстрований'))
                     break
                  case 59:
                     dispatch(setModalMessage('Вичерпано ліміт смс! Спробуйте пізніше.'))
                     setShowRobotCall(true)
                     break
                  default:
                     dispatch(setModalMessage('Сталася помилка'))
                     tadaService
                        .logError(e)
                        .catch(e => console.log(e))
                     break
                  }
               }
            })
      } else {
         dispatch(setModalMessage('Введіть номер телефону!'))
      }

      return () => cancelled = true
   }

   const robotBtn = showRobotCall ? (
      <div className={ s.form_center }>
         <button className={ s.form_big_btn } onClick={ robotClick }>Згенерувати дзвінок</button>
      </div>
   ) : null

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div style={ { marginTop: '10px' } }>{formMessage}</div>
         <div className={ s.form_column }>
            <label htmlFor="login">Телефон</label>
            {/* <input type="text" id="login" value={ login } onChange={ e => changeLogin(e.target.value) } /> */}
            <NumberFormat
               format="+38 (###) ###-##-##"
               allowEmptyFormatting
               mask="_"
               type="tel"
               id="login"
               value={ login }
               onValueChange={ ({ formattedValue, value }) => {
                  changeLogin(formattedValue)
                  setIsValidPhone(value.length === 10)
               } }
            />
         </div>
         {/* <div className={s.form_row}>
                <div className={s.check_item}>
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember" className={s.check_label}>
                        <span>Запомнить меня</span>
                    </label>
                </div>
            </div> */}
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ loginClick } disabled={ showRobotCall && !isValidPhone }>Підтверджую</button>
         </div>
         {robotBtn}
         {/* <div className={s.form_center}>
                <button className={s.form_small_btn} onClick={registerClick}>Зарегистрироваться</button>
            </div> */}
      </form>
   )
}

export default PhoneCheckForm
