import { useEffect, useState } from 'react'
import { clx } from '@utils'
import s from './goods_count.module.scss'

const GoodsCount = ({
   enough,
   setCount,
   count,
   totalQuantity,
   minimum,
   setHideOrderBtn,
   goodCode,
   setValidationState,
   onDecreaseQuantity,
   onIncreaseQuantity,
   disableIncrease,
   disableDecrease
}) => {

   const [value, setValue] = useState(count)

   useEffect(() => {
      setValidationState(prev => ({
         ...prev,
         [goodCode]: !!(count % minimum)
      }))

      setValue(count)
      // setHideOrderBtn(!!(count % minimum))
   }, [count])

   const changeValue = (e) => {
      const newValue = e.target.value
      setValue(newValue)
      if (!isNaN(+newValue) && (newValue !== '') && (newValue > 0)) {
         setCount(+newValue)
         setHideOrderBtn(!!(+newValue % minimum))
      }
   }

   const changeCount = () => {
      if (!isNaN(+value) && (value !== '') && value > 0) {
         setCount(+value)
         setValue(+value)
      } else {
         setValue(count)
      }
   }

   return (
      <div className={ s.goods_count }>
         <button disabled={ count <= 1 || disableDecrease } onClick={ onDecreaseQuantity }>-</button>
         <input
            className={ clx(s.goods_count_value, !enough && s.not_enough) }
            type="number"
            value={ value }
            onChange={ changeValue }
            onBlur={ changeCount }
         />
         <button disabled={ count >= totalQuantity || disableIncrease } onClick={ onIncreaseQuantity }>+</button>
      </div>
   )
}

export default GoodsCount