import Router from 'next/router'
import { useEffect, useRef, useState } from 'react'
import HistoryContext from './history_context'

export default function HistoryProvider({ children }) {

   const [history, setHistory] = useState({
      list: [],
      cur: null,
      prev: null,
      empty: true
   })

   const onNextPage = useRef((currentHistory, nextUrl) => {})

   useEffect(() => {
      const changePageHandler = url => {
         const nextUrl = decodeURI(url)

         if (history.cur !== nextUrl) {
            setHistory(prev => {
               const list = nextUrl !== prev.cur ? [...prev.list, nextUrl] : prev.list
               const newHistory = {
                  list,
                  cur: nextUrl,
                  prev: list[list.length - 2],
                  empty: false
               }

               onNextPage.current(newHistory, nextUrl)

               return newHistory
            })
         }
      }

      Router.events.on('routeChangeStart', changePageHandler)

      return () => Router.events.off('routeChangeStart', changePageHandler)
   }, [])

   return (
      <HistoryContext.Provider value={ { history, onNextPage: cb => onNextPage.current = cb } }>
         { children }
      </HistoryContext.Provider>
   )
}