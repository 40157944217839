import getConfig from 'next/config'
import isClient from './is_client'

export default function getEnv(key) {
   const { publicRuntimeConfig } = getConfig()

   const value = isClient()
      ? publicRuntimeConfig[key]
      : process.env[key]

   if (value) {
      return value
   }

   throw new Error(`Env not contains ${key} value`)
}