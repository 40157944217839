import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { changeCategories } from '@redux/actions'
import CategoryItem from './category_item'
import s from './categories_list.module.scss'

const CategoriesList = ({ categories }) => {
   const wrapperRef = useRef()
   const showMoreRef = useRef()

   const [show, setShow] = useState(true)
   const [activeCategory, setActiveCategory] = useState(null)

   useEffect(() => {
      setActiveCategory(categories[0]?.id)
   }, [categories])

   useEffect(() => {
      const onScroll = () => {
         const lastElement = showMoreRef.current.getBoundingClientRect()
         const windowHeight = window.innerHeight || document.documentElement.clientHeight

         const isElementVisible = lastElement.top >= 0 && lastElement.top <= windowHeight
         setShow(!isElementVisible)
      }

      wrapperRef.current.addEventListener('scroll', onScroll)

      return () => wrapperRef.current?.removeEventListener('scroll', onScroll)
   }, [wrapperRef])

   const getCategoriesList = () => Array.isArray(categories)
      ? categories.map(({ id, title, src, href, subitems }, index, array) => (
         <CategoriesListItem
            key={ id }
            id={ id }
            title={ title }
            show={ activeCategory === id }
            subitems={ subitems }
            src={ src }
            href={ href }
            setActiveCategory={ setActiveCategory }
            myRef={ index === array.length - 1 ? showMoreRef : null }
         />
      ))
      :null

   return (
      <div className={ s.categoriesList }>
         <div className={ s.categoriesList_items } ref={ wrapperRef }>
            { getCategoriesList() }
            { show && <div className={ s.scrollBtn }>Гортай вниз</div> }
         </div>
      </div>
   )
}

const CategoriesListItem = ({ id, title, show, src, href, subitems, myRef, setActiveCategory }) => {

   const dispatch = useDispatch()

   return (
      <div
         className={ `${ s.category } ${ show ? s.active : '' }` }
         onMouseEnter={ () => setActiveCategory(id) }
         ref={ myRef }
      >
         <Link href={ href }>
            <a className={ s.category_title } title={ title }
               onClick={ () => dispatch(changeCategories(false)) }>
               <div className={ s.category_name }>
                  <Image
                     src={ src }
                     width={ 20 }
                     height={ 20 }
                     alt=""
                  />
                  <p>{ title }</p>
               </div>
            </a>
         </Link>

         <CategoryItem items={ subitems } show={ show }/>
      </div>
   )
}

export default CategoriesList
