import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import App from 'next/app'
import { Provider } from 'react-redux'
import Head from 'next/head'
import Script from 'next/script'
import nookies, { parseCookies } from 'nookies'

import 'swiper/swiper-bundle.css'
import '../styles/global.scss'

import {
   setAuthKey,
   setCategories,
   setInStock,
   setMarketsList,
   setShoppingList,
   setWishlistGoods,
   setShopId,
   generalSetter,
   setCitiesList,
   setCitiesNPList,
   setSettings,
} from '@redux/actions'
import { useStore } from '@redux/store'

import TadaService from '../services/TadaService'
import { getApiKey, parseJSON, syncShoppingList, analytics, initSentry } from '@utils'
import { useAccount } from '../functions/hooks'
import { DEFAULT_SHOP_ID, SITE_TITLE } from '@static/constants'
import { PIXEL } from '@static/env'

import TadaServiceContext from '../components/context/tada_service_context'
import NextNprogress from '../components/common/next_n_progress'
import ErrorBoundary from '../components/common/error_boundary'
import HistoryProvider from '../components/context/history_provider'
import ErrorPage from '@components/pages/_error'

function MyApp({ Component, pageProps, authorizationKey, categories, markets, user, cartList, wishlistGoods, citiesNP, homepageURL, isPaginationPage }) {
   const store = useStore(pageProps.initialReduxState)
   const router = useRouter()
   const canonical = `https://ta-da.ua${router.asPath}`.split('?')[0]

   useEffect(() => {
      if (process.env.NODE_ENV === 'production') {
         analytics()
         initSentry()
         UTM()
      }
   }, [])

   const UTM = () => {
      const localUTM = localStorage.getItem('UTM')
      const isLocalUTMEmpty = localUTM ? Boolean(Object.keys(localUTM).length === 0) : true
      const result = Object.fromEntries(Object.entries(router.query).filter(([key]) => key.toLowerCase().includes('utm_')))

      if (!isLocalUTMEmpty) {
         if (localUTM !== JSON.stringify(result)) {
            if (Object.keys(result).length !== 0) {
               localStorage.setItem('UTM', JSON.stringify(result))
            }
         }
      }
      else {
         localStorage.setItem('UTM', JSON.stringify(result))
      }
   }

   const isHide = () => {
      if (router.asPath === '/')
         return false
      else {
         return router.asPath.indexOf('products') > -1 && router.asPath.indexOf('category') === -1
      }
   }

   //facebook PIXEL
   useEffect(() => {
      import('react-facebook-pixel')
         .then((x) => x.default)
         .then((ReactPixel) => {
            ReactPixel.init(PIXEL)
            ReactPixel.pageView()
            router.events.on('routeChangeComplete', () => {
               ReactPixel.pageView()
            })
         })
   }, [router.events])

   return (
      <ErrorBoundary fallback={ <ErrorPage statusCode={ 500 } /> }>
         <Head>
            <script type="application/ld+json">
               {`
               {
                 "@context": "http://schema.org",
                 "@type": "WebSite",
                 "url": "https://ta-da.ua/",
                 "potentialAction": {
                   "@type": "SearchAction",
                   "target": "https://ta-da.ua/products/search?search={search_term_string}",
                   "query-input": "required name=search_term_string"
                 }
               }
               `}
            </script>
            <script type="application/ld+json">
               {`
                  {
                  "@context": "http://schema.org",
                  "@type": "Organization",
                  "name": "Ta-Da",
                  "url": "https://ta-da.ua/",
                  "logo": "https://api.ta-da.net.ua/uploads/app/logo/pc/logo.svg"
                  }
               `}
            </script>
            {/* <!-- Google Tag Manager --> */}
            <script dangerouslySetInnerHTML={ {
               __html: `
                  (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-MN5SMSCC');
               ` } } />
            {/* <!-- End Google Tag Manager --> */}
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
            <meta name="description" content="Товари для дому щодня!" />

            {isPaginationPage
               ? <meta name="robots" content="noindex, follow" />
               : <meta name="robots" content="index, follow" />
            }

            <meta name="apple-itunes-app" content="app-id=com.ta_da.ios" />
            <link rel="apple-touch-icon" href="/images/common/android-chrome-512x512.png" />

            <link rel="icon" href="/images/favicon.png" />
            {/*<link rel="icon" href="https://ta-da.ua/images/common/logo.svg" type="image/svg+xml"/>*/}
            <meta name="google-play-app" content="app-id=com.ta_da.android" />
            <link rel="android-touch-icon" href="/images/common/android-chrome-512x512.png" />

            <link rel="canonical" href={ canonical } />

            <meta property="og:locale" content="uk_UA" />
            {/*<meta property="og:description" content="Товари для дому щодня!" />*/}
            <meta name="og:title" content={ SITE_TITLE } />
            {/*<meta property="og:image" content="https://ta-da.ua/images/common/logo.svg" />*/}
            <meta name="og:site_name" content={ SITE_TITLE } />
            <meta name="twitter:card" content="summary_large_image" />

            {homepageURL && <base href={ homepageURL } />}

            {/* Open Graph */}
            <meta property="og:site_name" content={ SITE_TITLE } />
            <meta property="og:url" content={ 'https://ta-da.ua' + router.asPath } />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ `${SITE_TITLE} - Безліч товарів за приємною ціною ✅ Дізнайтесь деталі за номером ☎️ 0 800 759 119` } />
            {/*<meta property="og:title" content={ SITE_TITLE } />*/}
            <meta property="og:description" content={ 'Безліч товарів за приємною ціною ✅ Дізнайтесь деталі за номером ☎️ 0 800 759 119 ✔️ Зручна доставка по супермаркетах в Україні 🚚 Мережа магазинів | ТА-ДА!' } />
            {!isHide() && <meta property="og:image" content={ `${homepageURL}images/common/android-chrome-512x512.jpeg` } />}

            <title>{SITE_TITLE}</title>
         </Head>
         <Provider store={ store }>
            <HistoryProvider>
               <NextNprogress
                  color="#000"
                  startPosition={ 0.3 }
                  stopDelayMs={ 200 }
                  height="3"
               />
               <DefaultValuesWrapper
                  authorizationKey={ authorizationKey }
                  categories={ categories }
                  markets={ markets }
                  user={ user }
                  cartList={ cartList }
                  wishlistGoods={ wishlistGoods }
                  citiesNP={ citiesNP }
               >
                  <Component { ...pageProps } />
               </DefaultValuesWrapper>
            </HistoryProvider>
         </Provider>

         <Script id="eSputnik-script" dangerouslySetInnerHTML={ { __html: '!function (t, e, c, n) { var s = e.createElement(c); s.async = 1, s.src = \'https://statics.esputnik.com/scripts/\' + n + \'.js\'; var r = e.scripts[0]; r.parentNode.insertBefore(s, r); var f = function () { f.c(arguments); }; f.q = []; f.c = function () { f.q.push(arguments); }; t[\'eS\'] = t[\'eS\'] || f; }(window, document, \'script\', \'0A6B4C2493AD45E8973EEE0FC32EE577\'); eS(\'init\');' } } />

         {/*Hotjar Tracking Code for https://ta-da.ua/ */}

         <Script
            id="hotjar"
            dangerouslySetInnerHTML={ {
               __html: `
               (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:4984771,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            } }
         />
         {/* <!-- Google Tag Manager (noscript) --> */}
         <noscript>
            <iframe
               src="https://www.googletagmanager.com/ns.html?id=GTM-MN5SMSCC"
               height="0"
               width="0"
               style={ { display: 'none', visibility: 'hidden' } }
            />
         </noscript>
         {/* <!-- End Google Tag Manager (noscript) --> */}
      </ErrorBoundary>
   )
}

const DefaultValuesWrapper = ({
   children,
   authorizationKey,
   categories,
   markets,
   user,
   cartList,
   wishlistGoods,
   citiesNP
   // viewedProducts,
}) => {
   const cookies = parseCookies()
   const dispatch = useDispatch()
   const [rendered, setRendered] = useState(false)

   const authKey = useSelector(state => state.authKey)
   const storeShoppingList = useSelector(state => state.shoppingList)
   const choosenCity = useSelector(state => state.choosenCity)
   const choosenCityNP = useSelector(state => state.choosenCityNP)
   const choosenAddress = useSelector(state => state.choosenAddress)
   const choosenAddressNP = useSelector(state => state.choosenAddressNP)
   const isNPShown = useSelector(state => state.isNPShown)
   const profileInfo = useSelector(state => state.profileInfo)
   
   const tadaService = new TadaService(authorizationKey || authKey)
   const inStock = cookies['in-stock']
   const synchronizedShoppingList = syncShoppingList(cartList, storeShoppingList)

   useEffect(() => {
      const asyncEffect = async () => {
         authorizationKey && dispatch(setAuthKey(authorizationKey))
         categories && dispatch(setCategories(categories))
         markets && dispatch(setMarketsList(markets))
         citiesNP && dispatch(setCitiesNPList(citiesNP))

         if (typeof inStock !== 'undefined')
            dispatch(setInStock(JSON.parse(inStock)))

         dispatch(setWishlistGoods(wishlistGoods))
         dispatch(setShoppingList(synchronizedShoppingList))
         const temObj = {}

         const settingsList = await tadaService.settingsList().then(res => {
            dispatch(setSettings(res))
            return res
         })

         // Set info about shop
         const userCity = parseJSON(cookies.city)
         const userAddress = parseJSON(cookies.address)

         if (userCity && !choosenCity) {
            temObj.choosenCity = userCity.label
         }
         if (userAddress && !choosenAddress) {
            temObj.choosenAddress = userAddress.label
            temObj.choosenShopId = userAddress.shopId
         }
         if (settingsList.available_delivery.some(el => el.company.includes('novaposhta'))) {
            // Set info about NP
            const userCityNP = profileInfo ? profileInfo?.delivery?.city : parseJSON(cookies.cityNP) 
            const userAddressNP = profileInfo ? profileInfo?.delivery?.address : parseJSON(cookies.warehouse)

            if (userCityNP && !choosenCityNP) {
               temObj.choosenCityNP = profileInfo ? userCityNP : userCityNP.label
            }
            if (userAddressNP && !choosenAddressNP) {
               temObj.choosenAddressNP = profileInfo ? userAddressNP : userAddressNP.label
            }
            // set default department tab
            const userIsNPShown = parseJSON(cookies.isNPShown)
            if (userIsNPShown !== undefined && isNPShown === null) temObj.isNPShown = userIsNPShown
         } else {
            temObj.isNPShown = false
         }
         //set departments
         dispatch(generalSetter(temObj))
         //set Shop ID
         dispatch(setShopId(userAddress?.shopId || DEFAULT_SHOP_ID))

         setRendered(true)
      }
      asyncEffect()
   }, [])

   return (
      <TadaServiceContext.Provider value={ tadaService }>
         <UserDefaultData user={ rendered ? user : null }>
            {children}
         </UserDefaultData>
      </TadaServiceContext.Provider>
   )
}

const UserDefaultData = ({ children, user }) => {
   const { setUserData } = useAccount()
   useEffect(() => {
      if (user) setUserData(user.cardNumber, user.token, false, true)
   }, [user])

   return <>{children}</>
}

MyApp.getInitialProps = async (appContext) => {
   const appProps = await App.getInitialProps(appContext)
   const { req } = appContext.ctx
   const URL = req
      ? `${req.headers['x-forwarded-proto']}://${req.headers.host}`
      : '0'

   const homepageURL =
      URL.indexOf('localhost') > -1
         ? null
         : URL.indexOf('10.2.0.41') > -1 //check if it's test server
            ? 'https://test-env-server.ta-da.ua/'
            : 'https://ta-da.ua/'

   if (!req) return { ...appProps }

   const cookies = nookies.get(appContext.ctx)
   const authorizationKey = getApiKey()
   const tadaService = new TadaService(authorizationKey)
   const user = parseJSON(cookies.user)
   const shopId = cookies.shopId || DEFAULT_SHOP_ID

   const wishlistGoods = user?.token
      ? await tadaService
         .wishlist(user.token)
         .catch(e => {
            tadaService.logError(e)
            return []
         })
      : []

   const cartList = user?.token
      ? await tadaService
         .getCartList(user.token)
         .catch(e => {
            tadaService.logError(e)
            return e
         })
      : null

   const categories = await tadaService
      .structuresCategoriesList(shopId)
      .then(res => res)
      .catch(e => {
         tadaService.logError(e)
         return e
      })

   const markets = await tadaService
      .shopsList()
      .then(res => res)
      .catch(e => {
         tadaService.logError(e)
         return e
      })

   const citiesNP = await tadaService
      .getNPCitiesList()
      .then(res => res)
      .catch(e => {
         tadaService.logError(e)
         return e
      })

   return {
      ...appProps,
      authorizationKey,
      categories,
      markets,
      user,
      cartList,
      wishlistGoods,
      citiesNP,
      homepageURL,
      isPaginationPage: appContext.ctx.req.url.includes('page=')
   }
}

export default MyApp
