/**
 * @example
 * input: "095 555 5555"
 * output: "+38 (095) 555 5555"
 */
export default function prettyPhone(phone) {
   const tempPhone = phone.replace(/\D/g, '').slice(-10)

   return `+38 (${tempPhone.slice(0, 3)}) ${tempPhone.slice(3, 6)}-${tempPhone.slice(6, 8)}-${tempPhone.slice(8, 10)}`
}
