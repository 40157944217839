import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

export default function useInfinityScroll(callback) {
   const router = useRouter()
   const observerRef = useRef(null)
   const [onPause, setOnPause] = useState(true)

   useEffect(() => {
      observerRef.current = observerRef.current = new IntersectionObserver((entities, observer) => {
         const observedTarget = entities[0]

         if (observedTarget.isIntersecting) {
            router.push(
               {
                  query: {
                     ...router.query,
                     page: Number(router.query.page || 1),
                  },
               },
               null,
               { shallow: true }
            )

            callback()
            observer.disconnect()
         }
      })
   }, [router])

   const setObserverRef = useCallback(ref => {
      if (!ref || onPause) return

      observerRef.current.observe(ref)
   }, [onPause])

   const observingStart = useCallback(() => {
      setOnPause(false)
   }, [])

   const observingStop = useCallback(() => {
      setOnPause(true)
   }, [])

   return {
      observedRef: setObserverRef,
      observingStart,
      observingStop,
   }
}
