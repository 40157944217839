import Link from 'next/link'
import { useSelector } from 'react-redux'
import { memo } from 'react'

import { useAccount } from '@functions/hooks'
import s from './authorization.module.scss'


const Authorization = ({ inner = text => <>{ text }</> }) => {

   const { toAuthorization } = useAccount()

   const token = useSelector(state => state.token)
   const profileInfo = useSelector(state => state.profileInfo)

   const authClick = () => {
      toAuthorization({
         showSuccessText: true
      })
   }

   const content = token ? (
      <Link href="/account" className={ s.inner }>
         <a>
            <b>
               {
                  inner(
                     profileInfo?.userName && profileInfo?.userSurname
                        ? <>
                           <span>{profileInfo.userSurname}</span>{' '}
                           <span>{profileInfo.userName}</span>
                        </>
                        : profileInfo?.phone
                  )
               }
            </b>
         </a>
      </Link>
   ) : <span className={ s.inner } onClick={ authClick }>{ inner('Авторизація') }</span>

   return (
      <div className={ s.authorization }>
         { content }
      </div>
   )
}

export default memo(Authorization)