import { useDispatch, useSelector } from 'react-redux'
import { changeModal, setModalMessage } from '@redux/actions'
import s from './modal.module.scss'

/**
 * @param {string} title
 * @param {node} inner
 * @param {node} top
 * @param {node} extra
 * @param {object} styles - { title: object }
 * @param {boolean} required - { title: object }
 * */
const Modal = ({ title, inner, top, extra, styles, required = false }) => {

   const dispatch = useDispatch()
   const { modalMessage } = useSelector(({ modalMessage }) => ({ modalMessage }))

   const titleStyles = styles.title
   top && (titleStyles.paddingTop = 10)

   const closeModal = () => {
      dispatch(changeModal(null))
      dispatch(setModalMessage(''))
   }

   return (
      <div className={ s.modal }>
         <div className={ s.modal_body } onClick={ e => e.stopPropagation() }>
            <div className={ s.extra }>{extra}</div>

            {
               !required && (
                  <button className={ s.close_btn } onClick={ closeModal }>
                     <img src="/images/common/cross.svg" alt="" />
                  </button>
               )
            }

            <div className={ s.top }>
               {top}
            </div>
            <div className={ s.modal_control } style={ titleStyles }>
               <span>{title}</span>
            </div>
            <div className={ s.modal_inner }>
               {modalMessage}
               {inner}
            </div>
         </div>
      </div>
   )
}

Modal.defaultProps = {
   styles: { title: {} }
}

export default Modal
