import { useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import TadaServiceContext from '../../components/context/tada_service_context'


export default function useArchiveNews() {

   const router = useRouter()
   const tadaService = useContext(TadaServiceContext)

   const PAGE_LIMIT = 10
   const { page: pageQuery } = router.query

   const [pageNumber, setPageNumber] = useState(+pageQuery || 1)
   const [archiveNewsData, setArchiveNewsData] = useState({})
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      const newPage = +pageQuery || 1
      const newOffset = PAGE_LIMIT * (newPage - 1)

      setPageNumber(newPage);

      (async () => {
         try {
            setIsLoading(true)
            const news = await tadaService.archiveNewsList(newOffset, PAGE_LIMIT)
            setArchiveNewsData(news)
         } catch (err) {
            console.error(err)
         } finally {
            setIsLoading(false)
         }
      })()
   }, [pageQuery])

   const fetchArchiveNews = useCallback(async (page, offset = 0) => {
      try {
         return await tadaService.archiveNewsList(offset)
      } catch (err) {
         console.error(err)
      }
   }, [])

   return {
      fetchArchiveNews,

      pageNumber,
      archiveNews: archiveNewsData.news ?? [],
      totalArchivePages: Math.ceil(archiveNewsData.totalNewsCount / PAGE_LIMIT),
      isLoading
   }
}