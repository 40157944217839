import { useRouter } from 'next/router'
import Image from 'next/image'
import { useContext, useState } from 'react'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'

import { clx } from '@utils'
import { useShoppingList, useWishlist, useMounted } from '@functions/hooks'
import { prettyPrice } from '@utils'
import { changeModal } from '@redux/actions'

import WarningMessage from './warning_message'
import GoodsCount from './goods_count'
import Loading from '@components/common/loading'
import TadaServiceContext from '@components/context/tada_service_context'
import GoodMenu from './goods_menu'
import s from './cart_goods.module.scss'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@utils'


const CartGoods = ({ cartGoods = [], setHideOrderBtn, setValidationState }) => (
   <div className={ s.cart_goods }>
      { cartGoods.map(item =>
         <CartProductItem
            key={ item.goodCode }
            { ...item }
            setHideOrderBtn={ setHideOrderBtn }
            setValidationState={ setValidationState }
         />
      ) }
   </div>
)

const CartProductItem = ({
   id,
   discountPrice,
   goodCode,
   ignoreMinimum,
   minimum,
   title,
   src,
   price,
   measurement,
   totalQuantity,
   invalidCount,
   setHideOrderBtn,
   setValidationState,
   annotation
}) => {

   const router = useRouter()
   const dispatch = useDispatch()

   const { addGoodToWishlist, removeGoodFromWishlist, isFavorite } = useWishlist()
   const { incGoodsCount, decGoodsCount, removeGood, setGoodsCount } = useShoppingList()

   const { shoppingList, token, cartGoods } = useSelector(({
      shoppingList,
      token,
      cartGoods
   }) => ({ shoppingList, token, cartGoods }))

   const [isRemoved, setIsRemoved] = useState(false)
   const [isRemoving, setIsRemoving] = useState(false)
   const [isIncreasing, setIsIncreasing] = useState(false)
   const [isDecreasing, setIsDecreasing] = useState(false)

   const [, isUnmounted] = useMounted()

   const count = shoppingList[goodCode]
   const enough = count <= totalQuantity

   const tadaService = useContext(TadaServiceContext)

   const removeGoodHelper = () => {
      if (cartGoods.length === 1) {
         removeGood(goodCode)
      } else {
         setIsRemoved(true)

         setTimeout(() => {
            if (!isUnmounted) removeGood(goodCode)
         }, 500)
      }
   }

   const firebaseProps = {
      currency: 'UAH',
      value: discountPrice ? discountPrice :price,
      content_name: title,
      content_type: 'product',
      content_ids: [id],
   }

   const handleRemoveGood = async event => {
      const analyticsInstance = analytics()
      logEvent(analyticsInstance, 'remove_from_cart', firebaseProps)//firebaseAnalitics
      if (token) {
         setIsRemoving(true)
         tadaService
            .removeGoodFromCart(goodCode, token)
            .then(() => {
               removeGoodHelper()
            })
            .catch(err => {
               tadaService.logError(err)
               setIsRemoving(false)
            })
      } else {
         removeGoodHelper()
      }
   }

   const handleIncreaseQuantity = event => {
      setIsIncreasing(true)
      incGoodsCount(goodCode, ignoreMinimum ? 1 : minimum)
         .finally(() => {
            setIsIncreasing(false)
         })
   }

   const handleDecreaseQuantity = event => {
      setIsDecreasing(true)
      decGoodsCount(goodCode, ignoreMinimum ? 1 : minimum)
         .finally(() => {
            setIsDecreasing(false)
         })
   }

   const handleMoveToWishlist = event => {
      if (isFavorite(goodCode)) {
         removeGoodFromWishlist(goodCode)
      } else {
         addGoodToWishlist(goodCode)
      }
   }

   // const handleChangeToAnalog = async event => {
   //    await router.push(`/products/similarw/${ goodCode }`)
   //    dispatch(changeModal(null))
   // }

   const infoAlertMessage = enough
      ? <>Кількість має бути <br/> кратною { minimum }!</>
      : (totalQuantity > 0) ? `В наявності ${ totalQuantity } ${ measurement }.` : 'Немає в наявності'

   const infoAlert = (
      <div
         className={ `${ s.not_avalible } ${ s.not_enough_item }` }
         style={ { display: (!enough || count % (ignoreMinimum ? 1 : minimum)) ? 'block' : 'none' } }
      >
         { infoAlertMessage }
      </div>
   )

   const warningMessages = {
      'м': annotation,
      'шт': 'Товар може бути відсутнім, ми повідомимо вам!'
   }

   return (
      <div
         className={ clx(
            s.cart_goods_item,
            !enough && s.not_enough,
            isRemoved && s.remove
         ) }
      >
         <button className={ s.close }>
            {
               isRemoving
                  ? <Loading size="small"/>
                  : <GoodMenu
                     isSold={ !enough }
                     isFavorite={ isFavorite(goodCode) }
                     // onChangeOnAnalog={ handleChangeToAnalog }
                     onMoveToWishlist={ handleMoveToWishlist }
                     onRemove={ handleRemoveGood }
                  />
            }
         </button>
         { infoAlert }
         <div
            className={ `${ s.not_avalible } ${ s.primary_item }` }
            style={ { display: ((invalidCount && enough) || (count === totalQuantity)) ? 'block' : 'none' } }
         >
            { (count === totalQuantity) ? `В наявності ${ totalQuantity } ${ measurement }.` : `В упаковці ${ minimum }шт.` }
         </div>
         <div className={ s.inner }>
            <div className={ s.goods_preview }>
               <Image
                  src={ src }
                  width={ 110 }
                  height={ 99 }
                  alt={ title + ' фото' }
                  title={ title + ' фото' }
               />
            </div>
            <div className={ s.goods_content }>
               <div className={ s.goods_title } title={ title }>
                  <Link href={ `/products/${ goodCode }` }>
                     <a onClick={ () => dispatch(changeModal(null)) }>{ title }</a>
                  </Link>
               </div>
               <div className={ s.price }>
                  <span>Ціна за 1&nbsp;{ measurement }.</span>
                  <div className={ s.price_value }>
                     { prettyPrice(price) }&nbsp;грн
                  </div>
               </div>
               <div className={ s.cost }>
                  <GoodsCount
                     enough={ enough }
                     count={ count }
                     totalQuantity={ totalQuantity }
                     minimum={ ignoreMinimum ? 1 : minimum }
                     setCount={ count => setGoodsCount(goodCode, count) }
                     setHideOrderBtn={ setHideOrderBtn }
                     goodCode={ goodCode }
                     setValidationState={ setValidationState }
                     disableIncrease={ isIncreasing }
                     disableDecrease={ isDecreasing }
                     onIncreaseQuantity={ handleIncreaseQuantity }
                     onDecreaseQuantity={ handleDecreaseQuantity }
                  />
                  <b className={ s.cost_value }>
                     {
                        // Так и должно быть. Для правильности отображения
                        prettyPrice(prettyPrice(price) * count)
                     }
                        &nbsp;грн
                  </b>
               </div>
            </div>
         </div>

         { measurement === 'м' && <WarningMessage message={ warningMessages[measurement] }/> }
         { totalQuantity < 4 && measurement === 'шт' && (
            <WarningMessage message={ warningMessages[measurement] }/>
         ) }
      </div>
   )
}

export default CartGoods
