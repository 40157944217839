import Link from 'next/link'
import s from './breadcrumbs.module.scss'

const BreadCrumbs = ({ breadcrumbs: breadcrumbsProp = [] }) => {
   if (!breadcrumbsProp || !breadcrumbsProp.length) {
      return null
   }

   const breadcrumbs = breadcrumbsProp.slice(0, -1)
   const lastBreadcrumb = breadcrumbsProp.slice(-1)[0]

   return (
      <div className={ s.bread_crumbs }>
         <BreadCrumbsItem title="Iнтернет-магазин TA-DA!" href="/"/>

         { breadcrumbs.map(item => (
            <BreadCrumbsItem
               key={ item.title }
               href={ item.href || `/products/category/${ item.categoryId }` }
               title={ item.title }
            />
         )) }

         <BreadCrumbsItem title={ lastBreadcrumb.title }/>
      </div>
   )
}

const BreadCrumbsItem = ({ href, title }) => {
   if (!href) {
      return (
         <span className={ s.bread_crumbs_item }>
            { title }
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M1 1L5 5L1 9" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
         </span>
      )
   }

   return (
      <span className={ s.bread_crumbs_item }>
         <Link href={ href } scroll={ false }>
            <a>
               { title }
               <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L1 9" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </a>
         </Link>
      </span>
   )
}

export default BreadCrumbs
