import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Contacts from '@components/common/contacts'
import PagesList from '@components/common/pages_list'
import s from './bottom_buttons.module.scss'
import Image from 'next/image'

const BottomButtons = () => {
   const informationItems = useSelector(state => state.informationItems)
   const partnersItems = useSelector(state => state.partnersItems)

   const formattedInformationItems = useMemo(
      () => informationItems.filter(item => item.href !== '/location'),
      [informationItems]
   )

   return (
      <div className={ s.bottom_buttons }>
         <BottomButtonsItem title="Інформація">
            <PagesList items={ formattedInformationItems }/>
         </BottomButtonsItem>
         <BottomButtonsItem title="Покупцям та партнерам">
            <PagesList items={ partnersItems }/>
         </BottomButtonsItem>
         <BottomButtonsItem title="Контакти" show={ true }>
            <Contacts textCenter/>
         </BottomButtonsItem>
      </div>
   )
}

const BottomButtonsItem = ({ title, children, show = false }) => {
   const [showContent, changeShowContent] = useState(show)

   const bottonContentStyle = {
      maxHeight: showContent ? 1000 : 0,
      borderBottom: showContent ? '1px solid #C4C4C4' : 'none'
   }

   return (
      <div className={ s.bottom_item }>
         <button onClick={ () => changeShowContent(prevShowContent => !prevShowContent) } className={ s.bottom_btn }>
            { title }
            <Image
               src={ '/images/common/arrow_down.svg' }
               width={ 10 }
               height={ 10 }
               className={ `${ s.arrow } ${ showContent ? s.rotate : '' }` }
               alt=""
            />
         </button>

         <div className={ s.bottom_content } style={ bottonContentStyle }>
            { children }
         </div>
      </div>
   )
}

export default BottomButtons
