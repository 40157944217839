import s from './swipe_handler.module.scss'
import { memo } from 'react'

const SwipeHandler = ({ leftAction, children }) => {

   let xDown = null
   let yDown = null

   const getTouches = e => e.touches || e.originalEvent.touches
   const handleTouchStart = e => {
      const firstTouch = getTouches(e)[0]
      xDown = firstTouch.clientX
      yDown = firstTouch.clientY
   }
   const handleTouchMove = e => {
      if (!xDown || !yDown) {
         return
      }

      const xUp = e.touches[0].clientX
      const yUp = e.touches[0].clientY

      const xDiff = xDown - xUp
      const yDiff = yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
         if (xDiff > 0) {
            leftAction()
         }
      }

      xDown = null
      yDown = null
   }

   return (
      <div
         className={ s.swipe_handler }
         onTouchStart={ handleTouchStart }
         onTouchMove={ handleTouchMove }
      >
         {children}
      </div>
   )
}

export default memo(SwipeHandler)
