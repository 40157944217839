import React, { useEffect, useState } from 'react'
import s from './button_switch.module.scss'

const ButtonSwitchItem = ({ title, active, onClick, disabled = false, disabledText }) => (
   <div
      className={ `
            ${ s.button_switch_item }
            ${ active ? s.active : '' }
            ${ disabled ? s.disabled : '' }
            ${ !disabledText ? s.disable_hover_effect : '' }
        ` }
      onClick={ onClick }
   >
      { title }

      { disabledText && (
         <div className={ s.info }>
            { disabledText }
         </div>
      ) }
   </div>
)

const ButtonSwitch = ({ children, defaultActive = 0 }) => {
   const [activeBtn, setActiveBtn] = useState(defaultActive)

   useEffect(() => {
      if (children[activeBtn].props.disabled && !children[0].props.disabled) {
         setActiveBtn(0)
         children[0].props.onClick()
      }
   }, [children])

   return (
      <div className={ s.button_switch }>
         {
            React.Children.map(children, (child, idx) => {
               const { disabled, onClick } = child.props
               return React.cloneElement(child, {
                  active: idx === activeBtn && !disabled,
                  onClick: () => {
                     if (!disabled) {
                        onClick()
                        setActiveBtn(idx)
                     }
                  }
               })
            })
         }
      </div>
   )
}

export { ButtonSwitchItem }
export default ButtonSwitch