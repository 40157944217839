import React, { memo, useCallback, useRef, useState } from 'react'
import useOutsideClick from '@functions/hooks/useOutsideClick'
import { clx } from '@utils'
import s from './menu.module.scss'

function Menu({ menuButton, placement = 'bottom-left', children }) {
   const [isOpen, setIsOpen] = useState(false)
   const itemsRef = useRef(null)
   const menuButtonRef = useRef(null)

   useOutsideClick(
      [itemsRef, menuButtonRef],
      () => {
         if (isOpen) setIsOpen(false)
      }
   )

   const handleMenuItemClick = useCallback((event) => {
      setIsOpen(!isOpen)
   }, [isOpen])

   return (
      <div className={ s.wrapper }>
         { React.cloneElement(menuButton, {
            onClick: handleMenuItemClick,
            className: s.menuButton,
            ref: menuButtonRef
         }) }

         <div ref={ itemsRef } className={ clx(s.items, isOpen ? s.open : s.hidden, s[placement]) }>
            { React.Children.map(children, (child, idx) => {
               return child
                  ? React.cloneElement(child, {})
                  : child
            }) }
         </div>
      </div>
   )
}

export default memo(Menu)
