import { HYDRATE } from 'next-redux-wrapper'
import { partnersPagesRoutes, informationsPagesRoutes } from '@static/navigation'
import { DEFAULT_SHOP_ID } from '@static/constants'

const initialState = {
   /* profile */
   profileInfo: null,
   cardNumber: null,
   wishlistGoods: [],
   viewedProducts: [],

   /* auth */
   phoneResetUuid: null,
   token: null,
   authKey: null,

   /* shipping */
   choosenCity: null,
   choosenAddress: null,
   choosenCityNP: null,
   choosenAddressNP: null,
   isNPShown: null,
   choosenShopId: DEFAULT_SHOP_ID,
   shippingAddress: '',
   shippingMethod: null,
   localShipping: null,
   userCoordinates: null,
   marketsList: [],
   closestMarketData: null,
   wasChangedMarket: false,
   settings: {
      'available_delivery': [
         {
            'method_id': 3,
            'company_id': 2,
            'company': 'novaposhta'
         }
      ],
      'hotline_phones': [
         '0 800 759 119',
         '(099) 413 72 59',
         '(067) 539 34 77'
      ]
   },

   /* cart */
   cartGoods: [],
   shoppingList: {},
   cardInfo: null,
   packages: null,
   minTotalOrder: 0,
   addIndicatorsList: [], // list items who adding to cart

   /* app */
   mainSlides: null,
   tabs: [],
   modalData: null, // { title, inner, top, extra, styles, required }
   modalMessage: null,
   showMobileSidebar: false,
   showCategories: false,
   showSearchHints: false,
   showClosestMarket: false,

   /* goods */
   productData: null,
   goodToAdd: null,
   popularList: null,
   newGoodsList: null,
   specialsList: null,
   categories: [],
   inStock: false,

   showFilter: false, // unused
   choosenPackage: null, // unused
   citiesNPList: [],
   addressesList: [], // unused

   informationItems: informationsPagesRoutes,
   partnersItems: partnersPagesRoutes,
   partnershipFileName: ''
}

const reducer = (state = initialState, { type, payload }) => {
   switch (type) {
   case HYDRATE:
      return {
         ...state,
         ...payload
      }
   case 'SET_VIEWED_PRODUCTS':
      return {
         ...state,
         viewedProducts: payload
      }
   case 'SET_WISHLIST_GOODS':
      return {
         ...state,
         wishlistGoods: payload
      }
   case 'REMOVE_GOOD_FROM_WISHLIST':
      return {
         ...state,
         wishlistGoods: state.wishlistGoods.filter(good => good.goodCode !== payload)
      }
   case 'REMOVE_GOODS_FROM_WISHLIST':
      return {
         ...state,
         wishlistGoods: state.wishlistGoods.filter(good => !payload.some(goodCode => +goodCode === good.goodCode))
      }
   case 'ADD_GOODS_TO_WISHLIST':
      return {
         ...state,
         wishlistGoods: [...state.wishlistGoods, ...payload]
      }
   case 'SET_SETTINGS':
      return {
         ...state,
         settings: payload
      }
   case 'SET_IN_STOCK':
      return {
         ...state,
         inStock: payload
      }
   case 'SET_IS_MOBILE':
      return {
         ...state,
         isMobile: payload
      }
   case 'SET_WAS_CHANGED_MARKET':
      return {
         ...state,
         wasChangedMarket: payload
      }
   case 'CHANGE_CATEGORIES':
      return {
         ...state,
         showCategories: payload
      }
   case 'CHANGE_FILTER':
      return {
         ...state,
         showFilter: payload
      }
   case 'CHANGE_PAGE_FOUND':
      return {
         ...state,
         pageFound: payload
      }
   case 'CHANGE_TABS':
      return {
         ...state,
         tabs: payload
      }
   case 'SET_AUTH_KEY':
      return {
         ...state,
         authKey: payload
      }
   case 'SET_CARD_NUMBER':
      return {
         ...state,
         cardNumber: payload
      }
   case 'SET_USER_TOKEN':
      return {
         ...state,
         token: payload
      }
   case 'SET_TABS':
      return {
         ...state,
         tabs: payload
      }
   case 'CHANGE_MODAL':
      return {
         ...state,
         modalData: payload
      }
   case 'SET_PROFILE_INFO': {
      return {
         ...state,
         profileInfo: {
            ...state.profileInfo,
            ...payload
         }
      }
   }
   case 'SET_CARD_INFO':
      return {
         ...state,
         cardInfo: payload
      }
   case 'LOGOUT':
      return {
         ...state,
         profileInfo: null,
         cardInfo: null,
         token: null,
         cardNumber: null,
         wishlistGoods: []
      }
   case 'SET_MODAL_MESSAGE':
      return {
         ...state,
         modalMessage: payload
      }
   case 'SET_MARKETS_LIST':
      return {
         ...state,
         marketsList: payload
      }
   case 'SET_CITIES_NP_LIST':
      return {
         ...state,
         citiesNPList: payload
      }
   case 'SET_ADDRESSES_LIST':
      return {
         ...state,
         addressesList: payload
      }
   case 'SET_CHOOSEN_CITY':
      return {
         ...state,
         choosenCity: payload
      }
   case 'SET_CHOOSEN_ADDRESS':
      return {
         ...state,
         choosenAddress: payload
      }
   case 'SET_CHOOSEN_CITY_NP':
      return {
         ...state,
         choosenCityNP: payload
      }
   case 'SET_CHOOSEN_ADDRESS_NP':
      return {
         ...state,
         choosenAddressNP: payload
      }
   case 'SET_NP_SHOWN_STATUS':
      return {
         ...state,
         isNPShown: payload
      }
   case 'GENERAL_SETTER':
      return {
         ...state,
         ...payload
      }
   case 'SET_SPECIALS_LIST':
      return {
         ...state,
         specialsList: payload
      }
   case 'SET_POPULAR_LIST':
      return {
         ...state,
         popularList: payload
      }
   case 'SET_NEW_GOODS_LIST':
      return {
         ...state,
         newGoodsList: payload
      }
   case 'SET_CATEGORIES':
      return {
         ...state,
         categories: payload
      }
   case 'SET_SHOP_ID':
      return {
         ...state,
         choosenShopId: payload
      }
   case 'SET_SHOW_SEARCH_HINTS':
      return {
         ...state,
         showSearchHints: payload
      }
   case 'SET_GOOD_DATA':
      return {
         ...state,
         goodData: payload
      }
   case 'SET_MAIN_SLIDES':
      return {
         ...state,
         mainSlides: payload
      }
   case 'SET_CLOSEST_MARKET_DATA':
      return {
         ...state,
         closestMarketData: payload
      }
   case 'SET_SHOW_CLOSEST_MARKET':
      return {
         ...state,
         showClosestMarket: payload
      }
   case 'SET_SHOPPING_LIST':
      return {
         ...state,
         shoppingList: payload
      }
   case 'SET_CART_GOODS':
      return {
         ...state,
         cartGoods: payload
      }
   case 'SET_PACKAGES':
      return {
         ...state,
         packages: payload
      }
   case 'SET_MIN_TOTAL_ORDER':
      return {
         ...state,
         minTotalOrder: payload
      }
   case 'SET_GOOD_TO_ADD':
      return {
         ...state,
         goodToAdd: payload
      }
   case 'SET_ADD_INDICATORS_LIST':
      return {
         ...state,
         addIndicatorsList: payload
      }
   case 'SET_CHOOSEN_PACKAGE':
      return {
         ...state,
         choosenPackage: payload
      }
   case 'SET_USER_COORDINATES':
      return {
         ...state,
         userCoordinates: payload
      }
   case 'SET_SHIPPING_METHOD':
      return {
         ...state,
         shippingMethod: payload
      }
   case 'SET_LOCAL_SHIPPING_METHOD':
      return {
         ...state,
         localShipping: payload
      }
   case 'SET_SHIPPING_ADDRESS':
      return {
         ...state,
         shippingAddress: payload
      }
   case 'SET_INFORMATION_ITEMS':
      return {
         ...state,
         informationItems: payload
      }
   case 'SET_PARTNERS_ITEMS':
      return {
         ...state,
         partnersItems: payload
      }
   case 'SET_SHOW_MOBILE_SIDEBAR':
      return {
         ...state,
         showMobileSidebar: payload
      }
   case 'PHONE_RESET_UUID':
      return {
         ...state,
         phoneResetUuid: payload
      }
   case 'SET_PRODUCT_DATA':
      return {
         ...state,
         productData: payload
      }
   case 'SET_PARTNERSHIP_FILE_NAME':
      return {
         ...state,
         partnershipFileName: payload
      }
   default:
      return state
   }
}

export { initialState }
export default reducer
