import s from './message.module.scss'
import { clx } from '@utils'

/**
 * @param {string} className
 * @param {string} message
 * @param {'success'|'warning'} type
 * */
export default function Message({ className, message, type= 'success' }) {

   if (!message) {
      return null
   }

   return (
      <div className={ clx(s.wrapper, className, s[type]) }>{ message }</div>
   )
}