import Image from 'next/image'
import s from './warning_message.module.scss'

const WarningMessage = ({ message }) => (
   <div className={ s.warning }>
      <div className={ s.image_wrapper }>
         <Image
            src="/images/common/warning.svg"
            width={ 18 }
            height={ 18 }
            alt=""
         />
      </div>
      <div className={ s.text }>
         { message }
      </div>
   </div>
)

export default WarningMessage
