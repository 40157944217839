import crypto from 'crypto'

export default function getApiKey() {
   const apiKey = process.env.API_KEY

   const date = new Date()
   let day = date.getUTCDate().toString()
   let month = (date.getUTCMonth() + 1).toString()
   const year = date.getUTCFullYear().toString()

   if (day.length < 2) {
      day = '0' + day
   }

   if (month.length < 2) {
      month = '0' + month
   }

   return crypto.createHash('SHA256').update(`${day}/${month}/${year}${apiKey}`).digest('hex')
}