import Image from 'next/image'
import s from './internal_error.module.scss'

export default function InternalErrorPage() {
   return (
      <div className={ s.wrapper }>
         <div className={ s.first_block }>
            <Image
               src="/images/500_error/topchik.svg"
               layout="fill"
               alt=""
            />
         </div>
         <div className={ s.second_block }>
            <a href="https://l.ta-da.ua/app_from_site" target="_blank" rel="noreferrer nofollow">
               <Image
                  src="/images/500_error/complect.svg"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                  alt=""
               />
            </a>
         </div>
      </div>
   )
}
