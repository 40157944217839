export const DEFAULT_SHOP_ID = 8

export const SITE_TITLE = 'TA-DA! - Товари для дому. Щодня!'

/**
 * Error codes
 */
export const ERROR_CODE = {
   EMAIL_ALREADY_EXIST: 24,
   INVALID_EMAIL: 8,
   INVALID_PHONE: 50,
   USER_NOT_AUTHORIZED: 52,
   SMS_LIMIT_REACHED: 59,
   OVEREXPOSURE_SMS_SENDING: 105,
   MINIMAL_ORDER_SUM: 130,

   PRODUCTS_NOT_FOUND: 66,
   PRODUCT_NOT_FOUND: 12,
}
