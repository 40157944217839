export const isDataArray = (array, minLength = 1) => Array.isArray(array) && array.length >= minLength

export const isDataObject = (object) => typeof object === 'object' && !Array.isArray(object) && Object.keys(object).length > 0

export const classList = (...classes) => {
   let result = ''
   const addClass = (element) => (result += ` ${element}`)
 
   classes.forEach((element) => {
      if (!element) return
      if (typeof element === 'string' || typeof element === 'number') addClass(element)
      if (isDataArray(element)) addClass(classList(...element))
      if (isDataObject(element)) Object.keys(element).forEach((key) => element[key] && addClass(key))
   })
   return result.trim()
}