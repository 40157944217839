import s from './adBannerPlank.module.scss'

const AdBannerPlank = () => {
   return (
      <div className={ s.plank }>
         {/* <img 
            src="/images/adBannerSmall.jpg" 
            alt="Ad Banner"
            className={ s.baner }
         /> */}
      </div>
   ) 
} 
export default AdBannerPlank