import Layout from '@components/layouts'
import SEOHead from '@components/pages/products/product/seo_head'

export default function DescribedErrorPage({ className, title, message }) {
   return (
      <Layout>
         <SEOHead
            siteTitle={ title }
         />
         <div className={ className }>
            <div className="container-xl">
               { message ?? 'Сталася помилка!' }
            </div>
         </div>
      </Layout>
   )
}
