import { useState } from 'react'
import Image from 'next/image'

import { clx } from '@utils'
import s from './contact_button.module.scss'


const ContactButton = () => {
   const [showContacts, setShowContacts] = useState(false)

   const handleClick = () => {
      setShowContacts(!showContacts)
   }

   return (
      <div className={ clx(showContacts && s.blackout_wrapper) } onClick={ handleClick }>
         <button className={ clx(s.chat_button, showContacts && s.hidden) } onClick={ handleClick }>
            <Image
               className={ s.chat_icon }
               src="/images/common/chat.svg"
               width={ 20 }
               height={ 20 }
               alt=""
            />
         </button>
         <div className={ clx(s.wrapper, showContacts && s.opened) }>
            <button className={ clx(s.contact_item, s.close) } onClick={ handleClick } >
               <Image
                  src="/images/common/cross.svg"
                  width={ 15 }
                  height={ 15 }
                  alt=""
               />
            </button>
            <a href="https://t.me/tada_contact_bot" target="_blank" className={ clx(s.contact_item, s.telegram) } rel="noreferrer nofollow" >
               <Image
                  src="/images/common/paper_plane.svg"
                  width={ 20 }
                  height={ 20 }
                  alt=""
               />
            </a>
            <a href="https://ta-da.ua/contact_us" className={ clx(s.contact_item, s.phones) } rel="noreferrer nofollow" >
               <Image
                  src="/images/common/phone.svg"
                  width={ 20 }
                  height={ 20 }
                  alt=""
               />
            </a>
         </div>
      </div>
   )
}

export default ContactButton
