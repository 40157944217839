import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAccount } from '@functions/hooks'
import { changeModal, setModalMessage } from '@redux/actions'
import TadaServiceContext from '@components/context/tada_service_context'

import Loading from '../loading'
import SuccessAuthContent from '../modal_content/success_auth_content'
import s from './form.module.scss'
import { clx } from '@utils'

const CodeForm = ({
   showSuccessText = false,
   updateUserShop = false,
   recallData,
   onSuccessLoggedIn = () => { }
}) => {

   const router = useRouter()

   const [code, changeCode] = useState(['', '', '', ''])
   const [loading, setLoading] = useState(false)

   const [loginCount, setLoginCount] = useState(0)

   const [codeTimer, setCodeTimer] = useState(90)
   const [codeInterval, setCodeInterval] = useState(null)

   const { cardNumber } = useSelector(({ cardNumber }) => ({ cardNumber }))

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const { setUserData, toAuthorization } = useAccount()

   const loginClick = e => {
      e.preventDefault()

      let cancelled = false

      if (loginCount >= 5) {
         dispatch(setModalMessage(''))
         toAuthorization()
      }

      setLoading(true)

      tadaService
         .loginByCode(cardNumber, code.join(''))
         .then(async res => {
            if (!cancelled) {
               dispatch(setModalMessage(''))

               const info = await setUserData(cardNumber, res.token, updateUserShop)

               if (info) {
                  const { userName, userSurname, email, phone, birthday } = info
                  let accountDataText = ''

                  if (!userName || !userSurname || !email || !phone || !birthday) {
                     accountDataText = 'Дозаповніть профіль для можливості відновлення доступу.'
                     router.push('/account')
                  }

                  if (showSuccessText) {
                     dispatch(changeModal({
                        title: 'Привіт!',
                        inner: <SuccessAuthContent accountDataText={ accountDataText } />,
                        extra: (
                           <img
                              className={ s.success_auth__image }
                              src="/images/common/dino/success_auth.svg"
                              alt=""
                           />
                        )
                     }))
                  }

                  onSuccessLoggedIn()
               } else {
                  dispatch(setModalMessage('Помилка!'))
                  setLoading(false)
               }
            }
         })
         .catch(e => {
            if (!cancelled) {
               console.log(e)
               setLoading(false)
               switch (e.error?.code) {
               case 40:
                  dispatch(setModalMessage('Неправильний код'))
                  setLoginCount(count => count + 1)
                  break
               case 70:
                  dispatch(setModalMessage('Код не належить даному користувачу'))
                  setLoginCount(count => count + 1)
                  break
               default:
                  dispatch(setModalMessage('Помилка!'))
                  tadaService.logError(e)
                  break
               }
            }
         })

      return () => cancelled = true
   }

   const handleCodeChange = order => {
      return event => {
         const value = event.target.value
         const nextInput = event.target.nextElementSibling

         changeCode(prev => {
            const newCode = [...prev]

            newCode[order - 1] = value

            return newCode
         })

         if (value.length >= 1 && nextInput) {
            nextInput.focus()
         }
      }
   }

   const handleKeyDown = event => {
      const value = event.target.value

      if ((event.key === 'Backspace' && !value) || (event.key === 'ArrowLeft' && !value)) {
         event.target.previousElementSibling?.focus()
      } else if (event.key === 'ArrowRight' && !value) {
         event.target.nextElementSibling?.focus()
      }
   }

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div className={ clx(s.form_column, s.part_input) }>
            <input
               maxLength={ 1 }
               type="text"
               id="code-1"
               value={ code[0] }
               onChange={ handleCodeChange(1) }
               onKeyDown={ handleKeyDown }
               inputMode="numeric"
               autoComplete="off"
            />
            <input
               maxLength={ 1 }
               type="text"
               id="code-2"
               value={ code[1] }
               onChange={ handleCodeChange(2) }
               onKeyDown={ handleKeyDown }
               inputMode="numeric"
               autoComplete="off"
            />
            <input
               maxLength={ 1 }
               type="text"
               id="code-3"
               value={ code[2] }
               onChange={ handleCodeChange(3) }
               onKeyDown={ handleKeyDown }
               inputMode="numeric"
               autoComplete="off"
            />
            <input
               maxLength={ 1 }
               type="text"
               id="code-4"
               value={ code[3] }
               onChange={ handleCodeChange(4) }
               onKeyDown={ handleKeyDown }
               inputMode="numeric"
               autoComplete="off"
            />
         </div>
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ loginClick } disabled={ !code }>Вхід</button>
            {!recallData?.call ? (
               <CodeTimer
                  type={ recallData.type }
                  value={ recallData.value }
                  timer={ codeTimer }
                  setTimer={ setCodeTimer }
                  codeInterval={ codeInterval }
                  setCodeInterval={ setCodeInterval }
               />
            ) : null}
         </div>
      </form>
   )
}

const CodeTimer = ({ type, value, timer, setTimer, codeInterval, setCodeInterval }) => {

   const [minutes, setMinutes] = useState(0)
   const [seconds, setSeconds] = useState(0)

   const [formMessage, setFormMessage] = useState('')
   const [robotCall, setRobotCall] = useState(false)

   const tadaService = useContext(TadaServiceContext)

   useEffect(() => {
      const codeInterval = setInterval(() => {
         setTimer(timer => timer - 1)
      }, 1000)

      setCodeInterval(codeInterval)

      return () => clearInterval(codeInterval)
   }, [])

   useEffect(() => {
      return () => clearInterval(codeInterval)
   }, [])

   useEffect(() => {
      if (timer <= 0) {
         clearInterval(codeInterval)
      }
      const newMinutes = Math.trunc(timer / 60)
      const newSeconds = timer - (newMinutes * 60)

      setMinutes(newMinutes)
      setSeconds(newSeconds)
   }, [timer])

   const handleSend = e => {
      e.preventDefault()

      if (robotCall) {
         setTimer(90)
         setCodeInterval(setInterval(() => setTimer(timer => timer - 1), 1000))
         tadaService
            .callAuthorization(type, value)
            .catch(e => {
               switch (e.error?.code) {
               default:
                  setFormMessage('Сталася помилка')
                  break
               }
            })
      } else {
         tadaService
            .userAuth(value)
            .then(() => {
               setTimer(90)
               setCodeInterval(setInterval(() => setTimer(timer => timer - 1), 1000))
            })
            .catch(e => {
               console.log(e)
               switch (e.error?.code) {
               case 59:
                  setFormMessage(<>Вичерпано ліміт смс! Але ви можете згенерувати <br /> дзвінок робота</>)
                  setRobotCall(true)
                  break
               default:
                  setFormMessage('Сталася помилка')
                  tadaService.logError(e)
                  break
               }
            })
      }
   }

   return (
      <div className={ s.code_timer } style={ { width: '100%' } }>
         <div style={ { margin: '10px 0' } }>
            {(formMessage || 'Надіслати код ще раз')}
            {timer > 0 ? <>&nbsp;через {minutes < 10 ? 0 : ''}{minutes}:{seconds < 10 ? 0 : ''}{seconds}</> : null}
         </div>
         <button className={ s.form_big_btn } disabled={ timer > 0 } onClick={ handleSend }>
            {robotCall ? 'Згенерувати дзвінок' : 'Надіслати ще раз'}
         </button>
      </div>
   )
}

export default CodeForm
