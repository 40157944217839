import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'next/image'

import TadaServiceContext from '@components/context/tada_service_context'
import { protectEmailString } from '@utils'
import { prettyPhone } from '@utils'
import { changeModal, setModalMessage } from '@redux/actions'

import NumberFormat from '../../number_format'
import Loading from '../../loading'
import CheckVerificationEmailCodeForm from './check_verification_email_code_form'
import s from '../form.module.scss'

const ResetPhoneForm = () => {
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const [phone, setPhone] = useState('')
   const [isValid, setIsValid] = useState(false)
   const [loading, setLoading] = useState(false)
   const isDisabled = !isValid

   const handleSendPhone = e => {
      e.preventDefault()

      if (!setIsValid) {
         return dispatch(setModalMessage('Введіть коректний номер телефону'))
      }

      setLoading(true)

      const formattedPhone = prettyPhone(phone)

      tadaService
         .userCheck('phone', formattedPhone)
         .then(([response]) => {
            const email = response.email
            const protectedEmail = protectEmailString(email)

            tadaService
               .codeGenerate('email', email)
               .catch(e => {
                  console.log(e)
                  switch (e?.error?.code) {
                  case 38:
                     dispatch(setModalMessage('Вичерпано ліміт смс! Спробуйте пізніше.'))
                     break
                  default:
                     dispatch(setModalMessage('Помилка!'))
                     tadaService.logError(e).catch(e => console.log(e))
                     break
                  }
               })

            dispatch(changeModal({
               title: 'Відновлення акаунту',
               inner: <CheckVerificationEmailCodeForm
                  protectedEmail={ protectedEmail }
                  uuid={ response.uuid }
                  phone={ formattedPhone }
               />,
               extra: (
                  <div className={ s.code_verification_dino }>
                     <Image src="/images/common/dino/new_phone.svg" width={ 200 } height={ 204 } alt=""/>
                  </div>
               )
            }))
         })
         .catch(e => {
            console.log(e)
            switch (e?.error?.code) {
            case 50:
            case 52:
               dispatch(setModalMessage('Користувач не зареєстрований'))
               break
            case 59:
               dispatch(setModalMessage('Вичерпано ліміт смс! Спробуйте пізніше.'))
               break
            default:
               dispatch(setModalMessage('Сталася помилка'))
               break
            }
         })
         .finally(() => setLoading(false))
   }

   if (loading) {
      return <Loading/>
   }

   return (
      <form className={ s.form }>
         <div className={ s.form_column }>
            <label htmlFor="forget">Введіть номер телефону, до якого прив'язаний акаунт</label>
            <NumberFormat
               format="+38 (###) ###-##-##"
               allowEmptyFormatting
               mask="_"
               type="tel"
               id="forget"
               value={ phone }
               onValueChange={ ({ formattedValue, value }) => {
                  setPhone(formattedValue)
                  setIsValid(value.length === 10)
               } }
            />
            {/*<input type="text" id="forget" value={phone} onChange={handleChange}/>*/}
         </div>

         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ handleSendPhone } disabled={ isDisabled }>Далі</button>
         </div>
      </form>
   )
}

export default ResetPhoneForm
