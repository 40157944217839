import { useCallback, useContext } from 'react'
import TadaServiceContext from '../../components/context/tada_service_context'
import { useDispatch, useSelector } from 'react-redux'
import {
   addGoodsToWishlist as addGoodsToWishlistAction,
   removeGoodFromWishlist as removeGoodFromWishlistAction,
   removeGoodsFromWishlist as removeGoodsFromWishlistAction,
   setWishlistGoods
} from '../../redux/actions'
import { useAccount } from './index'

export default function useWishlist() {

   const tadaService = useContext(TadaServiceContext)

   const dispatch = useDispatch()

   const { toAuthorization } = useAccount()

   const token = useSelector(state => state.token)
   const wishlistGoods = useSelector(state => state.wishlistGoods)

   const isFavorite = useCallback(
      goodCode => wishlistGoods.some(good => good.goodCode === goodCode),
      [wishlistGoods]
   )

   const getWishlistGoods = useCallback(() => {
      return tadaService
         .wishlist(token)
         .then(goods => {
            dispatch(setWishlistGoods(goods))
         })
         .catch(tadaService.logError)
   }, [token])

   const handleAddGoodToWishlist = useCallback(goodCode => {
      return tadaService
         .addGoodToWishlist(token, goodCode)
         .then(addedGood => {
            dispatch(addGoodsToWishlistAction(addedGood))
         })
         .catch(error => {
            tadaService.logError(error)
         })
   }, [token])

   const addGoodToWishlist = goodCode => {
      if (!token) {
         return toAuthorization({
            showSuccessText: true
         })
      }

      if (!isFavorite(goodCode)) {
         handleAddGoodToWishlist(goodCode)
      } else {
         removeGoodFromWishlist(goodCode)
      }
   }

   const addGoodsToWishlist = useCallback(goodsCodes => {
      return tadaService
         .addGoodsToWishlist(token, goodsCodes)
         .then(addedGoods => {
            dispatch(addGoodsToWishlistAction(addedGoods))
         })
         .catch(tadaService.logError)
   }, [token])

   const removeGoodFromWishlist = useCallback(goodCode => {
      return tadaService
         .removeGoodFromWishlist(token, goodCode)
         .then(() => {
            dispatch(removeGoodFromWishlistAction(goodCode))
         })
         .catch(tadaService.logError)
   }, [token])

   const removeGoodsFromWishlist = useCallback(goodsCodes => {
      return tadaService
         .removeGoodsFromWishlist(token, goodsCodes)
         .then(() => {
            dispatch(removeGoodsFromWishlistAction(goodsCodes))
         })
         .catch(tadaService.logError)
   }, [token])

   return {
      isFavorite,
      getWishlistGoods,
      addGoodToWishlist,
      addGoodsToWishlist,
      removeGoodFromWishlist,
      removeGoodsFromWishlist
   }
}