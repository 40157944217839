import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowClosestMarket } from '@redux/actions'
import { useSetMarket } from '@functions/hooks'
import s from './closest_market.module.scss'

const defaultProgressValue = 285
const timeToClose = 20

const ClosestMarket = () => {
   const { setMarket } = useSetMarket()
   const dispatch = useDispatch()

   const closestMarketData = useSelector(state => state.closestMarketData)
   const marketsList = useSelector(state => state.marketsList)
   const { choosenCityNP, choosenAddressNP, isNPShown } = useSelector(state => state)

   /* TODO(refactor): rename to timeToClose */
   const [closeTime, setCloseTime] = useState(timeToClose)

   useEffect(() => {
      const closeTimeout = setTimeout(() => handleClose(), timeToClose * 1000)
      setCloseTime(time => time - 1)
      const closeInterval = setInterval(() => setCloseTime(time => time - 1), 1000)

      return () => {
         clearTimeout(closeTimeout)
         clearInterval(closeInterval)
      }
   }, [])

   const handleClose = () => dispatch(setShowClosestMarket(false))

   const saveClick = async () => {
      let address = {}

      const city = marketsList.find(({ items }) => {
         const foundedAddress = items.find(({ shopId }) => shopId === closestMarketData.shopId)
         address = foundedAddress || {}

         return foundedAddress
      })

      setMarket(city, address, undefined, choosenCityNP, choosenAddressNP, isNPShown)
      handleClose()
   }

   return (
      <div className={ s.closest_market }>
         <div className={ `${ s.closest_market_bg } show_on_mobile` }/>
         <div className={ s.closest_market_container }>
            <div className={ s.inner }>
               <MarketControl
                  closestMarketData={ closestMarketData }
                  handleClose={ handleClose }
                  closeProgress={ (closeTime / timeToClose) * defaultProgressValue }
                  closeTime={ closeTime }
               />
               <div className={ s.market_image }>
                  <Image
                     src={ closestMarketData.src }
                     width={ 340 }
                     height={ 177 }
                     quality={ 100 }
                     className={ s.test }
                     alt=""
                  />
               </div>
               <MarketInfo closestMarketData={ closestMarketData } saveClick={ saveClick }/>
            </div>
         </div>
      </div>
   )
}

const MarketControl = ({ closestMarketData, handleClose, closeProgress, closeTime }) => (
   <div className={ s.market_control }>
      <div className={ `${ s.market_control_item }` }>
         <div className={ s.market_control_image }>
            <img src="/images/common/market_paper_white.svg" />
         </div>
         { closestMarketData.dist.toFixed(2) } км
      </div>
      <div className={ `${ s.market_control_item } ${ s.market_close }` }>
         <div className={ s.market_control_image } onClick={ handleClose }>
            <div className={ s.close_progress }>
               <svg
                  height="30"
                  width="30"
                  viewBox="0 0 100 100"
                  style={ { transform: 'rotate(-90deg)' } }
               >
                  <circle
                     cx="50"
                     cy="50"
                     r="45"
                     stroke="#fff"
                     strokeWidth="8"
                     strokeDasharray={ `${ closeProgress } 9999` }
                     fill="none"
                  />
               </svg>
            </div>
            <img src="/images/common/cross_white.svg" />
         </div>
         { closeTime + 1 } сек
      </div>
   </div>
)

const MarketInfo = ({ closestMarketData: { value: address, scheduleWeekday }, saveClick }) => (
   <div className={ s.market_info }>
      <div className={ s.market_info_item }>
         <div className={ s.title_container }>
            <div className={ s.info_title }>
               <span>Графік роботи</span>
               { scheduleWeekday }
            </div>
            <div className={ s.info_title }>
               <span>Адреса :</span>
               { address }
            </div>
         </div>
         <div className={ s.info_button }>
            <button onClick={ saveClick }>
               <img src="/images/common/market_pict.svg" />
            </button>
            Обрати найближчий супермаркет
         </div>
      </div>
   </div>
)

export default ClosestMarket
