import { useState } from 'react'
const useImageFormatErrorHandler = (initialSrc) => {
   const [imageSrc, setImageSrc] = useState(initialSrc)

   const handleImageError = () => {
      setImageSrc(initialSrc.replace(/\.webp/g, ''))
   }

   return [imageSrc, handleImageError]
}

export default useImageFormatErrorHandler