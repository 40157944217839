import Link from 'next/link'
import Image from 'next/image'
import s from './sidebar_buttons.module.scss'

const SidebarButtons = ({ children, items = [], changeVisibility }) => {
   return (
      <div className={ s.sidebar_buttons }>
         { children }
         { Array.isArray(items) 
            ? items.map(item => (
               <SidebarButtonsItem
                  key={ item.id }
                  { ...item }
                  onClick={ () => changeVisibility(false) }
               />
            )) 
            : null
         }
      </div>
   )
}

const SidebarButtonsItem = ({ src, title, href, onClick }) => {
   const inner = (
      <>
         <div className={ s.item_image }>
            <Image
               src={ src }
               width={ 20 }
               height={ 20 }
               alt=""
            />
         </div>
         <p>{title}</p>
      </>
   )

   const body = href ? <Link href={ href }><a>{inner}</a></Link> : <button>{inner}</button>

   return (
      <div className={ s.sidebar_buttons_item } onClick={ onClick }>
         {body}
      </div>
   )
}

export { SidebarButtonsItem }
export default SidebarButtons
