import dynamic from 'next/dynamic'
import { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeModal, setAddIndicatorsList, setGoodToAdd, setShowMobileSidebar } from '@redux/actions'
import { useSetMarket, useShoppingList } from '@functions/hooks'

import Error from '../error'
import TadaServiceContext from '@components/context/tada_service_context'
import SelectMarketForm from './select_market_form'
import s from './form.module.scss'
import ButtonSwitch, { ButtonSwitchItem } from '@components/common/button_switch'
import SelectWarehouseForm from '@components/common/forms/select_warehouse_form'
import { analytics, clx, initSentry } from '@utils'

const Map = dynamic(
   () => import('../../maps/chose_market_form_map').then((mod) => mod.default),
   {
      ssr: false,
      loading: () => null,
   },
)

const ChoseMarketForm = ({ title, isCheckout = false, setIsEditable }) => {

   const tadaService = useContext(TadaServiceContext)

   const { setMarket } = useSetMarket()

   const [error, setError] = useState(false)
   const { delivery } = useSelector(state => ({ delivery: state?.profileInfo?.delivery }))
   const { citiesNPList } = useSelector(({ citiesNPList }) => ({ citiesNPList }))
   const { isNPShown, choosenCityNP, choosenAddressNP, choosenCity, choosenAddress, settings: { available_delivery } } = useSelector((store) => store)
   const isNPAvailable = available_delivery.some(el => el.company.includes('novaposhta'))
   const [deliveryMethod, setDeliveryMethod] = useState(isNPShown)
   const [warehouseList, setWarehouseList] = useState([])

   const dispatch = useDispatch()
   const { marketsList, goodToAdd, addIndicatorsList } = useSelector(({ marketsList, goodToAdd, addIndicatorsList }) => ({ marketsList, goodToAdd, addIndicatorsList }))

   const { incGoodsCount } = useShoppingList()

   const [city, setCity] = useState(null)
   const [address, setAddress] = useState(null)

   const [cityNP, setCityNP] = useState(null)
   const [warehouse, setWarehouse] = useState(null)


   const loadWarehousList = async id => {
      setCityNP && tadaService
         .getNPWarehouseList(id)
         .then(res => setWarehouseList(res))
         .catch(e => {
            tadaService.logError(e)
         })
   }

   useEffect(() => {
      const cityDelivery = delivery ? delivery.city : choosenCityNP
      const addressDelivery = delivery ? delivery.address : choosenAddressNP

      if (!cityNP && !warehouse && !!cityDelivery && !!addressDelivery) {
         setCityNP(citiesNPList.find(({ label }) => label === cityDelivery))
         //loadWarehousList(citiesNPList.find(({ label }) => label === choosenCityNP).id)

         try {
            tadaService.getNPWarehouseList(citiesNPList.find(({ label }) => label === cityDelivery)?.id).then(res => {
               setWarehouseList(res)
               setWarehouse(res.find(({ label }) => label === addressDelivery))
            })
         } catch (error) {
            // try catch added for not crash app
         }
      }
      if (!city && !address && !!choosenCity && !choosenCity.hasOwnProperty('items') && !!choosenAddress) {
         setCity(marketsList.find(({ label }) => label === choosenCity))
         setAddress(marketsList.find(({ label }) => label === choosenCity).items.find(({ label }) => label === choosenAddress))
      }
   }, [])

   /* useEffect(() => {
      setWarehouse(warehouseList[0])
   }, [warehouseList]) */

   const changeCity = item => {
      const city = marketsList.find(({ label }) => label === item.label)
      setCity(item)
      setAddress(city.items.find(({ active }) => !!active))
   }

   const changeNPCity = item => {
      setCityNP(item)
      setWarehouse(null)
      loadWarehousList(item.id)
   }

   const changeAddress = address => setAddress(address)
   const changeWarehouse = warehouse => setWarehouse(warehouse)

   const markerClick = (city, address) => {
      if (address.active) {
         changeCity(city)
         setAddress(address)
      }
   }

   const saveClick = async e => {
      e.preventDefault()

      /* if ((!city && !address) || (!cityNP && !warehouse)) {
         return
      } */

      const res = await setMarket(city, address, undefined, cityNP, warehouse, deliveryMethod).then(() => {
         if (isCheckout) setIsEditable(false)
      })

      if (res?.error) {
         setError(true)
      }

      goodToAdd && tadaService
         .getProductByCode(goodToAdd, !!address && !!address.shopId ? +address.shopId : res?.shop?.shop_id)
         .then(({ quantity, ignoreMinimum, minimum, minimumOrderQuantity }) => {
            dispatch(setAddIndicatorsList([
               ...addIndicatorsList,
               { title, code: goodToAdd, quantity }
            ]))
            quantity > 0 && incGoodsCount(goodToAdd, minimumOrderQuantity/* !!ignoreMinimum ? 1 : minimum */)
         })
         .catch(e => {
            tadaService.logError(e)
         })

      dispatch(setGoodToAdd(null))
      dispatch(setShowMobileSidebar(false))
      dispatch(changeModal(null))
   }

   if (error) {
      return <Error />
   }

   const supermarketForm = deliveryMethod ?
      <>
         <SelectWarehouseForm
            city={ cityNP }
            cities={ citiesNPList }
            warehouse={ warehouse }
            warehouseList={ warehouseList }
            changeCity={ changeNPCity }
            changeWarehouse={ changeWarehouse }
            setCity={ setCityNP }
            setWarehouse={ setWarehouse }
            isCheckout={ isCheckout }
         />
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ saveClick } disabled={ !cityNP || !warehouse }>Застосувати</button>
         </div>
         <div className={ s.form_column }>
            <span className={ s.form_item_desc }>
               *Вартість доставки за тарифами перевізника.
            </span>
            <span className={ s.form_item_desc }>
               *Ціна та кількість наявного товару може змінюватись залежно від обраного місця доставки.
            </span>
         </div>
      </>
      : <>
         <SelectMarketForm
            city={ city }
            address={ address }
            changeCity={ changeCity }
            changeAddress={ changeAddress }
            setCity={ setCity }
            setAddress={ setAddress }
         />
         {!isCheckout && <div className={ s.form_column } style={ { height: 250 } }>
            <Map center={ address && [address.latitude, address.longitude] } data={ address } markerClick={ markerClick } />
         </div>}
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ saveClick } disabled={ !city || !address }>Застосувати</button>
         </div>
         <div className={ s.form_column }>
            <span className={ s.form_item_desc }>
               *Кількість наявного товару може змінюватись залежно від обраного супермаркету.
            </span>
         </div>
      </>

   return (
      <form className={ clx(s.form, s.max_width) }>
         {!isCheckout && (
            <ButtonSwitch defaultActive={ +isNPShown }>
               <ButtonSwitchItem
                  title="Супермаркет"
                  onClick={ () => setDeliveryMethod(false) }
               />
               <ButtonSwitchItem
                  title="Нова Пошта"
                  onClick={ () => setDeliveryMethod(true) }
                  disabled={ !isNPAvailable }
               />
            </ButtonSwitch>
         )}

         {supermarketForm}


      </form>
   )
}

export default ChoseMarketForm
