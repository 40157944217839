import Head from 'next/head'
import { useRouter } from 'next/router'

export default function SEOHead({
   siteTitle,
   description,
   productTitle,
   image,
   price
}) {
   const router = useRouter()

   return (
      <Head>
         <title>{ siteTitle }</title>
         <meta name="description" content={ description } />

         {/* Open Graph */ }
         <meta property="og:site_name" content="TA-DA! - Товари для дому. Щодня!"/>
         <meta property="og:url" content={ 'https://ta-da.ua' + router.asPath }/>
         <meta property="og:type" content="product"/>
         <meta property="og:title" content={ productTitle }/>
         <meta property="og:description" content={ description }/>
         <meta property="og:image" content={ image }/>

         <meta property="product:price.amount" content={ price }/>
         <meta property="product:price.currency" content="UAH"/>
      </Head>
   )
}
