import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useContext } from 'react'

import { setCartGoods, setShoppingList } from '../../redux/actions'
import TadaServiceContext from '../../components/context/tada_service_context'
import { setCookie } from '@utils'

export default function useShoppingList() {

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const shoppingList = useSelector(state => state.shoppingList)
   const cartGoods = useSelector(state => state.cartGoods)
   const token = useSelector(state => state.token)

   const getPrevGoodQuantity = useCallback(goodCode => {
      const increasingGoodCode = Object.keys(shoppingList).find(gCode => +gCode === goodCode)

      return shoppingList?.[increasingGoodCode] ?? 0
   }, [shoppingList])

   const incGoodsCount = async (goodCodeOrCodes, count = 1) => {

      const isSeveralGoods = typeof goodCodeOrCodes === 'object'

      let goodCode = null
      let newShoppingList = {}

      if (isSeveralGoods) {
         newShoppingList = { ...shoppingList, ...goodCodeOrCodes }
      } else {
         goodCode = goodCodeOrCodes

         newShoppingList = {
            ...shoppingList,
            [goodCode]: shoppingList[goodCode] ? ((Math.floor(shoppingList[goodCode] / count) * count) + count) : count
         }
      }

      const handleSuccessAddedToCart = () => {
         dispatch(setShoppingList(newShoppingList))
         saveCart(newShoppingList)
      }

      if (token) {
         if (isSeveralGoods) {
            const goods = Object.keys(newShoppingList).reduce((acc, goodCode) => {
               acc.push({
                  quantity: newShoppingList[goodCode],
                  goodCode
               })

               return acc
            }, [])

            await tadaService
               .addGoodsToCart(goods, token)
               .then(handleSuccessAddedToCart)
               .catch(tadaService.logError)
         } else {
            await tadaService
               .addGoodToCart(goodCode, getPrevGoodQuantity(goodCode) + count, token)
               .then(handleSuccessAddedToCart)
               .catch(tadaService.logError)
         }
      } else {
         dispatch(setShoppingList(newShoppingList))
         saveCart(newShoppingList)
      }
   }

   const decGoodsCount = async (goodCode, count = 1) => {
      if (!shoppingList[goodCode] || !(shoppingList[goodCode] > count))
         return

      const newShoppingList = {
         ...shoppingList,
         [goodCode]: (Math.ceil(shoppingList[goodCode] / count) * count) - count
      }

      if (token) {
         await tadaService
            .addGoodToCart(goodCode, getPrevGoodQuantity(goodCode) - count, token)
            .then(res => {
               dispatch(setShoppingList(newShoppingList))
               saveCart(newShoppingList)
            })
            .catch(tadaService.logError)
      } else {
         dispatch(setShoppingList(newShoppingList))
         saveCart(newShoppingList)
      }
   }

   const setGoodsCount = (goodCode, count = 1) => {
      const newShoppingList = {
         ...shoppingList,
         [goodCode]: count
      }

      dispatch(setShoppingList(newShoppingList))
      saveCart(newShoppingList)
   }

   const removeGood = goodCode => {
      const newShoppingList = { ...shoppingList }
      delete newShoppingList[goodCode]

      dispatch(setCartGoods(cartGoods.filter(item => item.goodCode !== goodCode)))
      dispatch(setShoppingList(newShoppingList))
      saveCart(newShoppingList)
   }

   const saveCart = cart => {
      setCookie(
         null, 'cart',
         JSON.stringify(cart),
         { maxAge: 24 * 60 * 60 }
      )
   }

   return { incGoodsCount, decGoodsCount, removeGood, setGoodsCount, saveCart }
}