import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'next/image'
import Link from 'next/link'

import { getLoginTypeAndValue } from '@utils'
import { useAccount } from '@functions/hooks'
import { changeModal, setModalMessage } from '@redux/actions'

import TadaServiceContext from '@components/context/tada_service_context'
import Loading from '../loading'
import CodeForm from './code_form'
import PhoneCheckForm from './phone_check_form'
import ResetPhoneForm from './reset_phone'
import NumberFormat from '../number_format'
import { ERROR_CODE } from '@static/constants'
import s from './form.module.scss'

const LoginForm = ({
   showSuccessText = false,
   updateUserShop = false,
   onSuccessLoggedIn = () => {}
}) => {

   const [login, changeLogin] = useState('')
   const [loading, setLoading] = useState(false)

   const [formMessage, setFormMessage] = useState('')
   const [isValidPhone, setIsValidPhone] = useState(false)
   const [showRobotCall, setShowRobotCall] = useState(false)

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const { setCard } = useAccount()

   const loginClick = e => {
      e.preventDefault()

      if (showRobotCall || login === '') {
         return
      }

      let cancelled = false

      const { type, value } = getLoginTypeAndValue(login)

      if (type === 'phone') {
         setLoading(true)

         dispatch(setModalMessage(''))

         tadaService
            .userCheck(type, value)
            .then(() => tadaService.userAuth(value))
            .then(({ success, user }) => {
               if(!cancelled && success) {
                  setCard(user.cardNumber)
                  setLoading(false)
                  dispatch(changeModal({
                     title: 'Код підтвердження',
                     inner: <CodeForm
                        recallData={ { type, value } }
                        showSuccessText={ showSuccessText }
                        updateUserShop={ updateUserShop }
                        onSuccessLoggedIn={ onSuccessLoggedIn }
                     />,
                     extra: (
                        <div className={ s.confirm_code_dino }>
                           <Image src="/images/common/dino/confirm_code.svg" width={ 233 } height={ 266 } alt=""/>
                        </div>
                     )
                  }))
               }
            })
            .catch(e => {
               if (!cancelled) {
                  console.log(e)
                  setLoading(false)
                  switch(e.error?.code) {
                  case ERROR_CODE.INVALID_PHONE:
                     dispatch(changeModal({
                        title: 'Підтвердіть правильність номеру',
                        inner: <PhoneCheckForm showSuccessText={ showSuccessText } updateUserShop={ updateUserShop } phone={ login } />
                     }))
                     break
                  case ERROR_CODE.USER_NOT_AUTHORIZED:
                     setFormMessage('Користувач не зареєстрований')
                     break
                  case ERROR_CODE.OVEREXPOSURE_SMS_SENDING: {
                     const cooldown = /--(.*?)--/g.exec(e.error.message)[1]
                     setFormMessage(`Досягнуто ліміт SMS. Новий код через ${ cooldown } секунд`)
                     break
                  }
                  case ERROR_CODE.SMS_LIMIT_REACHED:
                     setFormMessage('Вичерпано ліміт смс! Спробуйте пізніше.')
                     setShowRobotCall(true)
                     break
                  default:
                     setFormMessage('Сталася помилка')
                     tadaService.logError(e)
                     break
                  }
               }
            })
      } else {
         dispatch(setModalMessage('Введіть номер телефону!'))
      }

      return () => cancelled = true
   }

   const robotClick = e => {
      e.preventDefault()

      let cancelled = false

      const { type, value } = getLoginTypeAndValue(login)

      if(type === 'phone') {
         setLoading(true)

         dispatch(setModalMessage(''))

         tadaService
            .userCheck(type, value)
            .then(() => tadaService.callAuthorization(type, value))
            .then(({ statusCode, cardNumber }) => {
               if(!cancelled && statusCode === 200) {
                  setCard(cardNumber)
                  setLoading(false)
                  dispatch(changeModal({
                     title: 'Код підтвердження',
                     inner: <CodeForm
                        recallData={ { type, value, call: true } }
                        showSuccessText={ showSuccessText }
                        updateUserShop={ updateUserShop }
                        onSuccessLoggedIn={ onSuccessLoggedIn }
                     />,
                     extra: (
                        <div className={ s.confirm_code_dino }>
                           <Image src="/images/common/dino/confirm_code.svg" width={ 233 } height={ 266 } alt=""/>
                        </div>
                     )
                  }))
               }
            })
            .catch(e => {
               if(!cancelled) {
                  console.log(e)
                  setLoading(false)

                  switch(e.error?.code) {
                  case ERROR_CODE.INVALID_PHONE:
                     dispatch(changeModal({ title: 'Підтвердіть правильність номеру', inner: <PhoneCheckForm showSuccessText={ showSuccessText } phone={ login } /> }))
                     break
                  case ERROR_CODE.USER_NOT_AUTHORIZED:
                     dispatch(setModalMessage('Користувач не зареєстрований'))
                     break
                  case ERROR_CODE.OVEREXPOSURE_SMS_SENDING:
                     dispatch(setModalMessage('Ліміт: одне повідомлення в 90 секунд. Спробуйте пізніше.'))
                     break
                  case ERROR_CODE.SMS_LIMIT_REACHED:
                     dispatch(setModalMessage('Вичерпано ліміт смс! Спробуйте пізніше.'))
                     setShowRobotCall(true)
                     break
                  default:
                     dispatch(setModalMessage('Сталася помилка'))
                     break
                  }
               }
            })
      } else {
         dispatch(setModalMessage('Введіть номер телефону!'))
      }

      return () => cancelled = true
   }

   const forgetPhoneClick = event => {
      dispatch(changeModal({
         title: 'Відновлення акаунту',
         inner: <ResetPhoneForm />,
         extra: (
            <div className={ s.new_phone_dino }>
               <Image
                  src="/images/common/dino/what_happened.svg"
                  width={ 356 }
                  height={ 172 }
                  alt=""
               />
            </div>
         )
      }))
   }

   const robotBtn = showRobotCall ? (
      <div className={ s.form_center }>
         <button className={ s.form_big_btn } onClick={ robotClick }>Згенерувати дзвінок</button>
      </div>
   ) : null

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         { formMessage && <div style={ { marginTop: '10px' } }>{ formMessage }</div> }
         <div className={ s.form_column }>
            <label htmlFor="login">Телефон</label>
            <NumberFormat
               format="+38 (###) ###-##-##"
               allowEmptyFormatting
               mask="_"
               type="tel"
               id="login"
               value={ login }
               onValueChange={ ({ formattedValue, value }) => {
                  changeLogin(formattedValue)
                  setIsValidPhone(value.length === 10)
               } }
            />
            <span className={ s.forget_phone } onClick={ forgetPhoneClick }>Немає доступу до номеру?</span>
         </div>
         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ loginClick } disabled={ showRobotCall || !isValidPhone }>Вхід</button>
         </div>
         {robotBtn}
         <p style={ { marginBottom: 0, color: '#A8A8A8' } }>
                *Продовжуючи, я погоджуюсь з умовами&nbsp;
            <Link href="/bonus">
               <a onClick={ () => dispatch(changeModal(null)) } style={ { color: '#3342AA' } }>бонусної програми</a>
            </Link>
         </p>
      </form>
   )
}

export default LoginForm
