import { setCookie as nookiesSetCookie } from 'nookies'
import { destroyCookie as nookiesDestroyCookie } from 'nookies'

export default function setCookie(ctx, name, value, options) {
   const tempOpt = { path: '/' }
   nookiesSetCookie(ctx, name, value, {
      ...tempOpt,
      ...options
   })
}

export function destroyCookie(ctx, name, value, options) {
   nookiesDestroyCookie(ctx, name, value, {
      path: '/',
      ...options,
   })
}