import { sentryException } from '@utils/sentry'
import { prettyPrice } from '@utils'

const crypto = require('crypto')
class MyHeaders {
    _headers = {}

    get end() { return this._headers }

    /**
     * @param { 'json' | 'text' } type
     * */
    contentType(type) {
       const types = {
          json: 'application/json',
          text: 'text/plain'
       }

       this._headers['Content-Type'] = types[type]

       return this
    }

    /**
     * @param { 'ua' | 'ru' } lang
     * */
    userLanguage(lang) {
       const languages = {
          ua: 'ua',
          ru: 'ru'
       }

       this._headers['User-Language'] = languages[lang] || languages['ua']

       return this
    }

    platform() {
       this._headers['Platform'] = 'Site'
       return this
    }

    authBearer(token) {
       if(!token) return this
       this._headers['Authorization'] = 'Bearer ' + token
       return this
    }
}

const SalaryType = {
   Hidden: 0,
   Fixed: 1,
   Ranged: 2,
}

const categoriesCache = {}
const shopsCache = {}
const npcitiesCache = {}

export default class TadaService {

    #API_BASE = 'https://api.ta-da.net.ua'
    #NO_IMAGE_PATH = '/uploads/app/no_image.png'

    constructor(authorizationKey) {
       this.authorizationKey = authorizationKey
    }

    setAuthToken = token => {
       this.authToken = token
    }

    hash = str => {
       return crypto.createHash('SHA256').update(str).digest('hex')
    }

    request = async (path, requestOptions, headers, version = 1) => {
       const headersList = new Headers()

       if (!headers['Authorization']) {
          headersList.append('Authorization', `Key ${this.authorizationKey}`)
       }

       if (!headers['Platform']) {
          headersList.append('Platform', 'Site')
       }

       for (const key in headers) {
          headersList.append(key, headers[key])
       }

       requestOptions.headers = headersList

       const res = await fetch(`${this.#API_BASE}/v${version}/mobile/${path}`, requestOptions)

       if (!res.ok) throw await res.json()

       return await res.json()
    }

    #baseGoodsSearch = async (data, params) => {
       const {
          categoryId = null,
          searchString = '',
          shopId = 8,
          filterInStock = 0,
          filterPrice = null,
          sort = { direction, limit, offset, order },
          filterAttributes = null,
          options = {},
       } = data

       const { direction = 'ASC', limit = 24, offset = 0, order = 'name' } = sort

       const body = {
          'shop_id': shopId,
          'sort': {
             'direction': direction,
             'limit': limit,
             'offset': offset,
             'order': order
          },
          'filter_attributes': filterAttributes ? filterAttributes.map(({
             attributesTitle,
             attributes
          }) => ({ attributes_title: attributesTitle, attributes })) : []
       }

       categoryId && (body['category_id'] = categoryId)
       searchString && (body['search_string'] = searchString)
       filterInStock && (body['filter_in_stock'] = filterInStock)
       filterPrice && (filterPrice.start || filterPrice.end) && (body['filter_price'] = filterPrice)

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       const { url, version, headers = {} } = params

       const res = await this.request(
          url,
          requestOptions,
          { ...(new MyHeaders().userLanguage('ua').contentType('json').end), ...headers },
          version,
       )

       return this.transformSearch(res, options)
    }

    parseGoodsImage = image => {
       if (image) {

          if (image.startsWith(this.#API_BASE)) {
             return image
          }

          return this.#API_BASE + image

       } else {
          return this.#API_BASE + this.#NO_IMAGE_PATH
       }
    }

    getPromoActions = async () => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const response = await this.request(
          'promos.list',
          requestOptions,
          {},
          1.2,
       )

       return this.transformPromoActions(response)
    }

    getCardCode = (type, value) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       return this.request(`card.check?type=${type}&value=${encodeURIComponent(value)}`, requestOptions, {}, 1.1)
    }

    userAuth = async phone => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'phone': phone }),
          redirect: 'follow'
       }

       const res = await this.request('user.auth', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
          , 1.1)

       return this.transformUserAuth(res)
    }

    callAuthorization = async (type, value) => {

       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({
             'type': type,
             'value': value
          }),
          redirect: 'follow'
       }

       const res = await this.request('call.authorization', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
          , 1.1)

       return this.transformCallAuthorization(res)
    }

    userCheck = async (type, value) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'type': type, 'value': value }),
          redirect: 'follow'
       }

       return await this.request('user.check', requestOptions,
          new MyHeaders().contentType('json').end
          , 1.1)
    }

    sendTempCode = phone => {
       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({ 'type': 'phone', 'value': phone }),
          redirect: 'follow'
       }

       return this.request('send.code.authorization', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
          , 1.1)
    }

    loginByCode = (cardNumber, code) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'card_number': cardNumber, 'code': code }),
          redirect: 'follow'
       }

       return this.request('sms.authorization', requestOptions,
          new MyHeaders().contentType('json').end
          , 1.1)
    }

    loginByPassword = (cardNumber, password) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'card_number': cardNumber, 'password': this.hash(password) }),
          redirect: 'follow'
       }

       return this.request('authorization', requestOptions, new MyHeaders().contentType('json').end)
    }

    getPersonalInfo = async token => {
       const requestOptions = {
          type: 'SET_PROFILE_INFO',
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request('profile.info', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .platform()
             .authBearer(token)
             .end
       )

       return this.transformPersonalInfo(res)
    }

    getCardInfo = (token) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       return this.request(
          'card.info',
          requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .end,
          1.1
       )
    }

    infoSave = ({ token, uuid, userName, userSurname, email, birthday, phone, newPhone, gender, socialGroup }) => {
       const body = {
          uuid,
          user_name: userName,
          user_surname: userSurname,
          email,
          birthday,
          phone,
          gender,
          social_group: socialGroup
       }

       newPhone && (body.phone_new = newPhone)

       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       return this.request('info.save', requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .contentType('json')
             .end
          , 1.1)
    }

    pincodeUpdate = (token, pincode) => {
       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({ 'card_pincode': pincode }),
          redirect: 'follow'
       }

       return this.request('pincode.update', requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .contentType('json')
             .end
          , 1.1)
    }

    checksList = (token, cardNumber) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       return this.request(`checks.list/${cardNumber}?amount=50&offset=0`, requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .end
       )
    }

    checkInfo = (token, uuid) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       return this.request(`check.info/${uuid}?amount=50`, requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .authBearer(token)
             .platform()
             .end
       )
    }

    ordersList = async (token, cardNumber, amount = 10, offset = 0) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(`orders.list/${cardNumber}?amount=${amount}&offset=${offset}`, requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .end
       )

       return this.transformOrdersList(res)
    }

    orderInfo = async (token, orderId) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(`order.info/${orderId}`, requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .platform()
             .authBearer(token)
             .end
          , 1.1)

       const goodsData = res.goods.map(this.transformOrderInfo)
       const { shipping_code, shipping_address, shipping_city, payment_code } = res

       return {
          goods: goodsData,
          shippingCode: shipping_code,
          shippingAddress: shipping_address,
          shippingCity: shipping_city,
          paymentCode: payment_code,
       }
    }

   orderStatusesHistory = async (token, orderId) => {
      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request(`order.status.history/${orderId}`, requestOptions,
         new MyHeaders()
            .userLanguage('ua')
            .platform()
            .authBearer(token)
            .end
         , 1.1)

      return this.transformOrderStatusesHistory(res)
   }

    userCreate = (name, surname, birthday, email, phone, password) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
             user_name: name,
             user_surname: surname,
             phone: phone,
             email: email,
             birthday: birthday,
             password: this.hash(password)
          }),
          redirect: 'follow'
       }

       return this.request('user.create', requestOptions,
          new MyHeaders().contentType('json').end
          , 1.1)
    }

    passwordUpdate = (token, uuid, password, oldPassword, isNullable = false) => {
       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({
             'uuid': uuid,
             'password': this.hash(password),
             'password_old': this.hash(oldPassword),
             'password_is_nullable': isNullable
          }),
          redirect: 'follow'
       }

       return this.request('password.update', requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .contentType('json')
             .end
       )
    }

    getFAQ = async () => {
       const res = await fetch('https://tada.ua/api/faq')

       if (!res.ok) {
          // throw new Error('Code: ' + res.status + ', text: ' + res.statusText)
          throw await res.json()
       }

       return await res.json()
    }

    getDelivery = async () => {
       const res = await fetch('https://tada.ua/api/delivery')

       if (!res.ok) {
          // throw new Error('Code: ' + res.status + ', text: ' + res.statusText)
          throw await res.json()
       }

       return await res.json()
    }

    codeGenerate = (type, value) => {
       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({ type, value }),
          redirect: 'follow'
       }

       return this.request('code.generate', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
       )
    }

    codeVerification = (type, uuid, code) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
             'type': type,
             'uuid': uuid,
             'code': code
          }),
          redirect: 'follow'
       }

       return this.request('code.verification', requestOptions,
          new MyHeaders().contentType('json').end
          , 1.1)
    }

    passwordCreate = (code, password) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'code': code, 'password': this.hash(password) }),
          redirect: 'follow'
       }

       return this.request('password.create', requestOptions, new MyHeaders().contentType('json').end)
    }
    
    buyCertificate = (body, token) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       return this.request('certificate.buy', requestOptions, new MyHeaders()
          .userLanguage('ua')
          .platform()
          .authBearer(token)
          .end)
    }

    shopsList = async () => {
       const now = Date.now()

       if (shopsCache.expiry > now) {
          return shopsCache.data
       }
  
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }
  
       const res = await this.request('shops.list', requestOptions, new MyHeaders().userLanguage('ua').end)
  
       const transformedData = this.transformShopsList(res)
  
       shopsCache.data = transformedData
       shopsCache.expiry = Date.now() + 180000 // 3 mins
  
       return transformedData
    }

   marketsCitiesList = async () => {
      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request('shops.list', requestOptions, new MyHeaders().userLanguage('ua').end)

      return this.transformCitiesList(res)
   }

   getNPCitiesList = async () => {
      const now = Date.now()

      if (npcitiesCache.expiry > now) {
         return npcitiesCache.data
      }

      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request('delivery.list/novaposhta', requestOptions, new MyHeaders().userLanguage('ua').end, 1.1)

      const transformedData = this.transformNPCitiesList(res)

      npcitiesCache.data = transformedData
      npcitiesCache.expiry = Date.now() + 3600000 // 1 hour

      return transformedData
   }

   transformNPCitiesList = res => res.map(el => (
      {
         id: el.id,
         value: el.name.replace(/\([^)]*\)/gm, '').split(',')[0],
         label: el.name,
      }
   )
   )

   getNPWarehouseList = async (id) => {
      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request(`delivery.list/novaposhta/${id}`, requestOptions, new MyHeaders().userLanguage('ua').end, 1.1)

      return this.transformNPWarehouses(res)
   }

   transformNPWarehouses = res => res.map(el => (
      {
         id: el.id,
         value: el.name,
         label: el.name
      }
   )
   )

   marketsList = async () => {
      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request('shops.list', requestOptions, new MyHeaders().userLanguage('ua').end)
      return this.transformMarketsList(res)
   }

    specialsList = async (shopId = 8, limit = 15, version = 1.1) => {

       const body = {
          shop_id: shopId,
          sort: {
             direction: 'ASC',
             limit,
             offset: 0,
             order: 'none'
          },
          filter_attributes: []
       }

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       const { result_goods } = await this.request('specials.list', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('text')
             .end
          , version)

       return result_goods.map(this.transformSpecialsList)
    }

    seasonalGoods = async (shopId = 8, limit = 15, offset = 0) => {
       const goods = await this.request(
          'specials.list/' + shopId,
          {
             method: 'GET'
          },
          { limit, offset },
          1.3
       )

       return goods.map(this.transformSpecialsList)
    }

    specialsListSearch = async data => {
       const {
          shopId = 8,
          filterInStock = 0,
          filterPrice = null,
          sort = { direction, limit, offset, order },
          filterAttributes = null,
          options = {},
          append_all_categories_in_response = true,
          filter_categories = []
       } = data

       const { direction = 'ASC', limit = 24, offset = 0, order = 'name' } = sort

       const body = {
          'shop_id': shopId,
          'sort': {
             'direction': direction,
             'limit': limit,
             'offset': offset,
             'order': order
          },
          'filter_attributes': filterAttributes ? filterAttributes.map(({
             attributesTitle,
             attributes
          }) => ({ attributes_title: attributesTitle, attributes })) : [],
          append_all_categories_in_response,
          filter_categories
       }

       filterInStock && (body['filter_in_stock'] = filterInStock)
       filterPrice && (filterPrice.start || filterPrice.end) && (body['filter_price'] = filterPrice)

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       const res = await this.request('specials.list', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('text')
             .end
          , 1.1)

       return this.transformSearch(res, options)
    }

    structuresCategoriesList = async (shopId = 8, categoryId = 0) => {
       if (categoryId === 0) {
          const now = Date.now()
          if (categoriesCache[shopId] && categoriesCache[shopId].expiry > now) {
             return categoriesCache[shopId].data
          }
       }
     
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const categories = await this.request(
          `structured.categories.list/${shopId}/${categoryId}?list_as_array=1`,
          requestOptions,
          {},
          1.2
       )

       const transformedData = categories.map(this.transformStructuresCategories)

       if (categoryId === 0) {
          categoriesCache[shopId] = {
             data: transformedData,
             expiry: Date.now() + 600000 // 10 mins
          }
       }

       return transformedData

    }

    goodsInCategory = async (categoryId, shopId, {
       direction = 'ASC',
       limit = 30,
       offset = 0,
       order = 'name'
    }, filterAttributes = [], filterInStock = 1) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
             'category_id': categoryId,
             'shop_id': shopId,
             'filter_in_stock': filterInStock,
             'sort': {
                'direction': direction,
                'limit': limit,
                'offset': offset,
                'order': order
             },
             'filter_attributes': filterAttributes.map(({
                attributesTitle,
                attributes
             }) => ({ attributes_title: attributesTitle, attributes })),
             'filter_price': {
                'start': 0,
                'end': 100000
             }
          }),
          redirect: 'follow'
       }

       const res = await this.request('goods.in.category', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
       )

       return this.transformGoodsInCategory(res)
    }

    attributesList = async (category) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(`attributes.list?category=${category}`, requestOptions,
          new MyHeaders().userLanguage('ua').end
       )

       return this.transformAttributesList(res)
    }

    findGoodsInSearch = async (searchValue, shopId = 8) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 'search_string': searchValue, 'shop_id': shopId }),
          redirect: 'follow'
       }

       const res = await this.request('find.goods.in.search', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
          , 1.1)

       return this.transformFindGoodsInSearch(res)
    }

    getProductByCode = async (productCode, shopId = 8, token) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
             'shop_id': shopId,
             'good_code': productCode,
          }),
          redirect: 'follow'
       }

       const res = await this.request('find.gcode', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .authBearer(token)
             .end
          , 1.2)

       return this.transformGetProductByCode(res)
    }

    home = async () => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request('home', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .end
       )

       return this.transformHome(res)
    }

    /**
     * @typedef {('bundle'|'category'|'good'|'pack'|'news'|'link')} Types
     *
     * @typedef { object } Sources
     * @property { string } wide - wide banners
     * @property { string } normal
     *
     * @typedef Banner
     * @property { number } id
     * @property { Sources } sources
     * @property { Types } type
     * @property { (string|number) } hrefValue
     *
     * @returns { Banner[] } - banners list
     * */
    bannersList = async () => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request('banners.list', requestOptions, {}, 1.1)

       return res.map(this.transformBannersList)
    }

   settingsList = async () => {
      const requestOptions = {
         method: 'GET',
      }

      return await this.request('settings.list', requestOptions,
         new MyHeaders()
            .userLanguage('ua')
            .contentType('json')
            .end,
         1.1
      )
   }

    bundlesInfo = async (uuid, shopId = 8) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          `bundles.info?uuid=${uuid}&shop_id=${shopId}`,
          requestOptions,
          new MyHeaders().userLanguage('ua').end
       )

       return this.transformBundlesInfo(res)
    }

    selectedShopSave = (token, shopId = 8, warehouse, isNP) => {
       let bodyObj = { shop_id: shopId }

       if(isNP) bodyObj = { warehouse_id: warehouse }

       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify(bodyObj),
          redirect: 'follow'
       }

       return this.request('selected.shop.save', requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .contentType('json')
             .end,
          1.1)
    }

    shoppingList = async (shopId, shoppingList) => {
       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
             shop_id: shopId,
             id_list: Object.keys(shoppingList)
          }),
          redirect: 'follow'
       }

       const res = await this.request('shopping.list', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .contentType('json')
             .end
       )

       return this.transformShoppingList(res)
    }

    goodsSearch = data => {
       return this.#baseGoodsSearch(data, {
          url: 'goods.search',
          version: 1.1,
       })
    }

    goodsWithoutDiscount = (data) => {
       return this.#baseGoodsSearch(data, {
          url: 'goods.search.not.discounted',
          version: 1.1,
       })
    }

   newGoodsList = async ({
      categoryId = null,
      shopId = 1,
      filterInStock = 0,
      filterPrice = null,
      sort = { direction: 'ASC', limit: 24, offset: 0, order: 'none' },
      filterAttributes = null,
      options = { previewSize: '177x177' }
   }) => {
      const { direction, limit, offset, order } = sort

      const body = {
         shop_id: shopId,
         sort: {
            direction,
            limit,
            offset,
            order
         },
         filter_attributes: filterAttributes ? filterAttributes.map(({
            attributesTitle,
            attributes
         }) => ({ attributes_title: attributesTitle, attributes })) : []
      }

      categoryId && (body['category_id'] = categoryId)
      filterInStock && (body['filter_in_stock'] = filterInStock)
      filterPrice && (filterPrice.start || filterPrice.end) && (body['filter_price'] = filterPrice)

      const requestOptions = {
         method: 'POST',
         body: JSON.stringify(body),
         redirect: 'follow'
      }

      const res = await this.request('goods.recent.list', requestOptions,
         new MyHeaders()
            .userLanguage('ua')
            .contentType('json')
            .end
         , 1.2)

      return this.transformSearch(res, options)
   }


   popularList = async ({
      categoryId = null,
      shopId = 1,
      filterInStock = 0,
      filterPrice = null,
      sort = {},
      filterAttributes = null,
      options = { previewSize: '177x177' }
   }) => {
      const { direction = 'ASC', limit = 15, offset = 0, order = 'none' } = sort

      const body = {
         'shop_id': shopId,
         'sort': {
            'direction': direction,
            'limit': limit,
            'offset': offset,
            'order': order
         },
         'filter_attributes': filterAttributes ? filterAttributes.map(({
            attributesTitle,
            attributes
         }) => ({ attributes_title: attributesTitle, attributes })) : []
      }

      categoryId && (body['category_id'] = categoryId)
      filterInStock && (body['filter_in_stock'] = filterInStock)
      filterPrice && (filterPrice.start || filterPrice.end) && (body['filter_price'] = filterPrice)

      const requestOptions = {
         method: 'POST',
         body: JSON.stringify(body),
         redirect: 'follow'
      }

      const res = await this.request('popular.list', requestOptions,
         new MyHeaders()
            .userLanguage('ua')
            .contentType('json')
            .end
         , 1.1)

      return this.transformSearch(res, options)
   }

    purchaseAdd = async (token, goods, params = {}) => {
       const body = {
          'purchase': {
             'goods': Object.keys(goods).map(key => ({ 'good_code': key, 'quantity': goods[key] })),
             ...params
          }
       }

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       const res = await this.request('purchase.add', requestOptions,
          new MyHeaders()
             .userLanguage('ua')
             .authBearer(token)
             .platform()
             .contentType('json')
             .end,
          1.3)

       return this.transformPurchaseAdd(res)
    }

    purchaseCancel = async (token, id) => {

       const requestOptions = {
          method: 'PUT',
          body: JSON.stringify({ 'id': id }),
          redirect: 'follow'
       }

       return await this.request('purchase.cancel', requestOptions,
          new MyHeaders()
             .authBearer(token)
             .platform()
             .contentType('json')
             .end,
          1.1)
    }

    logError = async (error = '123') => {

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ error }),
          redirect: 'follow'
       }

       /**
         * 12 - good code number does not exist in the system
         * */
       if (error?.code !== 12) {
          try {
             sentryException(error)
          // eslint-disable-next-line no-empty
          } catch (err) {}
       }

       try {
          console.error('SERVICE_LOG', error)

          // return await this.request(
          //     `site.log.error`,
          //     requestOptions,
          //     new MyHeaders().contentType('json').end,
          //     1.1
          // )
       } catch (e) {
          console.error('SERVICE_LOGGER_ERROR', e)
       }
    }

    fetchBagList = async shopId => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const packages = await this.request(
          `bag.list/${shopId}`,
          requestOptions,
          {},
          1.1
       )

       return this.transformBagList(packages)
    }

    articlesList = async page => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          `articles.list?page=${page}`,
          requestOptions,
          new MyHeaders().userLanguage('ua').end,
          1.1
       )

       return res.map(this.transformArticlesList)
    }

    journalList = async () => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          'journal.list',
          requestOptions,
          {},
          1.1
       )

       return res.map(this.transformJournalList)
    }

    newsList = async () => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const news = await this.request(
          'news.list',
          requestOptions,
          {},
          1.2
       )

       return this.transformNewsList(news)
    }

    archiveNewsList = async (offset = 0, limit = 10) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          `news.archive?limit=${limit}&offset=${offset}`,
          requestOptions,
          {},
          1.2
       )

       return {
          totalNewsCount: res.total_news,
          news: res.news.map(item => ({
             id: item.id,
             date: item.date,
             title: item.title,
             status: item.status,
             photo: item.photo
          }))
       }
    }

    articleAllInfo = async id => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          `article.all.info/${id}`,
          requestOptions,
          new MyHeaders().contentType('json').end,
          1.1
       )

       return this.transformArticleAllInfo(res)
    }

    sendProductReview = ({
       userName,
       email,
       text,
       goodCode,
       rating,
       phone = null,
       uuid = '317d14ee-b05e-11ea-9c53-ac1f6b70c8cf'
    }) => {

       const body = {
          'user_name': userName,
          'email': email,
          'text': text,
          'uuid': uuid,
          'rating': rating
       }

       phone && (body['phone'] = phone)
       goodCode && (body['good_code'] = goodCode)

       const requestOptions = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       return this.request('review.add', requestOptions, new MyHeaders().contentType('json').end)
    }

    vacanciesList = (workplace = false, limit = 1000) => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow',
       }

       return this.request(
          workplace ? `vacancies.list?limit=${limit}&group_by_city=1&workplace=${workplace}` : `vacancies.list?limit=${limit}&group_by_city=1`,
          requestOptions,
          new MyHeaders()
             .contentType('json')
             .userLanguage('ua')
             .end
          , 1.2)
    }

   positionsList = async () => {
      const requestOptions = {
         method: 'GET',
         headers: {
            'Authorization': `Key ${this.authorizationKey}`
         },
         redirect: 'follow',
      }
      const res = await this.request(
         'vacancies.resume.positions',
         requestOptions,
         new MyHeaders()
            .contentType('json')
            .userLanguage('ua')
            .end
         , 1.1)

      return this.transformPositions(res)
   }


    vacancyById = async id => {
       const requestOptions = {
          method: 'GET',
          redirect: 'follow'
       }

       const res = await this.request(
          `vacancy.info/${id}`,
          requestOptions,
          new MyHeaders()
             .contentType('json')
             .userLanguage('ua')
             .end
          , 1.1)

       return this.transformVacancy(res)
    }

    addVacancyResume = ({
       name,
       surname,
       vacancyId,
       email,
       phone,
       file /* resume base64 file */
    }) => {

       const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: JSON.stringify({
             name,
             surname,
             email,
             phone,
             file,
             vacancy_id: vacancyId
          })
       }

       return this.request(
          'vacancy.resume.add',
          requestOptions,
          new MyHeaders().contentType('json').end,
          1.1
       )
    }

   addVacancyFreeResume = async ({
      name,
      surname,
      email,
      phone,
      comment,
      city,
      position,
      file /* resume base64 file */
   }) => {
      const requestOptions = {
         method: 'POST',
         redirect: 'follow',
         headers: {
            'Authorization': `Key ${this.authorizationKey}`
         },
         body: JSON.stringify({
            name,
            surname,
            email,
            phone,
            comment,
            city,
            position,
            file
         })
      }

      const res = await this.request(
         'vacancy.resume.free_feedback.add',
         requestOptions,
         new MyHeaders()
            .contentType('json')
            .userLanguage('ua')
            .end
         , 1.1)
      return res
   }
      getAboutUs = async () => {
         const requestOptions = {
            method: 'GET',
            redirect: 'follow'
         }

         return await this.request('about.us', requestOptions, new MyHeaders().userLanguage('ua').end, 1.1)
      }

    receiptsList = async (token, cartNumber, offset = 0, amount = 15) => {
       const res = await this.request(
          `checks.list/${cartNumber}?amount=${amount}&offset=${offset}`,
          { method: 'GET' },
          new MyHeaders().authBearer(token).end
       )

       return this.transformReceiptsList(res)
    }

    receiptInfo = async (token, receiptUuid) => {
       const res = await this.request(
          `check.info/${receiptUuid}`,
          { method: 'GET' },
          new MyHeaders()
             .authBearer(token)
             .userLanguage('ua')
             .end,
          1.1
       )

       return this.transformReceiptInfo(res)
    }

    packList = async (limit = 10, offset = 0) => {
       const res = await this.request(
          `pack.list?limit=${limit}&offset=${offset}`,
          { method: 'GET' },
          {},
          1.1
       )

       return this.transformPackList(res)
    }

    packInfo = async (
       {
          id,
          shopId = 8,
          filterInStock = 0,
          filterPrice = null,
          sort = {},
          filterAttributes = null,
          options = { previewSize: '177x177' },
          append_all_categories_in_response = true,
          filter_categories = []
       }
    ) => {

       const { direction = 'ASC', limit = 15, offset = 0, order = 'none' } = sort
       const body = {
          id,
          shop_id: shopId,
          sort: {
             direction: direction,
             limit: limit,
             offset: offset,
             order: order
          },
          filter_attributes: filterAttributes ? filterAttributes.map(({
             attributesTitle,
             attributes
          }) => ({ attributes_title: attributesTitle, attributes })) : [],
          append_all_categories_in_response,
          filter_categories
       }
       // categoryId && (body['category_id'] = categoryId)
       filterInStock && (body['filter_in_stock'] = filterInStock)
       filterPrice && (filterPrice.start || filterPrice.end) && (body['filter_price'] = filterPrice)

       const requestOption = {
          method: 'POST',
          body: JSON.stringify(body),
          redirect: 'follow'
       }

       const res = await this.request(
          'pack.info',
          requestOption,
          {},
          1.1
       )

       return this.transformPackInfo(res, options)
    }

    getCartList = async token => {
       const res = await this.request(
          'cart.list',
          { method: 'GET' },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return this.transformCartList(res)
    }

    removeGoodFromCart = async (goodCode, token) => {
       const res = await this.request(
          'cart.delete',
          {
             method: 'DELETE',
             body: JSON.stringify([goodCode])
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return res
    }

    removeGoodsFromCart = async (goods, token) => {
       const res = await this.request(
          'cart.delete',
          {
             method: 'DELETE',
             body: JSON.stringify(
                goods.map(good => good.goodCode)
             )
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return res
    }

    addGoodsToCart = async (goods, token) => {
       const res = await this.request(
          'cart.add',
          {
             method: 'POST',
             body: JSON.stringify(
                goods.map(good => ({
                   good_code: good.goodCode,
                   quantity: good.quantity
                }))
             )
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return res
    }

    addGoodToCart = async (goodCode, quantity, token) => {
       const res = await this.request(
          'cart.add',
          {
             method: 'POST',
             body: JSON.stringify([
                {
                   good_code: goodCode,
                   quantity
                }
             ])
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return res
    }

    wishlist = async (token, shopId = 8, limit = 15, offset = 0) => {
       const res = await this.request(
          `wish.list?shop_id=${shopId}&limit=${limit}&offset=${offset}`,
          {
             method: 'GET',
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return this.transformResultGoods(
          res,
          '177x177',
          { withWishlistCheckbox: true }
       )
    }

    viewedProducts = async (token, shopId = 8, limit = 15, offset = 0) => {
       const res = await this.request(
          `last.viewed.list?shop_id=${shopId}&limit=${limit}&offset=${offset}`,
          {
             method: 'GET',
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return this.transformResultGoods(
          res,
          '177x177',
       )
    }

    addGoodsToWishlist = async (token, goodsCodes) => {
       const res = await this.request(
          'wish.list.add',
          {
             method: 'POST',
             body: JSON.stringify(goodsCodes)
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )

       return this.transformResultGoods(res)
    }

    addGoodToWishlist = (token, goodCode) => {
       return this.addGoodsToWishlist(token, [goodCode])
    }

    removeGoodsFromWishlist = (token, goodsCodes) => {
       return this.request(
          'wish.list.delete',
          {
             method: 'DELETE',
             body: JSON.stringify(goodsCodes)
          },
          new MyHeaders().authBearer(token).end,
          1.1
       )
    }

    removeGoodFromWishlist = (token, goodCode) => {
       return this.removeGoodsFromWishlist(token, [goodCode])
    }

    getCategoryMetaInfo = async (url) => {
       const res = await fetch(url)
       if (!res.ok) throw await res.json()

       return await res.json()
    }

   getCategoryDescription = async (url) => {
      const res = await fetch(url)
      if (!res.ok) throw await res.json()

      return await res.json()
   }

   sendContactUsForm = async (token, data) => {
      const {
         firstName,
         lastName,
         fatherName,
         phone,
         requestType,
         reason,
         reasonDetails,
         comment,
         shopId,
      } = data

      const headers = new MyHeaders().authBearer(token).end

      const requestOptions = {
         method: 'POST',
         headers,
         body: JSON.stringify({
            firstName,
            lastName,
            fatherName,
            phone,
            requestType,
            reason,
            reasonDetails,
            comment,
            shopId,
         })
      }

      const response = await fetch('/api/send_feedback', requestOptions)

      if (!response.ok) {
         throw await response.json()
      }

      return await response.json()
   }

   getAppealReasons = async () => {
      const response = await fetch('/api/feedback_reasons')

      if (!response.ok) {
         throw await response.json()
      }

      return await response.json()
   }

   transformAppealReasons = response => {
      const result = response.result

      if (result.result !== 'success') {
         throw new Error(`API Provider: getAppealReasons returns not success response, got: "${result.result}"`)
      }

      return result.data
   }

   isSold = (quantity, alternativeQuantity) => !quantity && !alternativeQuantity.length

    transformPromoActions = promoActions => {
       return promoActions.map(action => ({
          id: action.id,
          uuid: action.uuid,
          title: action.title,
          content: action.content,
          image: `${this.#API_BASE}${action.image !== '0' ? action.image : this.#NO_IMAGE_PATH }.webp`,
          dateStart: action.date_start,
          dateEnd: action.date_end,
          isActive: action.active && action.site_visible,
          sortOrder: action.sort_order,
          relatedType: action.related_type,
          relatedValue: action.related_value,
       }))
    }

    transformNewsList = news => {
       const articlesMapper = articles =>
          articles.map(article => ({
             id: article.id,
             status: Boolean(article.status),
             title: article.title,
             date: article.date,
             photo: article.photo
          }))

       const formattedNews = {}

       Object.keys(news).forEach(key => {
          formattedNews[key] = articlesMapper(news[key])
       })

       return formattedNews
    }

    transformCartList = ({ goods, min_total_order }) => ({
       minTotalOrder: min_total_order,
       goods: goods.map(good => ({
          discountPrice: good.discount_price,
          discounted: good.discounted,
          goodCode: good.good_code,
          measurement: good.measurement,
          src: good.photo,
          price: good.price,
          quantity: good.cart_quantity,
          totalQuantity: good.quantity,
          title: good.title,
          annotation: good.annotation
       }))
    })

   transformPackInfo = ( data ) => {

      const {
         goods_count,
         result_count,
         result_goods,
         result_categories,
         min_price,
         max_price,
         attributes_group,
         breadcrumbs,
         image,
         date,
         date_end,
         title,
         ...rest
      } = data

      const previewSize = '177x177'
      const maxCategoriesCount = undefined


      const transformResultCategories = ({ image, name, id, uuid }) => ({
         src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.170x170`,
         name,
         id: uuid,
         category: id,
      })

      const transformAttributesGroup = ({ attributes_title, attributes }) => {
         const transformAttributes = ({ name, goods_count }) => ({
            id: name + Math.random(),
            name,
            goodsCount: goods_count
         })

         return {
            id: attributes_title,
            attributesTitle: attributes_title,
            attributes: attributes.map(transformAttributes)
         }
      }

      return {
         ...rest,
         title,
         src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.690x378`,
         date: date,
         dateEnd: date_end,
         goodsCount: goods_count,
         resultCount: Math.min(result_count, 9600),
         resultGoods: this.transformResultGoods(result_goods, previewSize, {
            withWishlistButton: true
         }),
         resultCategories: (maxCategoriesCount ? result_categories.slice(0, maxCategoriesCount) : result_categories).map(transformResultCategories),
         minPrice: Math.round(min_price),
         maxPrice: Math.round(max_price),
         attributes: attributes_group.map(transformAttributesGroup),
         breadcrumbs: typeof breadcrumbs[0] === 'string' ? null : breadcrumbs.slice(0, breadcrumbs.length - 1).map(this.transformBreadcrumbs),
         pageTitle: breadcrumbs && breadcrumbs[breadcrumbs.length - 1]?.title || null
      }
   }

   transformPackList({ date_end, goods, image, ...rest }) {
      return {
         ...rest,
         src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.690x378`,
         dateEnd: date_end,
         goods: goods.map(good => ({
            ...good,
            goodCode: good.good_code
         }))
      }
   }

    /**
     * 2021-08-13 16:14:28 -> 2021-08-13
     * */
    transformReceiptDate = date => {
       return date.split(' ')[0]
       // return new Date(date).toISOString().split('T')[0].replace(/-/g, '.')
    }

    transformReceiptsList = ({ checks, last_date }) => {
       const transformReceipt = ({ uuid, check_number, date, sum, bonus_credit, bonus_send, bonus_debt }) => ({
          uuid,
          receiptNumber: check_number,
          sum,
          date: this.transformReceiptDate(date),
          bonusCredit: bonus_credit,
          bonusSend: bonus_send,
          bonusDebt: bonus_debt
       })

       return checks.map(transformReceipt)
    }

    transformReceiptInfo = ({
       uuid,
       check_number,
       date,
       sum,
       cash_sum,
       bonus_debt,
       bonus_balance,
       bonus_total_amount,
       bonus_credit,
       bonus_send,
       shop,
       goods,
       fiscal_check_link
    }) => {

       const transformGoods = ({ title, price, bonus_debt, bonus_credit, good_code, amount, total_sum, uuid }) => ({
          title,
          price,
          amount,
          uuid,
          totalSum: total_sum,
          bonusDebt: bonus_debt,
          bonusCredit: bonus_credit,
          goodCode: good_code
       })

       return {
          uuid,
          sum,
          shop,
          date: this.transformReceiptDate(date),
          receiptNumber: check_number,
          cashSum: cash_sum,
          bonusDebt: bonus_debt,
          bonusBalance: bonus_balance,
          bonusTotalAmount: bonus_total_amount,
          bonusCredit: bonus_credit,
          bonusSend: bonus_send,
          goods: goods.map(transformGoods),
          fiscal_check_link
       }
    }

    transformArticleAllInfo = ({ title_ua, contents_ua, photo, date }) => ({
       photo,
       date,
       title: title_ua,
       content: contents_ua
    })

    transformShippings = ({ method, company, available_payments, description }) => ({
       method: {
          id: method.id,
          title: method.title,
          code: method.code,
          order: method.order,
          needAddress: method.need_address,
          minTotal: method.min_total
       },
       company,
       availablePayments: available_payments,
       description: {
          methodId: description.method_id,
          companyId: description.company_id,
          methodTitle: description.method_title,
          companyTitle: description.company_title,
       }
    })

    transformPurchaseAdd = ({ purchase: { id, user, last_recipient, shippings, payments, goods, confirmed, reservation_date, link_to_pay } }) => {

       const transformPayments = ({ payment_method_id, code, title, order, disable_bonuses }) => ({
          paymentMethodId: payment_method_id,
          code,
          title,
          order,
          disableBonuses: disable_bonuses,
       })

       const transformGoods = ({
          uuid,
          title,
          measurement,
          good_code,
          photo,
          price,
          discount_price,
          discounted,
          quantity
       }) => ({
          id: uuid,
          title,
          measurement,
          goodCode: good_code,
          src: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp`,
          price: prettyPrice(price),
          discountPrice: prettyPrice(discount_price),
          discounted,
          quantity: +quantity
       })

       return {
          id,
          user,
          lastRecipient: last_recipient,
          shippings: shippings.map(this.transformShippings),
          payments: payments.map(transformPayments),
          goods: goods.map(transformGoods),
          confirmed,
          reservationDate: reservation_date,
          linkToPay: link_to_pay
       }
    }

    transformUserAuth = ({ success, user = {} }) => ({
       success,
       user: {
          phone: user.phone,
          cardNumber: user.card_number
       }
    })

    transformCallAuthorization = ({ status_code, card_number }) => ({
       statusCode: status_code,
       cardNumber: card_number
    })

    transformGoodsGroups = groups => {
       if (!groups.length) return []

       const transformGroupedGoods = groupedGoods => groupedGoods.map(good => ({
          alternativeQuantity: this.transformAlternativeQuantity(good.alternative_quantity),
          value: good.attribute_value,
          productCode: good.good_code,
          groupedAttributeValueId: good.grouped_attribute_value_id,
          quantity: good.quantity,
          title: good.title,
          uuid: good.uuid,
          additionalType: good.additional_type,
          additionalValue: good.additional_value
       }))

       return groups.map(group => ({
          // groupedActive: group.grouped_active,
          groupedAttributeParentUuid: group.grouped_attribute_parent_uuid,
          variantBlockTitle: group.attribute_title,
          variantsBlockItems: transformGroupedGoods(group.grouped_goods)
       }))
    }

    transformResultGoods = (goods, previewSize, additionalProps) => {
       return goods.map(({
          alternative_quantity,
          discounted,
          ignore_minimum,
          minimum,
          reviews,
          rating,
          measurement,
          good_code,
          discount_price,
          price,
          photo,
          title,
          uuid,
          quantity,
          groups,
          price_diff_percent
       }) => ({
          discounted,
          measurement,
          goodCode: good_code,
          discountPrice: prettyPrice(discount_price),
          price: prettyPrice(price),
          ignoreMinimum: ignore_minimum,
          minimum,
          reviews: reviews || [],
          alternativeQuantity: this.transformAlternativeQuantity(alternative_quantity),
          rating,
          src: this.parseGoodsImage(photo) + '.webp.' + previewSize,
          title,
          id: uuid,
          quantity,
          isSold: !quantity && !alternative_quantity.length,
          groups: this.transformGoodsGroups(groups),
          discountPercent: price_diff_percent,

          // Custom props
          ...additionalProps
       }))
    }

    transformSearch = ({
       goods_count,
       result_count,
       result_goods,
       result_categories,
       min_price,
       max_price,
       attributes_group,
       breadcrumbs,
       ...rest
    },
    {
       previewSize = '69x69',
       maxCategoriesCount
    }) => {

       const transformResultCategories = ({ image, name, id, uuid }) => ({
          src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.170x170`,
          name,
          id: uuid,
          category: id,
       })

       const transformAttributesGroup = ({ attributes_title, attributes }) => {
          const transformAttributes = ({ name, goods_count }) => ({
             id: name + Math.random(),
             name,
             goodsCount: goods_count
          })

          return {
             id: attributes_title,
             attributesTitle: attributes_title,
             attributes: attributes.map(transformAttributes)
          }
       }

       return {
          ...rest,
          goodsCount: goods_count,
          resultCount: Math.min(result_count, 9600),
          resultGoods: this.transformResultGoods(result_goods, previewSize, {
             withWishlistButton: true
          }),
          resultCategories: (maxCategoriesCount ? result_categories.slice(0, maxCategoriesCount) : result_categories).map(transformResultCategories),
          minPrice: Math.round(min_price),
          maxPrice: Math.round(max_price),
          attributes: attributes_group.map(transformAttributesGroup),
          breadcrumbs: typeof breadcrumbs[0] === 'string' ? null : breadcrumbs.slice(0, breadcrumbs.length - 1).map(this.transformBreadcrumbs),
          pageTitle: breadcrumbs && breadcrumbs[breadcrumbs.length - 1]?.title || null,
       }
    }

    transformShoppingList = ({ min_total_order, goods, shippings }) => ({
       minTotalOrder: min_total_order,
       shippings: shippings.map(this.transformShippings),
       goods: goods.map(this.transformShoppingGood)
    })

    transformShoppingGood = ({
       uuid,
       good_code,
       title,
       photo,
       price,
       ignore_minimum,
       minimum,
       measurement,
       discount_price,
       discounted,
       quantity,
       cart_quantity,
       annotation,
       codes = {}
    }) => ({
       id: uuid,
       goodCode: good_code,
       title,
       src: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp.110x99`,
       price: (discount_price && discount_price < price) ? discount_price : price,
       measurement,
       discountPrice: discount_price,
       discounted,
       totalQuantity: quantity,
       annotation,
       codes,
       ignoreMinimum: ignore_minimum,
       minimum,
    })

    transformJournalList = ({ id, title, contents, photo, date, status }) => ({
       id,
       title,
       src: photo,
       date,
       status,
       contents
    })

    transformArticlesList = ({ id, name_ua, dropdown }) => {

       const transformDropdowns = ({ id, title, content, date, photo }) => ({
          id,
          title,
          content,
          date,
          src: photo,
       })

       return {
          id,
          title: name_ua,
          dropdowns: dropdown.map(transformDropdowns)
       }
    }

    transformPersonalInfo = ({
       user_name,
       user_surname,
       email,
       phone,
       gender,
       provider,
       birthday,
       card_pincode,
       enable_notifications,
       password_is_nullable,
       shop = {},
       social_group,
       delivery
    }) => ({
       userName: user_name,
       userSurname: user_surname,
       email,
       phone,
       birthday,
       gender,
       isProvider: Boolean(provider),
       cardPincode: card_pincode,
       enableNotifications: enable_notifications,
       passwordIsNullable: password_is_nullable,
       shop: {
          ...shop,
          cityUuid: shop?.city_uuid,
          scheduleWeekday: shop?.schedule_weekday,
          scheduleDayoff: shop?.schedule_dayoff,
          googleLink: shop?.google_link,
          shopId: shop?.shop_id,
       },
       socialGroup: social_group,
       delivery
    })

   getSocialGroups = async () => {
      const requestOptions = {
         method: 'GET',
         redirect: 'follow'
      }

      const res = await this.request('strict.fields', requestOptions,
         new MyHeaders()
            .userLanguage('ua')
            .platform()
            .end
      )

      return (res)
   }



    transformOrdersList = ({ orders }) => orders.map(({ uuid, date, revoke, sum, order_number, status }) => {
       const OrderStatus = {
          0: 'pending',
          1: 'in_handling',
          2: 'payment_waiting',
          3: 'delivery_waiting',
          4: 'completed',
          5: 'canceled',
       }

       return {
          id: order_number,
          uuid,
          price: prettyPrice(sum),
          status: OrderStatus[status],
          orderNum: +order_number.replace(/\D/g, ''),
          revoke,
          date: date && date.slice(0, date.indexOf(' '))
       }
    })

    transformOrderInfo = ({
       uuid,
       good_code,
       title,
       amount,
       price,
       total_sum,
       photo,
    }) => ({
       id: uuid,
       code: good_code,
       title,
       count: amount,
       cost: prettyPrice(price),
       price: prettyPrice(total_sum),
       src: photo + '.webp.77x77'
    })

    transformShopsList = res => {
       const cities = res.filter(market => market.status).filter((item, idx, arr) => {
          const doubleIdx = arr.findIndex(({ city }) => item.city === city)
          return idx === doubleIdx
       }).sort((a, b) => {
          if (a.city > b.city) {
             return 1
          }
          if (a.city < b.city) {
             return -1
          }
          return 0
       })

       const markets = cities.map(({ city }) => {
          const addressesList = res.filter(item => item.city === city).filter(market => market.status)

          const active = !(addressesList.length === 1 && !addressesList[0].active)

          const labelSrc = (selling_coffee, pudo) => {
             if (selling_coffee && pudo) {
                return './images/common/np_coffee.svg'
             } else if (pudo) {
                return './images/common/icon_NP.svg'
             } else if (selling_coffee) {
                return './images/common/coffee.svg'
             } else {
                return '/images/common/market2.svg'
             }}

          return {
             value: city + (active ? '' : ' (незабаром відкриття)'),
             label: city + (active ? '' : ' (незабаром відкриття)'),
             active,
             items: addressesList.map(({
                address,
                shop_id,
                latitude,
                longitude,
                photo,
                schedule_weekday,
                bus_stop,
                district,
                active,
                selling_coffee,
                pudo
             }) => ({
                value: address + (active ? '' : ' (незабаром відкриття)'),
                label: address + (active ? '' : ' (незабаром відкриття)'),
                shopId: shop_id,
                latitude: +latitude,
                longitude: +longitude,
                busStop: bus_stop,
                district,
                src: `${photo || `${this.#API_BASE}/uploads/app/no_image.png`}.webp.340x182`,
                labelSrc: labelSrc(selling_coffee, pudo),
                scheduleWeekday: schedule_weekday,
                active,
                haveCoffee: selling_coffee,
                pudo
             }))
          }
       })

       return markets[0].value ? markets : markets.slice(1)
    }

   transformCitiesList = res => {
      const temp = res.filter((el) => !el.online).map((el) => ({
         label: el.city,
         value: el.city_uuid
      }))

      return temp.filter((obj, index, self) =>
         index === self.findIndex((el) => el.label === obj.label))
   }

    transformMarketsList = res => {
       const regions = res.filter(market => market.status).filter((item, idx, arr) => {
          const doubleIdx = arr.findIndex(({ region }) => item.region === region)
          return idx === doubleIdx
       }).sort((a, b) => {
          if (a.region > b.region) {
             return 1
          }
          if (a.region < b.region) {
             return -1
          }
          return 0
       })

       const markets = regions.map(({ uuid, region }) => ({
          id: uuid,
          value: region,
          label: region,
          items: res.filter(market => market.status).filter(item => item.region === region).sort((a, b) => {
             if (a.city > b.city) {
                return 1
             }
             if (a.city < b.city) {
                return -1
             }
             if (a.address > b.address) {
                return 1
             }
             if (a.address < b.address) {
                return -1
             }

             return 0
          }).map(({
             city,
             address,
             shop_id,
             latitude,
             longitude,
             photo,
             phone,
             schedule_weekday,
             schedule_dayoff,
             bus_stop,
             district,
             active,
             selling_coffee,
             pudo,
             ...rest
          }) => {
             const labelSrc = (coffee, pudo) => {
                if (coffee && pudo) {
                   return './images/common/np_coffee.svg'
                } else if (pudo) {
                   return './images/common/icon_NP.svg'
                } else if (coffee) {
                   return './images/common/coffee.svg'
                } else {
                   return './images/common/market2.svg'
                }}
             return {
                id: `${city}, ${address}`,
                value: `${city}, ${address}`,
                label: `${city}, ${address}`,
                city,
                address,
                busStop: bus_stop,
                district,
                shopId: shop_id,
                latitude: +latitude,
                longitude: +longitude,
                labelSrc: labelSrc(selling_coffee, pudo),
                marketImage: `${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp`,
                phone,
                active,
                scheduleWeekday: schedule_weekday,
                scheduleDayoff: schedule_dayoff,
                haveCoffee: selling_coffee,
                pudo,
                ...rest
             }})
       }))

       return markets[0].value ? markets : markets.slice(1)
    }

   transformNewGoodsList = ( {
      uuid,
      good_code,
      photo,
      title_ua,
      price_value,
      rating = 0,
      reviews = [],
      ignore_minimum = 0,
      minimum = 1,
      discount_price = price_value,
      quantity = 1,
      alternative_quantity = [],
      groups = [],
      price_diff_percent = 0,
      breadcrumbs= null
   }) => ( {
      id: uuid || good_code,
      goodCode: good_code,
      title: title_ua,
      src: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp.177x177`,
      price: price_value,
      rating,
      reviews,
      discountPrice: discount_price,
      quantity,
      alternativeQuantity: alternative_quantity,
      // alternativeQuantity: this.transformAlternativeQuantity(alternative_quantity),
      ignoreMinimum: ignore_minimum,
      minimum,
      testPhoto: photo,
      groups,
      // groups: this.transformGoodsGroups(groups),
      discountPercent: price_diff_percent,
      categoryId: breadcrumbs ? breadcrumbs[breadcrumbs.length - 2].category_id : null,
      categoryTitle: breadcrumbs ? breadcrumbs[breadcrumbs.length - 2].title : null,

      // Custom props
      withWishlistButton: true
   }
   )

    transformSpecialsList = ({
       good_code,
       title,
       rating,
       reviews,
       photo,
       ignore_minimum,
       minimum,
       discount_price,
       price,
       quantity,
       alternative_quantity,
       groups,
       uuid,
       price_diff_percent,
       breadcrumbs
    }) => ({
       id: uuid || good_code,
       goodCode: good_code,
       title,
       src: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp.177x177`,
       price,
       rating,
       reviews,
       discountPrice: discount_price,
       quantity,
       alternativeQuantity: this.transformAlternativeQuantity(alternative_quantity),
       ignoreMinimum: ignore_minimum,
       minimum,
       testPhoto: photo,
       groups: this.transformGoodsGroups(groups),
       discountPercent: price_diff_percent,
       categoryId: breadcrumbs ? breadcrumbs[breadcrumbs.length - 2].category_id : null,
       categoryTitle: breadcrumbs ? breadcrumbs[breadcrumbs.length - 2].title : null,

       // Custom props
       withWishlistButton: true
    })

    transformStructuresCategories = ({ id, uuid, name, image, sort_order, list }) => {
       const transformSubitems = ({ id, uuid, name, list }, idx) => ({
          id: uuid,
          title: name,
          href: `/products/category/${id}`,
          subitems: idx > 5 ? [] : Object.values(list || []).map(({ id, uuid, name }) => ({
             id: uuid,
             title: name,
             href: `/products/category/${id}`,
          })).slice(0, 4)
       })

       return {
          id: uuid,
          title: name,
          src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }`,
          sortOrder: sort_order,
          href: `/products/category/${id}`,
          subitems: list ? list.map(transformSubitems) : [],
       }
    }

    transformGoodsInCategory = ({ result_count, goods_count, goods }) => ({
       resultCount: result_count,
       goodsCount: goods_count,
       goods: goods.map(this.transformGood)
    })

    transformGood = ({
       good_code,
       title,
       photo,
       price,
       measurement,
       discount_price,
       discounted, quantity,
       price_diff_percent,
       groups,
       alternative_quantity,
       uuid,
       breadcrumbs
    }) => ({
       id: uuid,
       goodCode: good_code,
       title,
       src: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp.177x177`,
       price,
       measurement,
       discountPrice: discount_price,
       discounted,
       quantity,
       discountPercent: price_diff_percent,
       groups: this.transformGoodsGroups(groups),
       alternativeQuantity: alternative_quantity,
       category: breadcrumbs[0]
    })

    transformAttributesList = ({ min_price, max_price, attributes_group }) => ({
       minPrice: Math.floor(prettyPrice(min_price)),
       maxPrice: Math.ceil(prettyPrice(max_price)),
       attributesGroup: attributes_group.map(({ attributes_title, attributes }) => ({
          id: attributes_title,
          attributesTitle: attributes_title,
          items: attributes.map(({ name, goods_count }) => ({
             id: `${name}-${Math.random()}`,
             name,
             goodsCount: goods_count,
             checked: false
          }))
       }))
    })

    transformFindGoodsInSearch = ({ search_count, search_result_goods }) => {
       const transformResultGoods = ({
          uuid,
          good_code,
          title,
          photo,
          price,
          measurement,
          discount_price,
          discounted,
          quantity,
          alternative_quantity,
          groups
       }) => ({
          id: uuid,
          goodCode: good_code,
          title,
          photo: `${this.#API_BASE}${photo !== '0' ? photo : this.#NO_IMAGE_PATH }.webp.69x69`,
          price,
          measurement,
          alternativeQuantity: this.transformAlternativeQuantity(alternative_quantity),
          discountPrice: discount_price,
          discounted,
          quantity,
          groups
       })

       return {
          searchCount: search_count,
          searchResultGoods: search_result_goods.slice(0, 4).map(transformResultGoods)
       }
    }

    transformBreadcrumbs = ({ category_id, title }) => ({ id: category_id, categoryId: category_id, title })

    transformReviews = ({ data_create, rating, good_code, user_name, text }) => ({
       id: data_create,
       dataCreate: data_create,
       rating,
       goodCode: good_code,
       userName: user_name,
       text
    })

    transformAlternativeQuantity = altQty => {
       if (!altQty || altQty.length === 0) return []

       return altQty.map(item => ({
          address: item.hasOwnProperty('address') ? item.address : item.address_ua,
          quantity: item.quantity,
          shopId: item.shop_id
       }))
    }

    transformBagList = packages => {
       return packages.map(p => ({
          id: p.uuid,
          discounted: p.discounted,
          measurement: p.measurement,
          goodCode: p.good_code,
          ignoreMinimum: !!p.ignore_minimum,
          discountPrice: p.discount_price,
          isSold: !p.quantity && !p.alternative_quantity.length,
          title: p.title,
          photo: `${p.photo || `${this.#API_BASE}/uploads/app/no_image.png`}`,
          uuid: p.uuid,
          minimum: p.minimum,
          price: p.price,
          price_diff_percent: p.price_diff_percent,
          quantity: p.quantity,
          alternativeQuantity: p.alternative_quantity
       }))
    }

   reviewsMapper = reviews =>
      reviews.map(review => ({
         dateOfCreation: review.data_create,
         productCode: review.good_code,
         rating: review.rating,
         comment: review.text,
         username: review.user_name
      }));

   variantsMapper = (variants = []) => {
      if (!variants.length) return []

      return variants.map(variant => ({
         groupedAttributeParentUuid: variant.grouped_attribute_parent_uuid,
         variantBlockTitle: variant.attribute_title,
         variantsBlockItems: variant.grouped_goods.map(innerIem => ({
            alternativeQuantity: this.alternativeQuantityMapper(innerIem.alternative_quantity),
            value: innerIem.attribute_value,
            additionalValue: innerIem.additional_value,
            productCode: innerIem.good_code,
            groupedAttributeValueId: innerIem.grouped_attribute_value_id,
            quantity: innerIem.quantity,
            title: innerIem.title,
            uuid: innerIem.uuid
         }))
      }))
   };

   characteristicsMapper = attributes =>
      attributes.map(attribute => ({
         title: attribute.attribute_title,
         titleId: attribute.title_id,
         values: attribute.attribute_values
      }));

   alternativeQuantityMapper = altQty => {
      return altQty.map(item => ({
         address: item.hasOwnProperty('address') ? item.address : item.address_ua,
         quantity: item.quantity,
         shopId: item.shop_id
      }))
   };

   breadcrumbsMapper = breadcrumbs =>
      breadcrumbs.map(breadcrumb => ({
         id: breadcrumb.category_id ?? breadcrumb.good_code,
         categoryId: breadcrumb.category_id || null,
         productCode: breadcrumb.good_code || null,
         title: breadcrumb.title
      }));

    transformGetProductByCode = productData => {
       const sales = { status: 0, dateEnd: '0' }

       if (productData.discount) {
          if (productData.discount.date_end) {
             const [year, month, day] = productData.discount.date_end.split(' ')[0].split('-')
             sales.status = productData.discount.status
             sales.dateEnd = `${day}.${month}.${year}`
          }
       }

       return {
          id: productData.uuid,
          uuid: productData.uuid,
          cost: {
             hasDiscount: productData.discount_price && productData.price !== productData.discount_price,
             price: prettyPrice(productData.price),
             discountPrice: prettyPrice(productData.discount_price),
             isSold: this.isSold(productData.quantity, productData.alternative_quantity)
          },
          title: productData.title,
          productCode: productData.good_code,
          description: productData.description,
          measurement: productData.measurement,
          image: productData.photo,
          extra_image: productData.extra_photos,
          reviews: this.reviewsMapper(productData.reviews),
          ratingNumber: productData.rating,
          codes: productData.codes,
          quantity: productData.quantity,
          minimumOrderQuantity: productData.ignore_minimum ? 1 : productData.minimum,
          alternativeQuantity: this.alternativeQuantityMapper(productData.alternative_quantity),
          variants: this.variantsMapper(productData.groups),
          characteristics: this.characteristicsMapper(productData.attributes),
          breadcrumbs: this.breadcrumbsMapper(productData.breadcrumbs),
          sales: sales,
          price_per_pack: productData.price_per_pack
       }
    }

    transformHome = ({ banners, promo, notifications }) => ({
       banners: banners.map((src, idx) => ({
          id: idx,
          src: `${this.#API_BASE}${src !== '0' ? src : this.#NO_IMAGE_PATH }.webp.1100x602`,
          background: '#fff'
       })),
       promo,
       notifications
    })

    /* new helper */
    transformBannersList = ({ image, wide_image, type, value }, idx) => ({
       id: idx,
       hrefValue: value,
       type,
       sources: {
          wide: `${this.#API_BASE}${wide_image !== '0' ? wide_image : this.#NO_IMAGE_PATH }.webp.1920x510`,
          normal: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.1100x602`,
       },
    })

    transformBundlesInfo = ({ uuid, image, title, date, date_end, goods }) => ({
       uuid,
       src: `${this.#API_BASE}${image !== '0' ? image : this.#NO_IMAGE_PATH }.webp.690x378`,
       title,
       date,
       dateEnd: date_end,
       goods: goods.map(this.transformGood),
    })

   transformVacancy = ({ title, offer, requirements, responsibilities, pay_type, pay, pay_to, pay_comment, terms, contacts, status, breadcrumbs, address }) => {
      let salaryValue = ''

      if (pay_type === SalaryType.Fixed) {
         salaryValue = `${pay} грн.`
      } else if (pay_type === SalaryType.Ranged) {
         salaryValue = `Від ${pay} до ${pay_to} грн.`
      }

      if (pay_type === SalaryType.Hidden && pay_comment) {
         salaryValue = ` ${pay_comment}`
      } else {
         if (pay_comment) {
            salaryValue += ` ${pay_comment}`
         }
      }

      return {
         title,
         offer,
         requirements,
         responsibilities,
         pay,
         terms,
         contacts,
         status,
         salaryValue,
         breadcrumbs,
         address
      }
   }

   transformPositions = ( res ) => {
      return Object.entries(res).map(([key, value]) => {
         return { id: key, value, label: value }
      })
   }


   transformOrderStatusesHistory = (orderStatusHistory) => {
      const OrderStatus = {
         '-1': 'created',
         '0': 'pending',
         '1': 'in_handling',
         '2': 'payment_waiting',
         '3': 'delivery_waiting',
         '4': 'completed',
         '5': 'canceled',
      }

      return orderStatusHistory.map(item => ({
         id: item.id,
         date: item.date,
         status: OrderStatus[String(item.status)],
      }))
   }

   addTaskInODOO = async (token, data) => {
      const headers = new MyHeaders().authBearer(token).end

      const requestOptions = {
         method: 'POST',
         headers,
         body: JSON.stringify(data)
      }
      try {

         const response = await fetch('/api/send_partnership', requestOptions)
         // console.log(response,'response')

         if (!response.ok) {
            throw await response.json()
         }
         return await response.json()
      } catch (er){
         console.log(er)
      }



   }
}
