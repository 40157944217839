import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import Catalog from './components/catalog'
import Search from './components/search'
import Account from './components/account'
import Logo from '@components/common/logo'
import Phone from './components/phone'
import Faq from './components/faq'
import NewsLink from './components/news_link'
import WorkSchedule from './components/work_schedule'
import WishlistIcon from './components/wishlist_icon'
import CartIcon from './components/cart_icon'
import Location from '@components/common/location'
import TadaServiceContext from '@components/context/tada_service_context'
import AdBunnerPlank from '@components/adBannerPlank'

import { changeCategories, setShowSearchHints } from '@redux/actions'
import { ROUTES } from '@static/routes'
import s from './header.module.scss'
import { ErrorBoundary } from '@sentry/nextjs'

const Header = () => {
   const tadaService = useContext(TadaServiceContext)

   const [isSticky, setSticky] = useState(false)

   const ref = useRef(null)

   const stickyHandler = () => {
      if (ref.current) {
         setSticky(ref.current.getBoundingClientRect().top <= 0)
      }
   }

   useEffect(() => {
      window.addEventListener('scroll', stickyHandler)
      window.addEventListener('resize', stickyHandler)

      return () => {
         window.removeEventListener('scroll', () => stickyHandler)
         window.removeEventListener('resize', () => stickyHandler)
      }
   }, [])

   const { showCategories, showSearchHints, showMobileSidebar } = useSelector(state => ({
      showCategories: state.showCategories,
      showSearchHints: state.showSearchHints,
      showMobileSidebar: state.showMobileSidebar
   }))

   const dispatch = useDispatch()
   const router = useRouter()

   const bgBlur = (showCategories || showSearchHints) ? (
      <div
         className={ s.bg_blur }
         onClick={ () => {
            dispatch(changeCategories(false))
            dispatch(setShowSearchHints(false))
         } }
      />
   ) : null

   const highIndexClass = showMobileSidebar ? ` ${s.high_index}` : ''

   const renderCatalog = useCallback(() => {
      return (
         <ErrorBoundary fallback={ null }>
            <Catalog />
         </ErrorBoundary>
      )
   }, [])

   return (<>
      <AdBunnerPlank />
      <header className={ s.header }>
         {bgBlur}
         <div className={ `${isSticky ? ' ' + s.header_sticky_bottom : ''}${highIndexClass}` } ref={ ref }>
            <div className={ s.header_bottom + highIndexClass }>
               <div className="container-xl">
                  <div className={ s.header_toolbar }>
                     <div className={ s.header_top + ' hide_on_mobile' }>
                        <Logo />
                     </div>
                     {renderCatalog()}
                     <Search
                        searchRequest={ tadaService.goodsSearch }
                        searchRoute={ ROUTES.PRODUCTS.SEARCH }
                        hints
                        placeholderDetails={ {
                           placeholders: [
                              'Чашка',
                              'Картина за номерами',
                              'Термос',
                              'Корзина',
                              'Контейнер',
                              'Рукавички',
                              'Відро',
                              'Маска',
                              'Подушка',
                              'Килим',
                              'Чайник',
                              'Швабра',
                              'Рюкзак',
                              'Комод',
                              'Конструктор',
                              'Шкарпетки',
                              'Сумка',
                              'Намет',
                              'Стілець'
                           ],
                           animated: true,
                        } }
                     />
                     <Location className="hide_on_mobile" />
                     <WishlistIcon />
                     <CartIcon />
                     <Account />
                  </div>
               </div>
            </div>
         </div>
      </header>
   </>
   )
}

export default Header
