import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import {
   setAddIndicatorsList,
   setClosestMarketData,
   setShowClosestMarket,
   setUserCoordinates
} from '@redux/actions'

import CategoriesList from './header/components/catalog/categories_list'
import ContactButton from '@components/buttons/contact_button'
import GoodsAddIndicator from '../goods_add_indicator'
import BreadCrumbs from '../common/breadcrumbs'
import ClosestMarket from '../closes_market'
import UpButton from '../buttons/up_button'
import Modal from '../common/modal'
import Footer from './footer'
import Header from './header'
import ErrorBoundary from '../common/error_boundary'

import s from './layout.module.scss'

const Smartbanner = dynamic(() => import('../common/smart_banner'), { ssr: false })

export const siteTitle = 'TA-DA! - Товари для дому. Щодня!'

export default function Layout({ children, breadcrumbs, disablePadding }) {
   const modalData = useSelector(state => state.modalData)
   const showCategories = useSelector(state => state.showCategories)
   const categories = useSelector(state => state.categories)
   const showSearchHints = useSelector(state => state.showSearchHints)
   const marketsList = useSelector(state => state.marketsList)
   const showClosestMarket = useSelector(state => state.showClosestMarket)
   const closestMarketData = useSelector(state => state.closestMarketData)
   const choosenAddress = useSelector(state => state.choosenAddress)
   const showMobileSidebar = useSelector(state => state.showMobileSidebar)

   const dispatch = useDispatch()

   const closestMarket = showClosestMarket ? <ClosestMarket/> : null

   useEffect(() => {
      if (modalData || showCategories || showSearchHints || showMobileSidebar) {
         const originalStyle = window.getComputedStyle(document.body).overflow

         document.body.style.overflow = 'hidden'

         return () => document.body.style.overflow = originalStyle
      }
   }, [modalData, showCategories, showSearchHints, showMobileSidebar])

   useEffect(() => {
      const GEO_PERMISSION_REQUEST_TIME = 1000 * 60 * 2 // 2 minutes

      const navigateTimeout = setTimeout(() => {
         if (marketsList.length && !closestMarketData && !choosenAddress) {
            navigator?.geolocation?.getCurrentPosition(pos => {
               const gradToRad = grad => grad * Math.PI / 180
               const calculateGeoDistance = (a, b, c, d) => {
                  return Math.acos(Math.sin(a) * Math.sin(c) + Math.cos(a) * Math.cos(c) * Math.cos(b - d)) * 6371
               }

               const userLatitude = gradToRad(pos.coords.latitude)
               const userLongitude = gradToRad(pos.coords.longitude)

               const firstMarket = marketsList[0].items[0]

               let closestMarket = {
                  ...firstMarket,
                  dist: calculateGeoDistance(userLatitude, userLongitude, firstMarket.latitude, firstMarket.longitude)
               }

               marketsList.forEach(({ items }) => items.forEach(market => {
                  const { latitude, longitude } = market
                  const dist = calculateGeoDistance(userLatitude, userLongitude, gradToRad(latitude), gradToRad(longitude))
                  if (dist < closestMarket.dist) {
                     closestMarket = {
                        ...market,
                        dist
                     }
                  }
               }))

               dispatch(setUserCoordinates({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }))
               dispatch(setClosestMarketData(closestMarket))
               dispatch(setShowClosestMarket(true))
            })
         }
      }, GEO_PERMISSION_REQUEST_TIME)

      return () => {
         clearTimeout(navigateTimeout)
         dispatch(setShowClosestMarket(false))
      }

   }, [marketsList])

   useEffect(() => () => dispatch(setAddIndicatorsList([])), [])

   return (
      <div className={ s.layout }>
         <Smartbanner
            storeText={ {
               android: 'Завантажуй мобільний додаток та шопінгуй онлайн у зручний час',
               ios: 'Завантажуй мобільний додаток та шопінгуй онлайн у зручний час'
            } }
            button="Відкрити"
            position="top"
            url={ {
               android: 'https://l.ta-da.ua/app_from_site',
               ios: 'https://l.ta-da.ua/ios_site'
            } }
         />

         <ErrorBoundary fallback={ null }>
            <Header/>
         </ErrorBoundary>

         <main style={ { paddingTop: breadcrumbs || disablePadding ? 0 : 15 } }>
            <ErrorBoundary>
               { breadcrumbs ? <div className="container-xl"><BreadCrumbs breadcrumbs={ breadcrumbs }/></div> : null }
            </ErrorBoundary>

            <ErrorBoundary>
               { Boolean(modalData) && (
                  <Modal
                     title={ modalData.title }
                     inner={ modalData.inner }
                     top={ modalData.top }
                     extra={ modalData.extra }
                     styles={ modalData.styles }
                     required={ modalData.required }
                  />
               ) }
            </ErrorBoundary>

            <ErrorBoundary>
               { closestMarket }
            </ErrorBoundary>

            <UpButton/>
            <ContactButton/>

            <GoodsAddIndicator/>

            <div className={ s.categories_list } style={ { transform: `translateX(${showCategories ? 0 : '-300%'})` } }>
               <ErrorBoundary>
                  <CategoriesList categories={ categories }/>
               </ErrorBoundary>
            </div>

            { children }
         </main>

         <ErrorBoundary>
            <Footer/>
         </ErrorBoundary>
      </div>
   )
}
