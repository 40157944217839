import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { parseCookies } from 'nookies'

import s from './form.module.scss'
import BaseSelect from '../base_select'
import { parseJSON } from '@utils'

const SelectMarketForm = ({ city, address, setCity, setAddress, changeCity, changeAddress, isSearchable = true }) => {
   const { marketsList } = useSelector(({ marketsList }) => ({ marketsList }))
   /* const cookies = parseCookies()
      useEffect(() => {
         const choosenCity = parseJSON(cookies.city)
         const choosenAddress = parseJSON(cookies.address)

         choosenCity && setCity(choosenCity)
         choosenAddress && setAddress(choosenAddress)
      }, []) */

   const customLabel = (option) => {
      return (<div className={ s.label }>
         { option.labelSrc && <img src={ option.labelSrc } alt="" /> }
         <span>{ option.label }</span>
      </div>)
   }

   return (
      <div className={ s.form }>
         <div className={ s.form_column } style={ { zIndex: 1002 } }>
            <BaseSelect
               options={ marketsList }
               isOptionDisabled={ option => !option.active }
               placeholder="Місто..."
               value={ city }
               onChange={ changeCity }
               isSearchable={ isSearchable }
            />
         </div>
         <div className={ s.form_column } style={ { zIndex: 1001 } }>
            <BaseSelect
               options={ city?.items || marketsList[0].items }
               placeholder="Супермаркет..."
               value={ address }
               onChange={ changeAddress }
               isOptionDisabled={ option => !option.active }
               isDisabled={ !city || !address }
               isSearchable={ isSearchable }
               getOptionLabel={ option => option.labelSrc ? customLabel(option) : option.label }
               getOptionValue={ option => option.value }
            />
         </div>
      </div>
   )
}

export default SelectMarketForm
