import s from './success_auth_content.module.scss'

const SuccessAuthContent = ({ accountDataText = '' }) => (
   <div className={ s.wrapper }>
      <p>Гоу скуплятися!</p>
      <span>В TA-DA! ти знайдеш все, що треба!</span>
      <p style={ { maxWidth: '270px', wordBreak: 'break-word' } }>
         <b>{ accountDataText }</b>
      </p>
   </div>
)

export default SuccessAuthContent