import dynamic from 'next/dynamic'

const Select = dynamic(
   () => import('react-select').then((mod) => mod.default),
   {
      ssr: false,
      loading: () => null,
   },
)

BaseSelect.defaultProps = {
   customStyles: {
      singleValue: {},
      control: {},
      dropdownIndicator: {}
   }
}

export default function BaseSelect({
   options,
   value,
   onChange,
   isSearchable = true,
   isOptionDisabled,
   isOptionSelected,
   isDisabled = false,
   placeholder,
   customStyles,
   hideDropdown = false,
   hideSeparator = false,
   getOptionValue,
   getOptionLabel,
   filterOption
}) {

   return (
      <Select
         theme={ theme => ({
            ...theme,
            colors: {
               ...theme.colors,
               primary25: '#ffd400',
               primary50: '#ffcf28',
               primary: '#ccc'
            }
         }) }
         styles={ {
            menu: style => ({
               ...style,
               zIndex: 1000
            }),
            option: (styles, { isDisabled, isSelected }) => ({
               ...styles,
               color: isDisabled ? '#9c9c9c' : '#000',
               backgroundColor: isDisabled ? '#e2e2e2' : isSelected ? '#ffd40047' : '#fff',
               cursor: isDisabled ? 'not-allowed' : 'pointer',
               ':hover': {
                  backgroundColor: !isDisabled ? '#eee' : '#e2e2e'
               }
            }),
            singleValue: styles => ({
               ...styles,
               ...customStyles.singleValue
            }),
            control: (styles, { isFocused }) => {
               if (!hideDropdown) return {
                  ...styles,
                  cursor: 'pointer',
                  ...customStyles.control
               }

               return {
                  ...styles,
                  boxShadow: isFocused ? 'none' : styles.boxShadow,
                  ':hover': {
                     borderColor: styles.borderColor
                  }
               }
            },
            dropdownIndicator: (styles) => ({
               ...styles,
               ...customStyles.dropdownIndicator,
               // transition: 'transform .2s ease',
               // transform: isFocused ? 'rotate(180deg)' : 'rotate(0)'
            })
         } }

         isMulti={ false }
         isOptionSelected={ isOptionSelected }
         options={ options }
         placeholder={ placeholder }
         value={ value }
         onChange={ onChange }
         isOptionDisabled={ isOptionDisabled }
         isDisabled={ isDisabled }
         noOptionsMessage={ () => 'Нічого не знайдено' }
         isSearchable={ isSearchable }
         getOptionValue={ getOptionValue }
         getOptionLabel={ getOptionLabel }
         filterOption={ filterOption }
         components={
            hideDropdown && { DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }
                || hideSeparator && { IndicatorSeparator: () => null }
         }
      />
   )
}
