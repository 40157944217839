import Image from 'next/image'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { useAccount, useWishlist } from '@functions/hooks'
import s from './wishlist_icon.module.scss'


export default function WishlistIcon() {

   const { getWishlistGoods } = useWishlist()
   const { toAuthorization } = useAccount()

   const router = useRouter()

   const token = useSelector(state => state.token)
   const wishlistGoods = useSelector(state => state.wishlistGoods)

   useEffect(() => {
      if (token) {
         getWishlistGoods()
      }
   }, [token])

   const handleTransitionToWishlistClick = async event => {
      if (!token) {
         return toAuthorization()
      }

      await router.push('/account/wishlist')
   }

   return (
      <div className={ s.link } onClick={ handleTransitionToWishlistClick }>
         <Image
            src="/images/common/heart-outline.svg"
            width={ 25 }
            height={ 20 }
            alt=""
         />

         { Boolean(wishlistGoods.length) && (
            <div className={ s.counter }>{ wishlistGoods.length }</div>
         ) }
      </div>
   )
}
