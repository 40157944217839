import s from '@components/common/items_list/items-list.module.scss'
import Image from 'next/image'
import Link from 'next/link'

const ItemsList = ({ items, ItemLayout, activeId, segmentation = false }) => Array.isArray(items)
   ? items.map(item => (
      segmentation ?
         <div key={ item.id } className={ s.item_card }>
            <div className={ s.wrapper }>
               <Link href={ `/products/category/${item.categoryId}?page=1` } >
                  <a className={ s.category_link }>
                     <span>{item.categoryTitle}</span>
                     <Image width={ 16 } height={ 10 } src="/images/common/arrow_right_long_black.svg" alt="" />
                  </a>
               </Link>
            </div>
            <ItemLayout
               { ...item }
               active={ activeId === item.id }
               product={ item }
            />
         </div>
         : <ItemLayout
            { ...item }
            key={ item.id }
            active={ activeId === item.id }
            product={ item }
         />
   ))
   : null

export default ItemsList
