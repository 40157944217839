/**
 * @example
 * input: 50.9567
 * output: 50.95
 */
export default function prettyPrice(value) {
   let price = +value
   let cents = Math.round((price - Math.trunc(price)) * 100)

   if (cents > 99) {
      const centFirst = Math.trunc(cents / 100)
      price += centFirst
      cents = 100 * ((cents / 100) - centFirst)
   }

   if (cents === 0) {
      cents = '00'
   } else if (cents < 10) {
      cents = `0${cents}`
   }

   return `${Math.trunc(price)}.${cents}`
}
