import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useShoppingList } from '@functions/hooks'
import { clx } from '@utils'
import { prettyPrice } from '@utils'
import s from './package.module.scss'

const Package = () => {
   const packages = useSelector(state => state.packages)

   const { incGoodsCount } = useShoppingList()

   if (!packages) {
      return null
   }

   return (
      <div className={ s.package }>
         <div className={ s.package_add_image }>
            <div className={ s.package_add_text }>Не забудь додати пакет:</div>
         </div>

         <div className={ s.package_add }>
            { packages.map(p =>
               <PackageAddItem
                  key={ p.id }
                  price={ p.price }
                  isSold={ p.isSold }
                  height={ 20 }
                  onClick={ () => incGoodsCount(p.goodCode) }
               />
            ) }
         </div>
      </div>
   )
}

const PackageAddItem = ({ price, height, onClick, isSold }) => (
   <div className={ clx(s.package_add_item, isSold && s.sold) } onClick={ onClick }>
      <div className={ s.package_image } style={ { height } }>
         <img src="/images/cart/package.png" alt="Додати пакет"/>
      </div>
      <div className={ s.package_title }>
         <span className={ s.package_price }>{ prettyPrice(price) } <br/> грн</span>
      </div>
   </div>
)

export default memo(Package)
