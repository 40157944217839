import { useMemo } from 'react'
import { compose, createStore } from 'redux'
import reducer, { initialState } from '../reducers'

let store

const composeEnhancers = (typeof window !== 'undefined') && (process.env.NODE_ENV === 'development')
   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
      : compose
   : compose

function initStore(preloadedState = initialState) {
   return createStore(
      reducer,
      preloadedState,
      composeEnhancers()
   )
}

const initializeStore = (preloadedState) => {
   let _store = store ?? initStore(preloadedState)

   if (preloadedState && store) {
      _store = initStore({
         ...store.getState(),
         ...preloadedState,
      })
      store = undefined
   }

   if (typeof window === 'undefined') return _store
   if (!store) store = _store

   return _store
}

function useStore(initialState) {
   const store = useMemo(() => initializeStore(initialState), [initialState])
   return store
}

export {
   useStore,
   initializeStore,
   store
}
