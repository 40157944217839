/**
 * The util for protect email view
 *
 * @example
 * input: "test_email@gmail.com"
 * output: "te********@*****.com"
 */
export default function protectEmailString(email) {
   if (!email) return ''

   const lastDogIndex = email.lastIndexOf('@')
   const splittedByDogEmail = [email.substring(0, lastDogIndex), email.substring(lastDogIndex + 1)]
   const [firstPart, secondPart] = splittedByDogEmail
   const dotIdx = secondPart.indexOf('.')

   const FIRST_VISIBLE_CHAR_COUNT = firstPart.length >= 3 ? 2 : firstPart.length === 2 ? 2 : 1

   return (`
        ${email.slice(0, FIRST_VISIBLE_CHAR_COUNT)}
        ${firstPart.slice(FIRST_VISIBLE_CHAR_COUNT, firstPart.length).replace(/./g, '*')}
        @${secondPart.slice(0, dotIdx).replace(/./g, '*')}
        ${secondPart.slice(dotIdx, secondPart.length)}
    `).replace(/\s+/g, '')
}
