// import { initializeApp, analytics as firebaseAnalytics, getApps } from 'firebase/app'
// import 'firebase/analytics'
// import { sentryException } from './sentry'

import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics as firebaseAnalytics } from 'firebase/analytics'
import { sentryException } from './sentry'

const firebaseConfig = {
   apiKey: 'AIzaSyDfhP_bL-qQm1SR19eXAYWeb5hhVCFsrrI',
   authDomain: 'tada-react.firebaseapp.com',
   projectId: 'tada-react',
   storageBucket: 'tada-react.appspot.com',
   messagingSenderId: '1073764527982',
   appId: '1:1073764527982:web:8c2e7dcba4aad6eb65db7f',
   measurementId: 'G-SLZH6KCKBC'
}

const analytics = () => {
   try {
      if (!getApps().length) {
         const app = initializeApp(firebaseConfig)
         return firebaseAnalytics(app)
      } else {
         return firebaseAnalytics(getApps()[0])
      }
   } catch(err) {
      console.error(err)
      sentryException(`Firebase: ${err}`)
      return null
   }
}


export { analytics }
