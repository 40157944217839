import { parseCookies } from 'nookies'
import parseJSON from './parse_json'
import setCookie from './cookies/set_cookie'

export default function syncShoppingList(cartList, storeShoppingList) {
   const cookies = parseCookies()
   const savedShoppingList = parseJSON(cookies.cart)
   const shoppingList = { ...storeShoppingList, ...savedShoppingList }

   if (!cartList) return shoppingList

   const synchronizedShoppingList = cartList.goods.reduce((acc, good) => {
      const shoppingGoodQty = shoppingList[good.goodCode]

      if (shoppingGoodQty && shoppingGoodQty > good.quantity) {
         acc[good.goodCode] = shoppingGoodQty
      } else if (shoppingGoodQty && shoppingGoodQty < good.quantity) {
         acc[good.goodCode] = good.quantity
      } else {
         acc[good.goodCode] = good.quantity
      }

      return acc
   }, {})

   Object.keys(shoppingList).forEach(goodCode => {
      if (!synchronizedShoppingList[goodCode]) {
         synchronizedShoppingList[goodCode] = shoppingList[goodCode]
      }
   })

   if (!cookies.cart) {
      setCookie(
         null, 'cart',
         JSON.stringify(synchronizedShoppingList),
         { maxAge: 24 * 60 * 60 } // 1 day
      )
   }

   return synchronizedShoppingList
}