import Link from 'next/link'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'

import ItemsList from '@components/common/items_list'
import { changeCategories } from '@redux/actions'
import s from './category_item.module.scss'


const CategoryItem = ({ items, show }) => {
   const dispatch = useDispatch()
   const { showCategories } = useSelector(({ showCategories }) => ({ showCategories }))

   return (
      <div className={ `${s.categoryItem} ${showCategories ? s.show : ''}` } style={ { display: show ? 'flex' : 'none' } }>
         <div className={ s.close_btn } onClick={ () => dispatch(changeCategories(false)) }>
            <Image
               src={ '/images/common/cross.svg' }
               width={ 19 }
               height={ 19 }
               onError={ e => console.log(e) }
               alt=""
            />
         </div>
         <div className={ s.sub_categories }>
            <div className={ s.categoryItem_lists }>
               <ItemsList items={ items } ItemLayout={ SubCategoryItem } />
            </div>
         </div>
      </div>
   )
}

const SubCategoryItem = ({ title, href, subitems }) => {
   const dispatch = useDispatch()

   return (
      <div className={ s.categoryItem_lists_item }>
         <span><Link href={ href }><a onClick={ () => dispatch(changeCategories(false)) }>{title}</a></Link></span>
         <ul>
            <ItemsList items={ subitems } ItemLayout={ SubCategoryPage } />
            {
               subitems.length ?
                  <li>
                     <Link href={ href }>
                        <a onClick={ () => dispatch(changeCategories(false)) } className={ s.show_all }>Переглянути всі <img
                           src="/images/common/arrow_right_long_black.svg" alt="" /></a>
                     </Link>
                  </li>
                  : null
            }
         </ul>
      </div>
   )
}

const SubCategoryPage = ({ title, href }) => {
   const dispatch = useDispatch()

   return <li><Link href={ href }><a onClick={ () => dispatch(changeCategories(false)) }>{title}</a></Link></li>
}

export default CategoryItem
