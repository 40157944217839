import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'

import { changeCategories, setShowMobileSidebar, setShowSearchHints } from '@redux/actions'
import MobileSidebar from '@components/mobile_sidebar'
import { clx } from '@utils'
import s from './catalog.module.scss'

const Catalog = () => {
   const { showCategories, categories } = useSelector(({ showCategories, categories }) => ({
      showCategories,
      categories
   }))

   const dispatch = useDispatch()

   const catalogHandler = () => {
      dispatch(setShowSearchHints(false))
      dispatch(changeCategories(!showCategories))
   }

   const catalogMobileHandler = () => {
      dispatch(setShowSearchHints(false))
      dispatch(setShowMobileSidebar(true))
   }

   return (
      <div className={ s.catalog }>
         <button className={ clx(s.catalog_button, 'hide_on_mobile') } onClick={ catalogHandler }>
            <Image
               src="/images/header/catalog.svg"
               width={ 20 }
               height={ 16 }
               alt=""
            /><span>Каталог
            </span>
         </button>
         
         <button className={ clx(s.sidebar_btn, 'show_on_mobile') } onClick={ catalogMobileHandler }>
            <Image
               src="/images/header/catalog.svg"
               width={ 20 }
               height={ 16 }
               quality={ 100 }
               alt=""
            />
         </button>
         <MobileSidebar categories={ categories } changeVisibility={ value => dispatch(setShowMobileSidebar(value)) }/>
      </div>
   )
}

export default Catalog
