import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'

import TadaServiceContext from '@components/context/tada_service_context'
import { prettyPhone, getLoginTypeAndValue } from '@utils'
import { changeModal, setModalMessage } from '@redux/actions'

import Loading from '../../loading'
import ConfirmNewPhoneForm from './confirm_new_phone_form'
import NumberFormat from '../../number_format'
import s from '../form.module.scss'

const NewPhoneForm = ({ token, oldPhone, uuid }) => {
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const [loading, setLoading] = useState(false)
   const [newPhone, setNewPhone] = useState('')
   const [isValid, setIsValid] = useState(false)

   const handleChangePhone = async e => {
      e.preventDefault()

      setLoading(true)

      const { type, value: formattedNewPhone } = getLoginTypeAndValue(newPhone)

      try {
         const [response] = await tadaService.userCheck(type, formattedNewPhone)

         if (response.phone) dispatch(setModalMessage('Такий користувач вже зареєстрований'))
      } catch (e) {
         if (e?.error?.code !== 50) { // code 50: user doesn't exist in system
            console.log(e)
            return dispatch(setModalMessage('Помилка!'))
         }

         dispatch(setModalMessage(''))
         dispatch(changeModal({
            title: 'Відновлення акаунту',
            inner: <ConfirmNewPhoneForm
               oldPhone={ oldPhone }
               newPhone={ prettyPhone(newPhone) }
               uuid={ uuid }
               token={ token }
            />
         }))
      } finally {
         setLoading(false)
      }
   }


   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div style={ { marginTop: '10px', maxWidth: '490px' } }>Введіть новий номер телефону</div>

         <div className={ s.form_column }>
            <label htmlFor="phone">Новий номер</label>
            <NumberFormat
               format="+38 (###) ###-##-##"
               allowEmptyFormatting
               mask="_"
               type="tel"
               id="phone"
               value={ newPhone }
               onValueChange={ ({ formattedValue, value }) => {
                  setNewPhone(formattedValue)
                  setIsValid(value.length === 10)
               } }
               // onChange={ e => setNewPhone(e.target.value) }
            />
            {/*<input type="text" id="phone" value={newPhone} onChange={e => setNewPhone(e.target.value)} />*/}
         </div>

         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ handleChangePhone } disabled={ !isValid }>
                    Далі
            </button>
         </div>
      </form>
   )
}

export default NewPhoneForm
