import { useCallback, useEffect } from 'react'

export default function useOutsideClick(ref, callback) {

   const handleDocumentClick = useCallback(
      (event) => {
         if (Array.isArray(ref)) {
            if (ref.every((r) => r.current && !r.current.contains(event.target))) {
               callback()
            }
         } else if (ref.current && !ref.current.contains(event.target)) {
            callback()
         }
      },
      [ref, callback]
   )

   useEffect(() => {
      document.addEventListener('mousedown', handleDocumentClick)

      return () => document.removeEventListener('mousedown', handleDocumentClick)
   }, [handleDocumentClick])
}