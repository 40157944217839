import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'

import { changeModal } from '@redux/actions'
import ChoseMarketForm from '../forms/chose_market_form'
import { clx } from '@utils'
import s from './location.module.scss'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const Location = ({ className, disabled, isPersonalInfo=false }) => {
   const dispatch = useDispatch()
   const router = useRouter()
   const { choosenCity, choosenAddress, choosenCityNP, choosenAddressNP, isNPShown, profileInfo } = useSelector((state) => state)

   const locationClick = event => {
      event.preventDefault()
      dispatch(changeModal({ title: 'Оберіть місце доставки', inner: <ChoseMarketForm /> }))
   }

   const choosenCurrCity = profileInfo?.delivery ? profileInfo.delivery.city : choosenCityNP
   const choosenCurrAddress = profileInfo?.delivery ? profileInfo.delivery.address : choosenAddressNP

   return (
      <div className={ clx(s.location, className,isPersonalInfo && s.bigMob) }>
         <button onClick={ locationClick } disabled={ disabled || router.pathname === '/checkout' }>
            <span className={ s.location_img }>
               <Image
                  src={ `/images/common/${isNPShown ? 'np_logo' : 'location'}.svg` }
                  width={ isNPShown ? 16 : 11 }
                  height={ 16 }
                  quality={ 100 }
                  alt=""
               />
            </span>
            <span className={ `${s.addressName} ${isPersonalInfo ? s.bigMob : ''}` }>
               {
                  (choosenCity || isNPShown) 
                     ? `${isNPShown ? choosenCurrCity : choosenCity}, ${isNPShown ? choosenCurrAddress : choosenAddress}`
                     : 'Оберіть місце доставки'
               }
            </span>
         </button>
      </div>
   )
}

export default Location
