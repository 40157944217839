import Link from 'next/link'
import s from './pages_list.module.scss'
import ItemsList from '../items_list'
import { useDispatch } from 'react-redux'
import { setShowMobileSidebar } from '@redux/actions'

const PagesList = ({ items }) => (
   <div className={ s.pages_list }>
      <ul>
         <ItemsList items={ items } ItemLayout={ PagesListItem } />
      </ul>
   </div>
)

const PagesListItem = ({ href, title }) => {

   const dispatch = useDispatch()

   return (
      <li>
         <Link href={ href }>
            <a onClick={ () => dispatch(setShowMobileSidebar(false)) }>{title}</a>
         </Link>
      </li>
   )
}

export default PagesList