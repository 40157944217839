import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'

import TadaServiceContext from '@components/context/tada_service_context'
import { changeModal, setModalMessage } from '@redux/actions'
import { useAccount } from '@functions/hooks'

import SuccessAuthContent from '../../modal_content/success_auth_content'
import Loading from '../../loading'
import s from '../form.module.scss'

const CheckVerificationNewPhoneCodeForm = ({ userData, newPhone }) => {
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const [loading, setLoading] = useState(false)
   const [code, setCode] = useState('')

   const { setUserData } = useAccount()

   const { userName, userSurname, uuid, email, birthday, phone } = userData

   const handleCheckVerification = async e => {
      e.preventDefault()

      setLoading(true)

      try {
         const { token, card_number } = await tadaService.codeVerification('phone_new', uuid, code)

         await tadaService.infoSave(token, uuid, userName, userSurname, email, birthday, phone)
         await setUserData(card_number, token)

         dispatch(changeModal({
            title: 'Привіт!',
            inner: <SuccessAuthContent />
         }))
      } catch (e) {
         tadaService.logError(e).catch(e => console.log(e))
         dispatch(setModalMessage('Сталася помилка'))
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loading />
   }

   return (
      <form className={ s.form }>
         <div style={ { marginTop: '10px', maxWidth: '490px' } }>На новий номер {newPhone} був відправлений код підтвердження</div>

         <div className={ s.form_column }>
            <label htmlFor="code">Код підтвердження</label>
            <input type="text" id="code" value={ code } onChange={ e => setCode(e.target.value) }/>
         </div>

         <div className={ s.form_center }>
            <button className={ s.form_big_btn } onClick={ handleCheckVerification } disabled={ !code }>
                    Далі
            </button>
         </div>
      </form>
   )
}

export default CheckVerificationNewPhoneCodeForm
