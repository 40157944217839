import prettyPhone from './pretty_phone'

export default function getLoginTypeAndValue(input, withCard = true) {
   let type = null
   let value = input

   if (input.includes('@')) {
      type = 'email'
   } else if (withCard && (value.length === 8) && (!isNaN(value))) {
      type = 'card'
   } else {
      type = 'phone'
      value = prettyPhone(value)
   }

   return { type, value }
}