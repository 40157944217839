import { useEffect, useContext } from 'react'
import TadaServiceContext from '@components/context/tada_service_context'

const ErrorBoundaryLogger = ({ error }) => {

   const tadaService = useContext(TadaServiceContext)

   useEffect(() => {
      tadaService?.logError(error)
   }, [])

   return null
}
export default ErrorBoundaryLogger