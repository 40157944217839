import { memo } from 'react'
import { prettyPrice } from '@utils'
import s from './price.module.scss'

function Price({ price, discountPrice, oldStyle, currentStyle, oldCentStyle, currentCentStyle }) {

   const isDiscount = discountPrice && discountPrice !== price

   const priceItem = prettyPrice(price).split('.')
   const discountPriceItem = prettyPrice(discountPrice).split('.')

   return (
      <div className={ s.price + ' ' + (isDiscount ? s.discount : '') }>
         {
            isDiscount ? (
               <>
                  <div className={ s.old_price } style={ oldStyle }>
                     {priceItem[0]}<span style={ oldCentStyle }>.{priceItem[1]} грн</span>
                  </div>
                  <div className={ s.current_price } style={ currentStyle }>
                     {discountPriceItem[0]}<span style={ currentCentStyle }>.{discountPriceItem[1]} грн</span>
                  </div>
               </>
            ) : (
               <div className={ s.current_price } style={ currentStyle }>
                  {priceItem[0]}<span style={ currentCentStyle }>.{priceItem[1]} грн</span>
               </div>
            )
         }
      </div>
   )
}

export default memo(Price)
