import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import TadaServiceContext from '../../components/context/tada_service_context'
import useGoodsSession, { removeSessionPageData } from './useGoodsSession'
import { checkHasMore } from './utils'
import { setInStock } from '../../redux/actions'
import useDidMountEffect from './useDidMountEffect'

export default function useGoodsSearch(
   requestGoods,
   searchResult = {
      resultCount: 0,
      resultGoods: [],
      resultCategories: [],
      priceRange: {},
      attributes: [],
      breadcrumbs: [],
      pageTitle: ''
   },
   pageName,
   options = {
      initialFilterAttributes: [],
      initialCategoryId: null
   },
   customAttributes
) {
   const router = useRouter()
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)
   const [useGetSessionValueIfPossible, useInitGoodsSession] = useGoodsSession(pageName)

   const { search: searchString, page: pageRoute, category_id: categoryIdFromQuery } = router.query
   const { choosenShopId, inStock } = useSelector(state => ({
      choosenShopId: state.choosenShopId,
      inStock: state.inStock
   }))

   const initialCategoryId = options.initialCategoryId

   const categoryId = initialCategoryId || categoryIdFromQuery

   const [showFilter, setShowFilter] = useState(false)
   const [loading, setLoading] = useState(false)
   const [newGoodsLoading, setNewGoodsLoading] = useState(false)

   const [page, setPage] = useState(+pageRoute || 1)
   const [pageOffset, setPageOffset] = useState(useGetSessionValueIfPossible('pageOffset', 0))

   const [needResetFilterPrice, setNeedResetFilterPrice] = useState(false)
   const [filterPrice, setFilterPrice] = useState(useGetSessionValueIfPossible('filterPrice', null))
   const [sort, setSort] = useState(useGetSessionValueIfPossible('sort', {
      limit: 24,
      offset: page === 1 ? 0 : 24 * (page - 1), // 24 * (page - 1)
      direction: 'ASC',
      order: 'none'
   }))

   const [filterAttributes, setFilterAttributes] = useState(useGetSessionValueIfPossible('filterAttributes', options.initialFilterAttributes))
   const [breadcrumbs, setBreadcrumbs] = useState(searchResult.breadcrumbs)
   const [pageTitle, setPageTitle] = useState(searchResult.pageTitle)

   const [goodsFound, setGoodsFound] = useState(true)
   const [error, setError] = useState(false)

   //API results
   const [resultCount, setResultCount] = useState(useGetSessionValueIfPossible('totalCount', searchResult.resultCount))
   const [foundGoods, setFoundGoods] = useState(useGetSessionValueIfPossible('goods', searchResult.resultGoods))
   const [priceRange, setPriceRange] = useState(useGetSessionValueIfPossible('priceRange', searchResult.priceRange))
   const [resultAttributes, setResultAttributes] = useState(searchResult.attributes)
   const [foundCategories, setFoundCategories] = useState(searchResult.resultCategories)

   const [scrollMode, setScrollMode] = useState(useGetSessionValueIfPossible('pageOffset') > 0)
   const sessionPageData = useInitGoodsSession({
      goods: foundGoods,
      totalCount: resultCount,
      sort: { ...sort, offset: page === 1 && pageOffset === 0 ? 0 : 24 * ((page + pageOffset) - 1) }, // 24 * (page + pageOffset)
      filterAttributes,
      pageOffset,
      filterPrice
   })

   const toFirstPage = (shallow = true) => {
      setPageOffset(0)
      setScrollMode(false)

      router.push({
         pathname: router.pathname,
         query: { ...router.query },
      }, null, { shallow })
   }

   const setFilterAttributesHandler = attributes => {
      if (attributes.length === 0) return setFilterAttributes([])

      toFirstPage()
      sessionPageData && removeSessionPageData()
      setSort(prev => ({ ...prev, offset: 0 }))
      setFilterAttributes(attributes)
   }

   const setFilterPriceHandler = newPriceRange => {
      if (newPriceRange?.start === priceRange?.minPrice && newPriceRange?.end === priceRange?.maxPrice) {
         return setFilterPrice(null)
      }

      setFilterPrice(newPriceRange)
      removeSessionPageData()
      toFirstPage()
   }

   const setSortHandler = sortParams => {
      setSort(prev => ({ ...prev, ...sortParams, offset: 0 }))
      removeSessionPageData()
      toFirstPage()
   }

   const resetFilters = () => {
      setFilterAttributes([])
      setNeedResetFilterPrice(true)
      dispatch(setInStock(false))
      setSort(prev => ({
         ...prev,
         limit: 24,
         offset: 0,
         order: 'none',
         direction: 'ASC'
      }))
      setFilterPrice({
         start: priceRange.minPrice,
         end: priceRange.maxPrice
      })

      removeSessionPageData()
      toFirstPage(false)
   }

   useDidMountEffect(() => {
      if (options.initialFilterAttributes.length) {
         setFilterAttributes(options.initialFilterAttributes)
      }
   }, [options.initialFilterAttributes])

   useEffect(() => {
      const newPage = +pageRoute || 1

      setPage(newPage)

      if (!scrollMode) {
         setSort(sort => ({ ...sort, offset: sort.limit * (newPage - 1) }))
         setPageOffset(0)
      }
   }, [pageRoute])

   useEffect(() => {
      if (customAttributes)
      {
         setFilterAttributes(customAttributes)
      }
   }, [])

   useEffect(() => {
      if (!choosenShopId) return

      if (sessionPageData) {
         return window.scrollTo(0, sessionPageData?.scrolledPoint)
      }

      let cancelled = false

      setLoading(true)

      const requestParams = {
         shopId: choosenShopId,
         sort,
         options: { previewSize: '177x177' },
         filterPrice,
         filterAttributes, // : sessionPageData?.filterAttributes.length > 0 ? sessionPageData?.filterAttributes : filterAttributes
         filterInStock: inStock
      }

      searchString && (requestParams.searchString = searchString)
      categoryId && (requestParams.categoryId = categoryId)

      requestGoods(requestParams)
         .then(({
            resultCount,
            resultGoods,
            resultCategories,
            minPrice,
            maxPrice,
            attributes,
            breadcrumbs,
            pageTitle
         }) => {
            if (!cancelled) {
               if (!sessionPageData) {
                  setResultCount(resultCount)
                  setFoundGoods(resultGoods)
                  setFoundCategories(resultCategories)
                  setPriceRange({ minPrice, maxPrice })
               }

               setResultAttributes(attributes)
               setGoodsFound(true)
               setBreadcrumbs(breadcrumbs)
               setPageTitle(pageTitle)

               if (sessionPageData) {
                  window.scrollTo(0, sessionPageData?.scrolledPoint)
               }
            }
         })
         .catch(e => {
            if (!cancelled) {
               switch (e?.error?.code) {
               case 66:
                  setGoodsFound(false)
                  setResultCount(0)
                  setFoundGoods([])
                  setFoundCategories([])
                  break
               default:
                  setError(true)
                  tadaService.logError(e).catch(e => console.log(e))
                  break
               }
            }
         })
         .finally(() => {
            !cancelled && setLoading(false)
         })

      return () => cancelled = true
   }, [searchString, categoryId, sort, choosenShopId, filterPrice, filterAttributes, inStock])

   const showMoreHandler = () => {
      if (!checkHasMore(page, pageOffset, resultCount) || !choosenShopId) return

      const cancelled = false

      setNewGoodsLoading(true)

      const requestParams = {
         shopId: choosenShopId,
         sort: { ...sort, offset: 24 * (page + pageOffset) },
         options: {
            previewSize: '177x177'
         },
         filterPrice,
         filterAttributes
      }

      searchString && (requestParams.searchString = searchString)
      categoryId && (requestParams.categoryId = categoryId)

      setPageOffset(prev => prev + 1)

      try {
         requestGoods(requestParams)
            .then(({ resultGoods }) => {
               if (!cancelled) {
                  setFoundGoods(foundGoods => [...foundGoods, ...resultGoods])
               }
            })
            .catch(e => {
               !cancelled && setError(true)

               if (e?.error?.code !== 66) {
                  tadaService.logError(e).catch(console.log)
               }
            })
            .finally(() => !cancelled && setNewGoodsLoading(false))
      } catch (e) {
         console.log(e)
      }
   }

   return {
      resetFilters,
      needResetFilterPrice,
      setNeedResetFilterPrice,
      hasMore: checkHasMore(page, pageOffset, resultCount),
      scrollMode,
      setScrollMode,
      searchString,
      foundCategories,
      setFoundCategories,
      priceRange,
      setPriceRange,
      filterPrice,
      setFilterPrice: setFilterPriceHandler,
      error,
      setError,
      showFilter,
      setShowFilter,
      sort,
      setSort: setSortHandler,
      loading,
      setLoading,
      foundGoods,
      setFoundGoods,
      newGoodsLoading,
      setNewGoodsLoading,
      showMoreHandler,
      page,
      setPage,
      pageOffset,
      resultCount,
      setResultCount,
      goodsFound,
      setGoodsFound,
      resultAttributes,
      setResultAttributes,
      filterAttributes,
      setFilterAttributes: setFilterAttributesHandler,
      breadcrumbs,
      setBreadcrumbs,
      pageTitle,
      setPageTitle,
      hasSelectedFilters: Boolean(filterAttributes.length) || inStock || Boolean(filterPrice)
   }
}
