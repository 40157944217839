import React from 'react'
import ErrorBoundaryLogger from './error_boundary_logger'

class ErrorBoundary extends React.Component {

   constructor(props) {
      super(props)
      this.state = { hasError: false, error: null }
   }

   componentDidCatch(error, errorInfo) {
      console.log(error, errorInfo)
      this.setState({
         hasError: true,
         error: `\n error: ${ error } \n info: ${ errorInfo }`
      })
   }

   render() {
      if (this.state.hasError) {
         const fallback = this.props.fallback

         if (this.props.empty) {
            return null
         }

         return (
            <>
               <ErrorBoundaryLogger error={ this.state.error }/>
               { fallback || <h1>Щось пішло не так :(</h1> }
            </>
         )
      }

      return this.props.children
   }
}

export default ErrorBoundary