import Image from 'next/image'
import s from './contacts.module.scss'

const Contacts = ({ textCenter = false }) => (
   <div className={ s.contacts } style={ { textAlign: textCenter ? 'center' : 'left' } }>
      <h3 className={ s.call_header }>Call center</h3>
      <span><a href="tel:0800759119" className={ s.phoneNum }>0 800 759 119</a></span>
      <span><a href="tel:+380994137259" className={ s.phoneNum }>(099) 413-72-59</a></span>
      <span><a href="tel:+380675393477" className={ s.phoneNum }>(067) 539-34-77</a></span>
      
      <p>Товари для дому. Щодня!</p>
      <div className={ s.apps }>
         <a href="https://l.ta-da.ua/app_from_site" target="_blank" rel="noreferrer nofollow">
            <Image
               src="/images/footer/googleplayDwnl.png"
               width={ 165 }
               height={ 60 }
               alt="Мобільний додаток для Android"
            />
         </a>
         <a href="https://l.ta-da.ua/ios_from_site" target="_blank" rel="noreferrer nofollow">
            <Image
               src="/images/footer/appstoreDwnl.png"
               width={ 165 }
               height={ 60 }
               alt="Мобільний додаток для iOS"
            />
         </a>
      </div>
   </div>
)

export default Contacts
