import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseCookies } from 'nookies'

import TadaServiceContext from '../../components/context/tada_service_context'
import useGoodsSession, { removeSessionPageData } from './useGoodsSession'
import { checkHasMore } from './utils'
import { setInStock } from '@redux/actions'
import { parseJSON } from '@utils'


/**
 * @param { object } searchResult
 * @param { 'packInfo' } getGoodsMethod - service method used to
 *  receive goods
 * @param { 'bundle' } goodsPageName - page name where use this hook
 * @param {function} dispatch
 * */
export default function useGoodsBundlePage(
   searchResult = {
      resultCount: 0,
      resultGoods: [],
      resultCategories: [],
      priceRange: {},
      attributes: [],
      breadcrumbs: []
   }, id) {

   const getGoodsMethod = 'packInfo'
   const goodsPageName = 'bundle'

   const router = useRouter()
   const cookies = parseCookies()
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const [useGetSessionValueIfPossible, useInitGoodsSession] = useGoodsSession(goodsPageName)

   const token = parseJSON(cookies.user)?.token
   const { page: pageRoute } = router.query

   const choosenShopId = useSelector(state => state.choosenShopId)
   const inStock = useSelector(state => state.inStock)

   const [showFilter, setShowFilter] = useState(false)

   const [loading, setLoading] = useState(false)
   const [newGoodsLoading, setNewGoodsLoading] = useState(false)

   const [page, setPage] = useState(+pageRoute || 1)
   const [pageOffset, setPageOffset] = useState(useGetSessionValueIfPossible('pageOffset', 0))

   const [needResetFilterPrice, setNeedResetFilterPrice] = useState(false)
   const [filterPrice, setFilterPrice] = useState(useGetSessionValueIfPossible('filterPrice', null))
   const [sort, setSort] = useState(useGetSessionValueIfPossible('sort', {
      limit: 24,
      offset: page === 1 ? 0 : 24 * (page - 1),
      direction: 'ASC',
      order: 'none'
   }))

   const [filterAttributes, setFilterAttributes] = useState(useGetSessionValueIfPossible('filterAttributes', []))
   const [breadcrumbs, setBreadcrumbs] = useState(searchResult.breadcrumbs)
   const [bundleImage, setBundleImage] = useState(searchResult.src)
   const [date, setDate] = useState(searchResult.date)
   const [dateEnd, setDateEnd] = useState(searchResult.dateEnd)
   const [title, setTitle] = useState(searchResult.title)
   const [categories, setCategories] = useState([])

   const [goodsFound, setGoodsFound] = useState(true)
   const [error, setError] = useState(false)

   //API results
   const [resultCount, setResultCount] = useState(useGetSessionValueIfPossible('totalCount', searchResult.resultCount))
   const [foundGoods, setFoundGoods] = useState(useGetSessionValueIfPossible('goods', searchResult.resultGoods))
   const [priceRange, setPriceRange] = useState(useGetSessionValueIfPossible('priceRange', searchResult.priceRange))
   const [resultAttributes, setResultAttributes] = useState(searchResult.attributes)
   const [foundCategories, setFoundCategories] = useState(searchResult.resultCategories)

   const [scrollMode, setScrollMode] = useState(useGetSessionValueIfPossible('pageOffset') > 0)
   const sessionPageData = useInitGoodsSession({
      goods: foundGoods,
      totalCount: resultCount,
      sort: { ...sort, offset: page === 1 && pageOffset === 0 ? 0 : 24 * ((page + pageOffset) - 1) },
      filterAttributes,
      pageOffset,
      filterPrice
   })

   const toFirstPage = (shallow = true) => {
      setPageOffset(0)
      setScrollMode(false)

      router.push({
         pathname: router.pathname,
         query: { ...router.query },
      }, null, { shallow })
   }

   const setFilterAttributesHandler = attributes => {
      if (attributes.length === 0) return setFilterAttributes([])

      toFirstPage()
      sessionPageData && removeSessionPageData()

      setSort(prev => ({ ...prev, offset: 0 }))
      setFilterAttributes(attributes)
   }

   const setFilterPriceHandler = newPriceRange => {
      /* this condition will ignore first render with same data */
      if (newPriceRange?.start === priceRange?.minPrice && newPriceRange?.end === priceRange?.maxPrice) {
         return setFilterPrice(null)
      }

      setFilterPrice(newPriceRange)
      removeSessionPageData()
      toFirstPage()
   }

   const setSortHandler = sortParams => {
      setSort(prev => ({ ...prev, ...sortParams, offset: 0 }))
      removeSessionPageData()
      toFirstPage()
   }

   const resetFilters = () => {
      setFilterAttributes([])
      setNeedResetFilterPrice(true)
      dispatch(setInStock(false))
      setSort(prev => ({
         ...prev,
         limit: 24,
         offset: 0,
         order: 'none',
         direction: 'ASC'
      }))

      setFilterPrice(null)

      removeSessionPageData()
      toFirstPage(false)
   }

   useEffect(() => {
      const newPage = +pageRoute || 1
      setPage(newPage)
      if (!scrollMode) {
         setSort(sort => ({ ...sort, offset: sort.limit * (newPage - 1) }))
         setPageOffset(0)
      }
   }, [pageRoute])

   // useEffect(() => {
   //    filterAttributes.length && pageRoute && +pageRoute !== 1 && router.push({
   //       pathname: router.pathname,
   //       query: { ...router.query, page: 1 },
   //    })
   // }, [filterAttributes])

   useEffect(() => {

      if (!choosenShopId) return

      if (sessionPageData) {
         return window.scrollTo(0, sessionPageData.scrolledPoint)
      }

      let cancelled = false

      setLoading(true)

      const requestParams = {
         id,
         shopId: choosenShopId,
         filterInStock: inStock,
         sort,
         filterPrice,
         filterAttributes,
         filter_categories: categories,
      }

      tadaService?.packInfo(requestParams).then(({
         resultCount,
         resultGoods,
         resultCategories,
         minPrice,
         maxPrice,
         attributes,
         breadcrumbs,
         src,
         date,
         dateEnd,
         title
      }) => {
         if (!cancelled) {
            if (!sessionPageData) {
               setResultCount(resultCount)
               setFoundGoods(resultGoods)
               setFoundCategories(resultCategories)
               setPriceRange({ minPrice, maxPrice })
            }

            setResultAttributes(attributes)
            setGoodsFound(true)
            setBreadcrumbs(breadcrumbs)
            setBundleImage(src)
            setDate(date)
            setDateEnd(dateEnd)
            setTitle(title)

            if (sessionPageData) {
               window.scrollTo(0, sessionPageData.scrolledPoint)
            }
         }
      }).catch(e => {
         if (!cancelled) {
            switch (e?.error?.code) {
            case 66:
               setGoodsFound(false)
               setResultCount(0)
               setFoundGoods([])
               setFoundCategories([])
               break
            default:
               setError(true)
               tadaService.logError(e)
               break
            }
         }
      }).finally(() => !cancelled && setLoading(false))

      return () => cancelled = true
   }, [sort, choosenShopId, filterPrice, filterAttributes, inStock, categories])

   const showMoreHandler = () => {
      if (!checkHasMore(page, pageOffset, resultCount)) return

      const cancelled = false

      setNewGoodsLoading(true)

      const requestParams = {
         id,
         shopId: choosenShopId,
         sort: { ...sort, offset: 24 * (page + pageOffset) },
         options: {
            previewSize: '177x177'
         },
         filterPrice,
         filterAttributes
      }

      setPageOffset(pageOffset => pageOffset + 1)

      tadaService?.[getGoodsMethod](requestParams)
         .then(({ resultGoods }) => {
            if (!cancelled) {
               setFoundGoods(foundGoods => [...foundGoods, ...resultGoods])
            }
         })
         .catch(e => {
            !cancelled && setError(true)

            if (e?.error?.code !== 66) {
               tadaService.logError(e).catch(e => console.log(e))
            }
         })
         .finally(() => !cancelled && setNewGoodsLoading(false))
   }

   return {
      needResetFilterPrice,
      setNeedResetFilterPrice,
      resetFilters,
      scrollMode,
      setScrollMode,
      hasMore: checkHasMore(page, pageOffset, resultCount),
      foundCategories,
      setFoundCategories,
      priceRange,
      setPriceRange,
      filterPrice,
      setFilterPrice: setFilterPriceHandler,
      error,
      setError,
      showFilter,
      setShowFilter,
      sort,
      setSort: setSortHandler,
      loading,
      setLoading,
      foundGoods,
      setFoundGoods,
      newGoodsLoading,
      setNewGoodsLoading,
      showMoreHandler,
      page,
      setPage,
      pageOffset,
      resultCount,
      setResultCount,
      goodsFound,
      setGoodsFound,
      resultAttributes,
      setResultAttributes,
      filterAttributes,
      setFilterAttributes: setFilterAttributesHandler,
      breadcrumbs,
      setBreadcrumbs,
      src: bundleImage,
      date,
      dateEnd,
      title,
      hasSelectedFilters: Boolean(filterAttributes.length) || inStock || Boolean(filterPrice),
      categories,
      setCategories
   }
}
