import Image from 'next/image'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'

import { changeModal } from '@redux/actions'
import s from './mobile_sidebar.module.scss'

import Logo from '../common/logo'
import SwipeHandler from '../common/swipe_handler/iindex'
import Authorization from '../common/authorization'
import Socials from '../common/socials'
import ChoseMarketForm from '../common/forms/chose_market_form'
import SidebarButtons from './components/sidebar_buttons'
import BottomButtons from './components/bottom_buttons'


const MobileSidebar = ({ categories, changeVisibility }) => {
   const dispatch = useDispatch()

   const choosenCity = useSelector(state => state.choosenCity)
   const choosenAddress = useSelector(state => state.choosenAddress)
   const showMobileSidebar = useSelector(state => state.showMobileSidebar)

   const sidebarStyle = {
      transform: `translateX(${showMobileSidebar ? 0 : '100%'})`,
      transition: showMobileSidebar ? 'all 0s ease 0s' : 'transform .5s step-end'
   }

   const sidebarBodyStyle = { transform: showMobileSidebar ? 'none' : 'translateX(-100%)' }

   const sidebarClickHandler = e =>
      e.target === e.currentTarget ? changeVisibility(false) : null

   return (
      <div
         className={ s.mobile_sidebar }
         onClick={ sidebarClickHandler }
         style={ sidebarStyle }
      >
         <SwipeHandler leftAction={ () => changeVisibility(false) }>
            <div className={ s.sidebar_body } style={ sidebarBodyStyle }>
               <div className={ s.sidebar_top } onClick={ () => changeVisibility(false) }>
                  <Logo mobile width={ 120 } height={ 40 } />
               </div>
               <button
                  className={ s.sidebar_choosemarket }
                  onClick={ () => dispatch(changeModal({
                     title: 'Оберіть місце доставки',
                     inner: <ChoseMarketForm />
                  })) }
               >
                  <span>{choosenCity ? `${choosenCity}, ${choosenAddress}` : 'Оберіть місце доставки для отримання замовлення'}</span>
                  <Image
                     src={ '/images/common/arrow_right_long_black.svg' }
                     width={ 16 }
                     height={ 10 }
                     alt=""
                  />
               </button>
               <div className={ s.sidebar_buttons_item } onClick={ () => changeVisibility(false) }>
                  <Link href="/location">
                     <a className={ s.sidebar_buttons_inner }>
                        <div className={ s.item_image }>
                           <Image
                              src={ '/images/common/market-time.svg' }
                              width={ 20 }
                              height={ 20 }
                              alt=""
                           />
                        </div>
                        <p>Графік роботи</p>
                     </a>
                  </Link>
               </div>
               <div className={ s.sidebar_buttons }>
                  <div className={ s.sidebar_buttons_item } onClick={ () => changeVisibility(false) }>
                     <Authorization inner={ text => (
                        <button className={ s.sidebar_buttons_inner }>
                           <span className={ s.item_image }>
                              <Image
                                 src={ '/images/common/user.svg' }
                                 width={ 20 }
                                 height={ 20 }
                                 alt=""
                              />
                           </span>
                           <span style={ { fontWeight: 'bold' } }>{text}</span>
                        </button>
                     ) } />
                  </div>
               </div>

               <SidebarButtons items={ categories } changeVisibility={ changeVisibility } />

               <div>
                  <BottomButtons />
                  <div className={ s.socials }>
                     <Socials />
                  </div>
               </div>
            </div>
         </SwipeHandler>
      </div>
   )
}

export default MobileSidebar
