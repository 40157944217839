import { useRouter } from 'next/router'
import Typed from 'react-typed'
import Image from 'next/image'
import { useContext, useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeCategories, setShowSearchHints } from '@redux/actions'
import convertLang from '@functions/convertLang'
import { clx, isCyrillic } from '@utils'
import { ERROR_CODE } from '@static/constants'

import TadaServiceContext from '@components/context/tada_service_context'
import SearchHints from './search_hints'
import s from './search.module.scss'

const MAX_RE_FETCH_COUNT = 3

const Search = ({
   searchRequest,
   searchRoute,
   hints = false,
   allowEmptySearch = false,
   placeholderDetails = {
      placeholders: [],
      animated: false,
   },
   className,
}) => {
   const router = useRouter()
   const tadaService = useContext(TadaServiceContext)
   const dispatch = useDispatch()

   const showSearchHints = useSelector(state => state.showSearchHints)
   const showCategories = useSelector(state => state.showCategories)
   const choosenShopId = useSelector(state => state.choosenShopId)

   const hintsIsEnabled = showSearchHints && hints

   const [searchValue, setSearchValue] = useState('')
   const [hintGoods, setHintGoods] = useState([])
   const [hintCategories, setHintCategories] = useState([])
   const [reFetchCount, setReFetchCount] = useState(0)

   //facebook PIXEL
   useEffect(() => {
      import('react-facebook-pixel')
         .then((x) => x.default)
         .then((ReactPixel) => {
            if (searchValue?.length > 1)
               ReactPixel.track('Search', { search_string: searchValue }) //facebook PIXEL
         })
   }, [])

   useEffect(() => {
      let cancelled = false
      let request

      if (searchValue?.length > 1) {
         request = setTimeout(() => {
            searchRequest({
               searchString: searchValue,
               shopId: choosenShopId,
               sort: { limit: 5, order: 'none' },
               options: {
                  previewSize: '69x69',
                  maxCategoriesCount: 4
               }
            })
               .then(({ resultGoods, resultCategories }) => {
                  if (!cancelled) {
                     setHintGoods(resultGoods)
                     setHintCategories(resultCategories)
                     dispatch(setShowSearchHints(!!resultGoods))
                     if (resultGoods && showCategories) {
                        dispatch(changeCategories(false))
                     }
                  }
               })
               .catch(e => {
                  if (e?.error?.code === ERROR_CODE.PRODUCTS_NOT_FOUND && reFetchCount <= MAX_RE_FETCH_COUNT) {
                     setReFetchCount(c => ++c)
                     setSearchValue(value =>
                        isCyrillic(value) ? convertLang.fromRus(value) : convertLang.fromEng(value)
                     )
                  }

                  if (e?.error?.code !== 66) {
                     tadaService.logError(e)
                  }
               })
         }, 500)
      } else {
         dispatch(setShowSearchHints(false))
      }

      return () => {
         request && clearTimeout(request)
         cancelled = true
      }
   }, [searchValue, reFetchCount])

   const handleSearch = () => {
      if (searchValue || allowEmptySearch) {
         if (!searchValue && allowEmptySearch) {
            router.push(searchRoute)
         } else {
            router.push(`${searchRoute}?search=${searchValue}`)
         }

         dispatch(setShowSearchHints(false))
         setReFetchCount(0)
      }
   }

   const handleChange = e => setSearchValue(e.target.value)

   const handleKeyPress = e => {
      if (e.key === 'Enter') {
         handleSearch()
         setSearchValue('')
      }
   }

   return (
      <div className={ clx(className, s.search, hintsIsEnabled && s.active) }>
         <div className={ s.search_inner }>
            <Typed
               strings={ placeholderDetails.placeholders }
               stopped={ !placeholderDetails.animated }
               typeSpeed={ 150 }
               backSpeed={ 30 }
               startDelay={ 0 }
               backDelay={ 1500 }
               attr="placeholder"
               loop
               showCursor={ false }
            >
               <input
                  type="text"
                  value={ searchValue }
                  onChange={ handleChange }
                  className={ clx(hintsIsEnabled && s.open_input) }
                  onKeyPress={ handleKeyPress }
                  placeholder={ placeholderDetails.animated ? 'Пошук' : placeholderDetails.placeholders }
               />
            </Typed>
            <button className={ s.search_btn } onClick={ handleSearch }>
               <Image
                  src={ '/images/common/search.svg' }
                  width={ 18 }
                  height={ 17 }
                  alt=""
               />
            </button>
         </div>
         <div className={ s.search_hints }>
            {hintsIsEnabled && (
               <SearchHints
                  searchValue={ searchValue }
                  goods={ hintGoods }
                  categories={ hintCategories }
                  hideHints={ () => dispatch(setShowSearchHints(false)) }
               />
            )}
         </div>
      </div>
   )
}

export default memo(Search)
